import Vue from 'vue'
import axios from 'axios'
import Const from '@/const'
import qs from "qs"

const HttpModule = {
  install (Vue) {
    //도메인 주소로 접속하면 도메인로 필요한 url를 생성
    if (process.env.VUE_APP_WEB_BASE_URL.replace(/http:|https:|\/\//gi, '') == window.location.hostname+":8080"){
      window.$store.VUE_APP_WEB_BASE_URL = process.env.VUE_APP_WEB_BASE_URL;
      window.$store.VUE_APP_SERVER_BASE_URL = process.env.VUE_APP_SERVER_BASE_URL;
      window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = process.env.VUE_APP_QUOTE_SOCKET_BASE_URL;
      window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL= process.env.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL;
      //테스트
      //window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = "ws://192.168.0.137:42000/websocket";
      //window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL = "ws://192.168.0.137:46000/websocketcoin"
      //window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = "ws://115.21.61.34:42000/websocket";
      //window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL = "ws://115.21.61.34:46000/websocketcoin"
      window.$store.VUE_APP_OMS_SOCKET_BASE_URL = process.env.VUE_APP_OMS_SOCKET_BASE_URL;
      window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL = process.env.VUE_APP_OMS_COIN_SOCKET_BASE_URL;
      window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL = process.env.VUE_APP_MESSAGE_SOCKET_BASE_URL;
    }else{
      let httpType = "http";
      let wsType = "ws";
      if (process.env.VUE_APP_WEB_BASE_URL.indexOf("https") > -1){
        httpType = "https";
        wsType = "wss";
      }
      window.$store.VUE_APP_WEB_BASE_URL = httpType + "://"+window.location.hostname;
      if (window.location.hostname.indexOf("mng.") > -1){
        window.$store.VUE_APP_SERVER_BASE_URL = httpType + "://api."+window.location.hostname.replace(/mng./gi, '');
      }else if (window.location.hostname.indexOf("mi.") > -1){
        window.$store.VUE_APP_SERVER_BASE_URL = httpType + "://api."+window.location.hostname.replace(/mi./gi, '');
      }else if (window.location.hostname.indexOf("di.") > -1){
          window.$store.VUE_APP_SERVER_BASE_URL = httpType + "://api."+window.location.hostname.replace(/di./gi, '');
      }else if (window.location.hostname.indexOf("ng.") > -1){
        window.$store.VUE_APP_SERVER_BASE_URL = httpType + "://api."+window.location.hostname.replace(/ng./gi, '');
      }else{
        window.$store.VUE_APP_SERVER_BASE_URL = httpType + "://api."+window.location.hostname.replace(/www./gi, '');
      }
      
      //console.log("test????? ", isNaN(window.location.hostname.replace(/\./gi, '')), window.location.hostname.replace(/\./gi, ''))
      if (window.location.hostname.indexOf("localhost") > -1
        || (!isNaN(window.location.hostname.replace(/\./gi, '')))
      ){
        window.$store.VUE_APP_WEB_BASE_URL = httpType + "://"+window.location.hostname+":8080";
        window.$store.VUE_APP_SERVER_BASE_URL = httpType + "://"+window.location.hostname+":8090";
        
        window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = process.env.VUE_APP_QUOTE_SOCKET_BASE_URL
        window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL= process.env.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL;
        //테스트
        //window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = "ws://192.168.0.137:42000/websocket";
        window.$store.VUE_APP_OMS_SOCKET_BASE_URL = wsType + "://" +window.location.hostname+":40010/";
        window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL = wsType + "://" +window.location.hostname+":40011/";
        //window.$store.VUE_APP_OMS_SOCKET_BASE_URL = process.env.VUE_APP_OMS_SOCKET_BASE_URL
        window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL = wsType + "://"+window.location.hostname.replace(/www./gi, '')+":8090/websocket";
      }else{
        if (window.location.hostname.indexOf("mng.") > -1){
          window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = wsType + "://libra."+window.location.hostname.replace(/mng./gi, '')+"/websocket";
          window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL= wsType + "://libra."+window.location.hostname.replace(/mng./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_OMS_SOCKET_BASE_URL = wsType + "://oms."+window.location.hostname.replace(/mng./gi, '')+"/";
          window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL =wsType + "://oms."+window.location.hostname.replace(/mng./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL = wsType + "://api."+window.location.hostname.replace(/mng./gi, '')+"/websocket";
        }else if (window.location.hostname.indexOf("mi.") > -1){
          window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = wsType + "://libra."+window.location.hostname.replace(/mi./gi, '')+"/websocket";
          window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL= wsType + "://libra."+window.location.hostname.replace(/mi./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_OMS_SOCKET_BASE_URL = wsType + "://oms."+window.location.hostname.replace(/mi./gi, '')+"/";
          window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL =wsType + "://oms."+window.location.hostname.replace(/mi./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL = wsType + "://api."+window.location.hostname.replace(/mi./gi, '')+"/websocket";
        }else if (window.location.hostname.indexOf("di.") > -1){
          window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = wsType + "://libra."+window.location.hostname.replace(/di./gi, '')+"/websocket";
          window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL= wsType + "://libra."+window.location.hostname.replace(/di./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_OMS_SOCKET_BASE_URL = wsType + "://oms."+window.location.hostname.replace(/di./gi, '')+"/";
          window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL =wsType + "://oms."+window.location.hostname.replace(/di./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL = wsType + "://api."+window.location.hostname.replace(/di./gi, '')+"/websocket";
        }else if (window.location.hostname.indexOf("ng.") > -1){
          window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = wsType + "://libra."+window.location.hostname.replace(/ng./gi, '')+"/websocket";
          window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL= wsType + "://libra."+window.location.hostname.replace(/ng./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_OMS_SOCKET_BASE_URL = wsType + "://oms."+window.location.hostname.replace(/ng./gi, '')+"/";
          window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL =wsType + "://oms."+window.location.hostname.replace(/ng./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL = wsType + "://api."+window.location.hostname.replace(/ng./gi, '')+"/websocket";
        }else{
          //window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = wsType + "://libra."+window.location.hostname.replace(/www./gi, '')+":42000/websocket";
          //window.$store.VUE_APP_OMS_SOCKET_BASE_URL = wsType + "://oms."+window.location.hostname.replace(/www./gi, '')+":40010/";
          window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL = wsType + "://libra."+window.location.hostname.replace(/www./gi, '')+"/websocket";
          window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL= wsType + "://libra."+window.location.hostname.replace(/www./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_OMS_SOCKET_BASE_URL = wsType + "://oms."+window.location.hostname.replace(/www./gi, '')+"/";
          window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL =wsType + "://oms."+window.location.hostname.replace(/www./gi, '')+"/websocketcoin";
          window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL = wsType + "://api."+window.location.hostname.replace(/www./gi, '')+"/websocket";
        }
      }

      //window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL = wsType + "://"+window.location.hostname.replace(/www./gi, '')+":8090/websocket";
      
    }

    console.log("BASE_URL >>>> ", window.$store.VUE_APP_SERVER_BASE_URL)
    console.log("SOCKET_URL >>>> ", window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL)
    console.log("COIN_SOCKET_URL >>>> ", window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL)    
    console.log("OMS_SOCKET_URL >>>> ", window.$store.VUE_APP_OMS_SOCKET_BASE_URL)
    console.log("OMS_COIN_SOCKET_URL >>>> ", window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL)
    console.log("MESSAGE_SOCKET_URL >>>> ", window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL)


    const http = axios.create({
      baseURL: window.$store.VUE_APP_SERVER_BASE_URL,
      crossDomain: true,
      withCredentials: true,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
        
    // request
    http.interceptors.request.use((config) => {

      if ( Vue.$sessionStorage.get('xDkToken') ) {
        config.headers['x-dk-token'] = Vue.$sessionStorage.get('xDkToken')
        config.headers['Access-Control-Allow-Origin'] = '*';  // CORS 설정(모든 리소스 허용)
      }

      return config
    }, (error) => {
      //window.$loading.hide()
      //window.$alert.alert(error, 'Error')
      if (error != undefined && error != null && error.indexOf("Axios Error") == -1){
        console.log("http interceptors.request error >>> ", error);
        window.$alert.alert(error, 'Error')
      }else{
        window.$alert.alert("네트워크 접속이 불안정합니다.<br/>네트워크 상태를 확인 해주세요.", 'Error')
        .then(() => {
          window.location.reload();
        });
      }
    })
        
    // response
    http.interceptors.response.use((response) => {
      //window.$loading.hide()

      // 에러 처리
      if (response.data.code !== Const.RESPONSE_TYPE.SUCCESS) {
        //throw new Error(response)
        //window.$alert.alert(response.data.message, 'Error')
        if (response.data.message != undefined && response.data.message != null && response.data.message.indexOf("Axios Error") == -1){
          console.log("http error interceptors.response >>> ", response.data.message);
          window.$alert.alert(response.data.message, 'Error')
        }else{
          window.$alert.alert("네트워크 접속이 불안정합니다.<br/>네트워크 상태를 확인 해주세요.", 'Error')
          .then(() => {
            window.location.reload();
          });
        }
        return new Promise(() => {})        
      }

      const xDkToken = response.headers['x-dk-token']

      if ( xDkToken !== undefined && xDkToken !== null ) {
        response.data.xDkToken = xDkToken
      }

      return response.data
    }, (error) => {
      //window.$loading.hide()
      
      if (error.response) {
        if ( error.response.data.code === Const.RESPONSE_TYPE.NOT_LOGIN) {
          //window.$alert.alert('로그인 인증이 만료되었습니다.<br/>다시 로그인하여 주십시오.', '안내')
          //.then(() => {
              window.$store.dispatch('Login/logout')
          //})
        } else {
          //window.$alert.alert(error.response.data.message, 'Error')
          if (error.response.data.message != undefined && error.response.data.message != null && error.response.data.message.indexOf("Axios Error") == -1){
            console.log("http logout error >>> ", error.response.data.message);
            window.$alert.alert(error.response.data.message, 'Error')
          }else{
            //403?
            window.$alert.alert("네트워크 연결중 에러가 발생했습니다.", 'Error')
            .then(() => {
              //window.location.reload();
            });
          }
        }	          
      } else {
        //window.$alert.alert(error, 'Error')
        if (error != undefined && error != null && error.indexOf("Axios Error") == -1){
          console.log("http logout error2 >>> ", error);
          window.$alert.alert(error, 'Error')
        }else{
          window.$alert.alert("네트워크 접속이 불안정합니다.<br/>네트워크 상태를 확인 해주세요.", 'Error')
          .then(() => {
            window.location.reload();
          });
        }
      }
        
      //return Promise.reject(error)
      return new Promise(() => {})
    })

    window.$http = http
    Vue.httpClient = http
    Vue.prototype.$http = http          
  }
}

export default HttpModule