<template>
  <div style="width: 100%; height: 100%; overflow: hidden;" >
    
    <!-- <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="grid.rowHeight"
      :rowHeight="grid.rowHeight"
      /> -->
    <div class="execPrice">
      <table class="execPrice-table">
        <!-- <colgroup>
          <col style="width:33%">
          <col style="width:34%">
          <col style="width:33%">
        </colgroup> -->
        <thead>
          <tr>
            <th>시간</th>
            <th>체결가</th>
            <th>수량</th>
          </tr>
        </thead>
        <tbody>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime1 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign1 != undefined && ydiffSign1 == '2') || buyerMaker1 == true , 'color-blue' : (ydiffSign1 != undefined && ydiffSign1 == '5') || buyerMaker1 == false }">{{ curPr1 }}</span></td>
            <td>{{ trDq1 }}</td>
          </tr>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime2 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign2 != undefined && ydiffSign2 == '2') || buyerMaker2 == true , 'color-blue' : (ydiffSign2 != undefined && ydiffSign2 == '5') || buyerMaker2 == false }">{{ curPr2 }}</span></td>
            <td>{{ trDq2 }}</td>
          </tr>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime3 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign3 != undefined && ydiffSign3 == '2') || buyerMaker3 == true , 'color-blue' : (ydiffSign3 != undefined && ydiffSign3 == '5') || buyerMaker3 == false }">{{ curPr3 }}</span></td>
            <td>{{ trDq3 }}</td>
          </tr>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime4 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign4 != undefined && ydiffSign4 == '2') || buyerMaker4 == true , 'color-blue' : (ydiffSign4 != undefined && ydiffSign4 == '5') || buyerMaker4 == false }">{{ curPr4 }}</span></td>
            <td>{{ trDq4 }}</td>
          </tr>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime5 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign5 != undefined && ydiffSign5 == '2') || buyerMaker5 == true , 'color-blue' : (ydiffSign5 != undefined && ydiffSign5 == '5') || buyerMaker5 == false }">{{ curPr5 }}</span></td>
            <td>{{ trDq5 }}</td>
          </tr>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime6 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign6 != undefined && ydiffSign6 == '2') || buyerMaker6 == true , 'color-blue' : (ydiffSign6 != undefined && ydiffSign6 == '5') || buyerMaker6 == false }">{{ curPr6 }}</span></td>
            <td>{{ trDq6 }}</td>
          </tr>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime7 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign7 != undefined && ydiffSign7 == '2') || buyerMaker7 == true , 'color-blue' : (ydiffSign7 != undefined && ydiffSign7 == '5') || buyerMaker7 == false }">{{ curPr7 }}</span></td>
            <td>{{ trDq7 }}</td>
          </tr>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime8 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign8 != undefined && ydiffSign8 == '2') || buyerMaker8 == true , 'color-blue' : (ydiffSign8 != undefined && ydiffSign8 == '5') || buyerMaker8 == false }">{{ curPr8 }}</span></td>
            <td>{{ trDq8 }}</td>
          </tr>
          <tr :style="{'height':this.trHeight+'px'}">
            <td>{{ execTime9 }}</td>
            <td><span :class="{ 'color-red' : (ydiffSign9 != undefined && ydiffSign9 == '2') || buyerMaker9 == true , 'color-blue' : (ydiffSign9 != undefined && ydiffSign9 == '5') || buyerMaker9 == false }">{{ curPr9 }}</span></td>
            <td>{{ trDq9 }}</td>
          </tr>
        </tbody>
      </table>        
    </div>

  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
import { stringCount } from '@/modules/helper'
import {cellClassRulesExePrc} from '@/modules/grid-utils.js'

export default {
  name: "agFilledTable",
  components: {
    AgGridVue,
  },
  computed:{
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData;
    },
    tikDotSz(){
      return stringCount(window.$store.getters['SocketPrice/getSelectSymbol'].pricescale);      
    },
    getSelectSymbol: function(){
      return window.$store.getters['SocketPrice/getSelectSymbol'];
    },
  },
  watch:{
    //소켓시세
    changeOvcData(newPrice){
      const curSymbol = window.$store.getters['SocketPrice/getSelectSymbol'];
      if (newPrice != undefined && newPrice != null){
        if (curSymbol && curSymbol.name == newPrice.symbol && newPrice.curPr > 0){
          let korTm = newPrice.korTm.replace(/\B(?=(\d{2})+(?!\d))/g, ":");
          // newPrice.execTime = korTm;
          // newPrice.curPr = newPrice.curPr.toFixed(this.tikDotSz);

          // if (this.grid.rowData.length > 0 && newPrice.symbol != this.grid.rowData[0].symbol){
          //   this.grid.rowData = [];
          // }

          // if (this.grid.rowData.length > this.rowCnt){
          //   this.grid.rowData.splice(this.grid.rowData.length-1, 1);
          // }

          // this.grid.rowData.unshift(newPrice);

          // export const cellClassRulesExePrc = {
          //   red: params => params.data.ydiffSign == "2",
          //   blue: params => params.data.ydiffSign == "5",
          // }
          this.execTime9 = this.execTime8;
          this.curPr9 = this.curPr8;
          this.trDq9 = this.trDq8;
          this.ydiffSign9 = this.ydiffSign8;

          this.execTime8 = this.execTime7;
          this.curPr8 = this.curPr7;
          this.trDq8 = this.trDq7;
          this.ydiffSign8 = this.ydiffSign7;

          this.execTime7 = this.execTime6;
          this.curPr7 = this.curPr6;
          this.trDq7 = this.trDq6;
          this.ydiffSign7 = this.ydiffSign6;

          this.execTime6 = this.execTime5;
          this.curPr6 = this.curPr5;
          this.trDq6 = this.trDq5;
          this.ydiffSign6 = this.ydiffSign5;

          this.execTime5 = this.execTime4;
          this.curPr5 = this.curPr4;
          this.trDq5 = this.trDq4;
          this.ydiffSign5 = this.ydiffSign4;

          this.execTime4 = this.execTime3;
          this.curPr4 = this.curPr3;
          this.trDq4 = this.trDq3;
          this.ydiffSign4 = this.ydiffSign3;

          this.execTime3 = this.execTime2;
          this.curPr3 = this.curPr2;
          this.trDq3 = this.trDq2;
          this.ydiffSign3 = this.ydiffSign2;


          this.execTime2 = this.execTime1;
          this.curPr2 = this.curPr1;
          this.trDq2 = this.trDq1;
          this.ydiffSign2 = this.ydiffSign1;


          this.execTime1 = korTm;
          this.curPr1 = newPrice.curPr.toFixed(this.tikDotSz);
          this.trDq1 = newPrice.trDq;
          this.ydiffSign1 = newPrice.ydiffSign;
        }
      }
    },
    //폴링시세
    changeOvcDataList(newPrice){
      const curSymbol = window.$store.getters['SocketPrice/getSelectSymbol'];
      if (curSymbol && newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (data.symbol == curSymbol.name){
            let korTm;
            if (data.korTm){
              korTm = data.korTm.replace(/\B(?=(\d{2})+(?!\d))/g, ":");
              //console.log("korTm", korTm)
            }
            // data.execTime = korTm;
            // data.curPr = data.curPr.toFixed(this.tikDotSz)
            
            // //체결시간/수량이 같으면 무시
            // if (data.korTm != undefined && data.korTm != null && this.grid.rowData.length > 0 && data.korTm == this.grid.rowData[0].korTm && data.trDq == this.grid.rowData[0].trDq){
            //   return;
            // }

            // if (this.grid.rowData.length > 0 && data.symbol != this.grid.rowData[0].symbol){
            //   this.grid.rowData = [];
            // }

            // if (this.grid.rowData.length > this.rowCnt){
            //   this.grid.rowData.splice(this.grid.rowData.length-1, 1);
            // }

            // this.grid.rowData.unshift(data);

            this.execTime9 = this.execTime8;
            this.curPr9 = this.curPr8;
            this.trDq9 = this.trDq8;
            this.ydiffSign9 = this.ydiffSign8;

            this.execTime8 = this.execTime7;
            this.curPr8 = this.curPr7;
            this.trDq8 = this.trDq7;
            this.ydiffSign8 = this.ydiffSign7;

            this.execTime7 = this.execTime6;
            this.curPr7 = this.curPr6;
            this.trDq7 = this.trDq6;
            this.ydiffSign7 = this.ydiffSign6;

            this.execTime6 = this.execTime5;
            this.curPr6 = this.curPr5;
            this.trDq6 = this.trDq5;
            this.ydiffSign6 = this.ydiffSign5;

            this.execTime5 = this.execTime4;
            this.curPr5 = this.curPr4;
            this.trDq5 = this.trDq4;
            this.ydiffSign5 = this.ydiffSign4;

            this.execTime4 = this.execTime3;
            this.curPr4 = this.curPr3;
            this.trDq4 = this.trDq3;
            this.ydiffSign4 = this.ydiffSign3;

            this.execTime3 = this.execTime2;
            this.curPr3 = this.curPr2;
            this.trDq3 = this.trDq2;
            this.ydiffSign3 = this.ydiffSign2;


            this.execTime2 = this.execTime1;
            this.curPr2 = this.curPr1;
            this.trDq2 = this.trDq1;
            this.ydiffSign2 = this.ydiffSign1;


            this.execTime1 = korTm;
            this.curPr1 = newPrice.curPr.toFixed(this.tikDotSz);
            this.trDq1 = newPrice.trDq;
            this.ydiffSign1 = newPrice.ydiffSign;
          }
        })
      }
    },
    getSelectSymbol(newVal){
      if (newVal == undefined) return;
      //종목이 다를경우 초기화
      this.resetSymbol(newVal);
    },
  },
  data() {
    return {
      execTime1: null,
      curPr1 : null,
      trDq1 : null,
      ydiffSign1: null,
      buyerMaker1: null,
      execTime2: null,
      curPr2 : null,
      trDq2 : null,
      ydiffSign2: null,
      buyerMaker2: null,
      execTime3: null,
      curPr3 : null,
      trDq3 : null,
      ydiffSign3: null,
      buyerMaker3: null,
      execTime4: null,
      curPr4 : null,
      trDq4 : null,
      ydiffSign4: null,
      buyerMaker4: null,
      execTime5: null,
      curPr5 : null,
      trDq5 : null,
      ydiffSign5: null,
      buyerMaker5: null,
      execTime6: null,
      curPr6 : null,
      trDq6 : null,
      ydiffSign6: null,
      buyerMaker6: null,
      execTime7: null,
      curPr7 : null,
      trDq7 : null,
      ydiffSign7: null,
      buyerMaker7: null,
      execTime8: null,
      curPr8 : null,
      trDq8 : null,
      ydiffSign8: null,
      buyerMaker8: null,
      execTime9: null,
      curPr9 : null,
      trDq9 : null,
      ydiffSign9: null,
      buyerMaker9: null,
      trHeight: 24,
      grid:{
        columnDefs: [
          { 
            headerName: "시간", 
            field: "execTime",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "체결가",
            field: "curPr",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesExePrc,
          },
          { 
            headerName: "수량",
            field: "trDq",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          
        ],
        rowData: [],
        gridApi: null,
        autoSizeStrategy: null,
        paginationPageSize: null,
      },
      currentPage: '',
      totalPages: '',
			rowHeight: '',
      symbol:null,
      rowCnt: 10,
    }
  },
	created() {
    // this.paginationPageSize = 12;
		this.isMobile();
  },
  methods: {
		isMobile() {
      if (window.name == undefined || window.name == null || window.name == ""){
        if (window.innerWidth <= 1200) {
          this.rowCnt = 22;
          this.trHeight = 30;
          return this.rowHeight = 40;
        } else {
          this.trHeight = 25;
          return this.rowHeight = 30;
        }
      }else{
        this.trHeight = 25;
        return this.rowHeight = 30;
      }
    },
    resetSymbol(newVal){
      if (this.symbol == null || (this.symbol != null && this.symbol.name != newVal.name)){
        this.grid.rowData = [];

        this.execTime9 = null;
        this.curPr9 = null;
        this.trDq9 = null;
        this.ydiffSign9 = null;
        this.buyerMaker9 = null;

        this.execTime8 = null;
        this.curPr8 = null;
        this.trDq8 = null;
        this.ydiffSign8 = null;
        this.buyerMaker8 = null;

        this.execTime7 = null;
        this.curPr7 = null;
        this.trDq7 = null;
        this.ydiffSign7 = null;
        this.buyerMaker7 = null;

        this.execTime6 = null;
        this.curPr6 = null;
        this.trDq6 = null;
        this.ydiffSign6 = null;
        this.buyerMaker6 = null;

        this.execTime5 = null;
        this.curPr5 = null;
        this.trDq5 = null;
        this.ydiffSign5 = null;
        this.buyerMaker5 = null;

        this.execTime4 = null;
        this.curPr4 = null;
        this.trDq4 = null;
        this.ydiffSign4 = null;
        this.buyerMaker4 = null;

        this.execTime3 = null;
        this.curPr3 = null;
        this.trDq3 = null;
        this.ydiffSign3 = null;
        this.buyerMaker3 = null;

        this.execTime2 = null;
        this.curPr2 = null;
        this.trDq2 = null;
        this.ydiffSign2 = null;
        this.buyerMaker2 = null;

        this.execTime1 = null;
        this.curPr1 = null;
        this.trDq1 = null;
        this.ydiffSign1 = null;
        this.buyerMaker1 = null;
      }
      this.symbol = newVal;
    }
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>