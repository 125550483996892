<template>
  <button class="btn-link" @click="showModify(params)">정정</button>
</template>

<script>
export default {
	name: 'AgModify',
	methods: {
    showModify(params) {
      this.$modal.show("modalEditOrder", params.data);
    },
	},
}
</script>