<script setup></script>

<template>
  <div class="ag-time">
    <div class="date">{{ params.data.updDt }}</div>
    <div class="time">{{ params.data.updTm }}</div>
  </div>
</template>

<script>
export default {
  name: 'AgTime',
  components: {
  },
  computed: {
  },
  watch: {

  },
  data() {
    return {

    }
  },
  created(){
  },
  mounted() {
  },
  methods:{
    addTpSl(params){
      params.clicked(params.data);
    }
  },

}
</script>


<style lang="scss" scoped>
.ag-time {
  text-align: center;
  font-size: 12px;
	line-height: 12px;
	.date {
    font-weight: bold;
  }
}
</style>
