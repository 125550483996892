<template>
  <div class="filled-container">
    <div class="panel-header">
      <div class="header-title">거래기록</div>
      <button>
        <svg class="more-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 10h4v4h-4v-4zM17 10h4v4h-4v-4zM3 10h4v4H3v-4z" fill="#888888"></path>
        </svg>
      </button>
    </div>
    <div class="filled-table">
      <div class="filled-label">
        <div class="price">가격</div>
        <div class="volume">수량</div>
        <div class="time">시간</div>
      </div>
      <div class="inner-table">
        <div class="filled-row" :class="{ 'sell' : buyerMaker1 == true , 'buy' : buyerMaker1 == false }" v-if="curPr1 != null">
          <div class="price">{{ curPr1 }}</div>
          <div class="volume">{{ trDq1 }}</div>
          <div class="time">{{ execTime1 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker2 == true , 'buy' : buyerMaker2 == false }" v-if="curPr2 != null">
          <div class="price">{{ curPr2 }}</div>
          <div class="volume">{{ trDq2 }}</div>
          <div class="time">{{ execTime2 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker3 == true , 'buy' : buyerMaker3 == false }" v-if="curPr3 != null">
          <div class="price">{{ curPr3 }}</div>
          <div class="volume">{{ trDq3 }}</div>
          <div class="time">{{ execTime3 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker4 == true , 'buy' : buyerMaker4 == false }" v-if="curPr4 != null">
          <div class="price">{{ curPr4 }}</div>
          <div class="volume">{{ trDq4 }}</div>
          <div class="time">{{ execTime4 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker5 == true , 'buy' : buyerMaker5 == false }" v-if="curPr5 != null">
          <div class="price">{{ curPr5 }}</div>
          <div class="volume">{{ trDq5 }}</div>
          <div class="time">{{ execTime5 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker6 == true , 'buy' : buyerMaker6 == false }" v-if="curPr6 != null">
          <div class="price">{{ curPr6 }}</div>
          <div class="volume">{{ trDq6 }}</div>
          <div class="time">{{ execTime6 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker7 == true , 'buy' : buyerMaker7 == false }" v-if="curPr7 != null">
          <div class="price">{{ curPr7 }}</div>
          <div class="volume">{{ trDq7 }}</div>
          <div class="time">{{ execTime7 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker8 == true , 'buy' : buyerMaker8 == false }" v-if="curPr8 != null">
          <div class="price">{{ curPr8 }}</div>
          <div class="volume">{{ trDq8 }}</div>
          <div class="time">{{ execTime8 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker9 == true , 'buy' : buyerMaker9 == false }" v-if="curPr9 != null">
          <div class="price">{{ curPr9 }}</div>
          <div class="volume">{{ trDq9 }}</div>
          <div class="time">{{ execTime9 }}</div>
        </div>
        <div class="filled-row" :class="{ 'sell' : buyerMaker10 == true , 'buy' : buyerMaker10 == false }" v-if="curPr10 != null">
          <div class="price">{{ curPr10 }}</div>
          <div class="volume">{{ trDq10 }}</div>
          <div class="time">{{ execTime10 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stringCount } from '@/modules/helper';

export default {
  name: 'fillPanel',
  components: {
  },
  computed: {
    //소켓 코인 시세
    changeCocData: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //폴링시세
    changeCocDataList: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    tikDotSz(){
      return stringCount(this.coinSymbol.pricescale);
    },
  },
  watch: {
    //소켓시세
    changeCocData(newPrice){
      const curSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
      if (newPrice != undefined && newPrice != null && curSymbol != undefined){
        if (curSymbol && curSymbol.name == newPrice.symbol && newPrice.curPr > 0){
          let korTm = newPrice.korTm.replace(/\B(?=(\d{2})+(?!\d))/g, ":");

          this.execTime10 = this.execTime9;
          this.curPr10 = this.curPr9;
          this.trDq10 = this.trDq9;
          this.ydiffSign10 = this.ydiffSign9;
          this.buyerMaker10 = this.buyerMaker9;

          this.execTime9 = this.execTime8;
          this.curPr9 = this.curPr8;
          this.trDq9 = this.trDq8;
          this.ydiffSign9 = this.ydiffSign8;
          this.buyerMaker9 = this.buyerMaker8;

          this.execTime8 = this.execTime7;
          this.curPr8 = this.curPr7;
          this.trDq8 = this.trDq7;
          this.ydiffSign8 = this.ydiffSign7;
          this.buyerMaker8 = this.buyerMaker7;

          this.execTime7 = this.execTime6;
          this.curPr7 = this.curPr6;
          this.trDq7 = this.trDq6;
          this.ydiffSign7 = this.ydiffSign6;
          this.buyerMaker7 = this.buyerMaker6;

          this.execTime6 = this.execTime5;
          this.curPr6 = this.curPr5;
          this.trDq6 = this.trDq5;
          this.ydiffSign6 = this.ydiffSign5;
          this.buyerMaker6 = this.buyerMaker5;

          this.execTime5 = this.execTime4;
          this.curPr5 = this.curPr4;
          this.trDq5 = this.trDq4;
          this.ydiffSign5 = this.ydiffSign4;
          this.buyerMaker5 = this.buyerMaker4;

          this.execTime4 = this.execTime3;
          this.curPr4 = this.curPr3;
          this.trDq4 = this.trDq3;
          this.ydiffSign4 = this.ydiffSign3;
          this.buyerMaker4 = this.buyerMaker3;

          this.execTime3 = this.execTime2;
          this.curPr3 = this.curPr2;
          this.trDq3 = this.trDq2;
          this.ydiffSign3 = this.ydiffSign2;
          this.buyerMaker3 = this.buyerMaker2;


          this.execTime2 = this.execTime1;
          this.curPr2 = this.curPr1;
          this.trDq2 = this.trDq1;
          this.ydiffSign2 = this.ydiffSign1;
          this.buyerMaker2 = this.buyerMaker1;


          this.execTime1 = korTm;
          this.curPr1 = newPrice.curPr.toFixed(this.tikDotSz);
          this.trDq1 = newPrice.trDq.toFixed(3);
          this.ydiffSign1 = newPrice.ydiffSign;
          this.buyerMaker1 = newPrice.buyerMaker;


        }
      }
      //
    },
    //폴링시세
    changeCocDataList(newPrice){
      //
    },
    getSelectCoinSymbol(newVal){
      if (newVal == undefined) return;
      // this.rStock = Object.assign({}, null);

      //종목이 변경될경우 소켓데이터 연결 갱신
      this.coinSymbol = Object.assign({}, newVal);
    },
  },
  data() {
    return {
      execTime1: null,
      curPr1 : null,
      trDq1 : null,
      ydiffSign1: null,
      buyerMaker1: null,
      execTime2: null,
      curPr2 : null,
      trDq2 : null,
      ydiffSign2: null,
      buyerMaker2: null,
      execTime3: null,
      curPr3 : null,
      trDq3 : null,
      ydiffSign3: null,
      buyerMaker3: null,
      execTime4: null,
      curPr4 : null,
      trDq4 : null,
      ydiffSign4: null,
      buyerMaker4: null,
      execTime5: null,
      curPr5 : null,
      trDq5 : null,
      ydiffSign5: null,
      buyerMaker5: null,
      execTime6: null,
      curPr6 : null,
      trDq6 : null,
      ydiffSign6: null,
      buyerMaker6: null,
      execTime7: null,
      curPr7 : null,
      trDq7 : null,
      ydiffSign7: null,
      buyerMaker7: null,
      execTime8: null,
      curPr8 : null,
      trDq8 : null,
      ydiffSign8: null,
      buyerMaker8: null,
      execTime9: null,
      curPr9 : null,
      trDq9 : null,
      ydiffSign9: null,
      buyerMaker9: null,
      curPr10 : null,
      trDq10 : null,
      ydiffSign10: null,
      buyerMaker10: null,
      coinSymbol: null,
    }
  },
  created(){
  },
  mounted() {
  },
  methods:{

  },

}
</script>