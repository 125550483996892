<template>
  <div class="slider-container">
    <input class="slider-input" type="range" value="1" min="1" max="125" step="1" list="my-detents" v-model="sliderValue" @input="updateSliderWidth"/>
    <div class="slider-track">
      <div class="slider-track-thumb" :style="{ width: thumbWidth }"></div>
      <div class="slider-dot" :class="{ active: sliderValue >= 0 }">
        <div class="label">1x</div>
      </div>
      <div class="slider-dot" style="left: 20%" :class="{ active: sliderValue >= 25 }">
        <div class="label" style="left: -1px">25x</div>
      </div>
      <div class="slider-dot" style="left: 39.5%" :class="{ active: sliderValue >= 50 }">
        <div class="label" style="left: -2px;">50x</div>
      </div>
      <div class="slider-dot" style="left: 60%" :class="{ active: sliderValue >= 75 }">
        <div class="label" style="left: -4px">75x</div>
      </div>
      <div class="slider-dot" style="left: 80%" :class="{ active: sliderValue >= 100 }">
        <div class="label" style="left: -5px;">100x</div>
      </div>
      <div class="slider-dot" style="left: 100%" :class="{ active: sliderValue >= 125 }">
        <div class="label" style="left: -13px;">125x</div>
      </div>
    </div>
    <datalist id="my-detents">
      <option value="25"></option>
      <option value="50"></option>
      <option value="75"></option>
      <option value="100"></option>
      <option value="125"></option>
    </datalist>
  </div>
</template>
<script>
export default {
  name: 'SliderLeverage',
  props: {
    value:{
      type : Number,
      default: 1,
    }
  },
  watch:{
    value:{
      handler(newVal){
        console.log("value?? ", newVal)
        this.sliderValue = Number(newVal);
        this.thumbWidth = `${((newVal - 0) / (125 - 0)) * 100}%`;
      }
    }
  },
	data() {
		return {
			sliderValue: 0,
      thumbWidth: '0%',
		}
	},
	computed: {
    // thumbPosition() {
    //   // Calculate the left position based on slider value
    //   return `${this.sliderValue}%`;
    // }
  },
  mounted(){
    if (this.value != undefined && this.value > 0){
      this.sliderValue = this.value;
      this.thumbWidth = `${((this.value - 0) / (125 - 0)) * 100}%`;
    }
  },
	methods: {
    updateSliderWidth() {
      // Calculates the width of the thumb based on the slider value
      //this.thumbWidth = `${((this.sliderValue - 0) / (125 - 0)) * 100}%`;

      this.$emit("updateSliderLeverage", this.sliderValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  width: 100%;
	position: relative;
	height: 25px;
	margin: 10px 0 30px 0;
	/* border: 1px solid red; */
  .slider-input {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
    -webkit-appearance: none;
    width: 100%;
    height: 26px;
    border-radius: 5px;
    /* background: #d3d3d3; */
    outline: none;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: white;
			border: 2px solid var(--btn-primary);
      cursor: pointer;
			/* margin-top: -17px; */
			@media (max-width: 800px) {
				width: 13px;
				height: 13px;
			}
    }
		/* &::-ms-thumb {
			margin-top: 0;
		} */
  }
	.slider-track {
		position: absolute;
		top: 11px;
		width: calc(100% - 9px);
		height: 2px;
		background-color: #dddddd;
		@media (max-width: 800px) {
			top: 12px;
			width: calc(100% - 9px);
		}
		.slider-track-thumb {
			width: 100%;
			height: 3px;
			background-color: var(--btn-primary);
		}
		.slider-dot {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 8px;
			height: 8px;
			border-radius: 50vh;
			background-color: #dddddd;
			@media (max-width: 800px) {
				/* top: 12px; */
				transform: translateY(-48%);
			}
			&.active {
				background-color: var(--btn-primary);
			}
			.label {
				position: absolute;
				top: 20px;
				/* left: 50%; */
				/* transform: translateX(-50%); */
				font-size: 10px;
			}
		}
	}
}
</style>
