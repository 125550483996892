<template>
  <div style="width: 100%; height: 100%">
  <ag-grid-vue
    style="width: 100%; height: 100%"
    class="ag-theme-wts"
    :columnDefs="grid.columnDefs"
    :rowData="grid.rowData"
    :headerHeight="30"
    :rowHeight="40"
    :columnHoverHighlight="true"
    :overlayNoRowsTemplate="grid.overlayNoRowsTemplate"
    @grid-ready="onGridReady"
  />
  </div>
</template>


<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
import AgInstrument from "@/components/coin/AgInstrument";
import AgTpSl from "@/components/coin/AgTpSl.vue";
import AgPositionType from "@/components/coin/AgPositionType.vue";
import {coinBuySellType, cellClassRulesMinus} from '@/modules/grid-utils.js';
import gridUtils from '@/modules/grid-utils';
import Utils from '@/modules/utils';
import EventBus from '@/modules/event-bus';

export default {
  name: "AgTransactionTable",
  components: {
    AgGridVue,
    AgInstrument,
    AgTpSl,
    AgPositionType
  },
  props:{
    coinAccount: {
      type: Object
    },
  },
  computed: {
    getCoinSymbolList: function(){
      let symbolList = window.$store.getters['Symbol/getCoinSymbolList']
      return symbolList;
    },
    //소켓 코인 시세
    changeCocData: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 호가
    changeCohData: function(){
      let cohData = window.$store.getters['SocketCoinPrice/getCohData']
      return cohData
    },
    //소켓 코인 센터가
    changeCotData: function(){
      let cotData = window.$store.getters['SocketCoinPrice/getCotData']
      return cotData
    },
    //소켓 코인 청산가
    changeComData: function(){
      let comData = window.$store.getters['SocketCoinPrice/getComData']
      return comData
    },
    //폴링시세
    changeCocDataList: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    //폴링호가
    changeCohDataList: function(){
      let cohData = window.$store.getters['SocketCoinPrice/getCohDataList']
      return cohData
    },
    resultCoinOms: function(){
        return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    },
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    getCoinPosOrdStList: function(){
      return window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
    },
    getUsrPos: function(){
      return window.$store.getters['SocketCoinOms/getUsrPos'];
    }
  },
  watch: {
    getCoinSymbolList(coinSymbolList){
      this.coinSymbolList = coinSymbolList;
      //this.searchPosOrdStList();
    },
    //소켓시세
    changeCocData(newPrice){
      //console.log("newprcie>>>> ", newPrice.symbol, newPrice.curPr)
      this.cocMap.set(newPrice.symbol, newPrice);
      // if (newPrice != null && this.coinSymbol != null && newPrice.symbol == this.coinSymbol.name){
      //   this.coc = newPrice;
        this.setGridPrice(); //현재가, 평가손익 새로고침
      // }
      
    },
    //소켓호가
    changeCohData(newPrice){
      if (newPrice != null && this.coinSymbol != null && newPrice.symbol == this.coinSymbol.name){
        //this.updateCoh(newPrice);
      }
    },
    //소켓 코인 센터가
    changeCotData(newPrice){
      if (newPrice != null && this.coinSymbol != null && newPrice.symbol == this.coinSymbol.name){       
        this.cot = newPrice
      }
    },
    //소켓 코인 청산가
    changeComData(newPrice){
      this.comMap.set(newPrice.symbol, newPrice);
      if (newPrice != null && this.coinSymbol != null && newPrice.symbol == this.coinSymbol.name){
        this.com = newPrice
      }
    },
    //폴링시세
    changeCocDataList(newPrice){
      //
    },
    //폴링호가
    changeCohDataList(newPrice){
      //
    },
    resultCoinOms(){ 
      this.searchCoinPosOrdStList();
    },
    getSelectCoinSymbol(newVal){
      if (newVal == undefined) return;
      //종목이 변경될경우 소켓데이터 연결 갱신
      this.coinSymbol = Object.assign({}, newVal);

      this.coinNowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};

      this.tikSize = newVal.minmov / newVal.pricescale
      //this.searchCoinPosOrdStList();
    },
    getCoinPosOrdStList(newList){
      this.coinPosOrdStList = newList;
      this.searchCoinPosOrdStList();
    },
    coinAccount:{
      handler(newVal){       
        this.searchCoinPosOrdStList();
      }
    },
    getUsrPos(usrPos){
      if (usrPos != undefined){
        this.usrPos = usrPos;
      }
    }
  },
  data() {
    return {
      grid:{
        columnDefs: [
          { 
            headerName: "종목", 
            field: "symCd",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellRenderer: 'AgInstrument',
            cellRendererParams: { leverage: 1 },
          },
          { 
            headerName: "방향",
            field: "ordSdNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: coinBuySellType,
          },
          { 
            headerName: "수량",
            field: "balQty",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            //valueFormatter: gridUtils.numberComma,
            valueFormatter: (d) =>{
              let val = d.value;
              if (d.data.ordSdNm === '매도'){
                val = val * -1;
              }
              return Utils.numberComma(val);
            },
            cellClassRules: {
              blue: (d) => d.data.ordSdNm === '매도',
              red: (d) => d.data.ordSdNm === '매수',
            },
          },
          { 
            headerName: "진입가격",
            field: "execPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              let val = d.value;
              let symbol = null;
              this.coinSymbolList.forEach(item => {
              if (item.symbolno == d.data.symNo){
                  symbol = item;
                  return;
              }
              });
              return val.toFixed(this.tikDotSz(symbol));
            },
          },
          { 
            headerName: "시장평균가",
            field: "markPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null){
                  return 0;
              }else if (d.data.ordType == "ORD" || d.data.ordType == "ST"){
                  return '';
              }else{
                  let coinSymbol = null;
                  this.coinSymbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    coinSymbol = item;
                      return;
                  }
                  });
                  return Number(d.value).toFixed(this.tikDotSz(coinSymbol));
              }
            }
          },
          { 
            headerName: "청산가",
            field: "liqPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "마진(비용)",
            field: "marginPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "PNL(ROE)",
            field: "valPl",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: (d) =>{
              let val = 0;
              if (d.value == undefined || d.value == null){
                val =  0;
              }else{
                let coinSymbol = null;
                  this.coinSymbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    coinSymbol = item;
                      return;
                  }
                  });
                  val = Number(d.value).toFixed(this.tikDotSz(coinSymbol));
              }
              val = val + " USDT";

              return val;
              //gridUtils.numberComma
            }
          },
          { 
            headerName: "익절/손절",
            field: "stoploss",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellRenderer: 'AgTpSl',
            cellRendererParams: {
              clicked: (data) => {
                this.tpslPop(data);
              },
            },
          },
          { 
            headerName: "포지션청산",
            field: "clearRtPos",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellRenderer: 'AgPositionType',
            cellRendererParams: {
              clicked: (ordTpCd, data) => {
                console.log("ordTpCd >>>> ", ordTpCd, data);
                if (ordTpCd == "1"){ //지정가 팝업호출
                  this.orderLimitPop(data);
                }else{ //시장가 청산
                  this.orderClear(data);
                }
              },
            },
            
          },
        ],
        rowData: [],
        currentPage: '',
        totalPages: '',
        rowHeight: '',
        overlayNoRowsTemplate: '<span style="margin-top: 30px;">조회된 실시간 보유가 없습니다.</span>',
      },
      gridApi: null,
      gridColumnApi:null,
      isGridReady:false,
      coinSymbolList:[],
      selectList:[],
      cocMap: new Map(),
      comMap: new Map(),
      currencyInfo:{currency:"WON"},
      coinSymbol: null,
      orderFlag:false,
      orderSetting: null,
      coinPosOrdStList:[],
      coc:{
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      cot:{
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      com:{
        markPr: 0,
      },
      bidsList:[],
      asksList:[],
      coinNowPrice: {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0},
      usrPos: {cpos:'1', leverage:1, marginMode:'1'}

    }
  },
  created() {
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom();
    })
  },
  mounted(){
    this.loadCustom();
    this.searchCoinPosOrdStList();

    
    if (this.coinSymbolList == undefined || this.coinSymbolList.length == 0){
        this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];
    }

    if (this.coinSymbol == undefined || this.coinSymbol == null){
        this.coinSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    }
  },
  methods: {
    loadCustom(){
      this.orderSetting = window.$store.getters['Layout/getOrderSetting'];
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.isGridReady = true;
      this.searchCoinPosOrdStList();
    },
    async searchCoinPosOrdStList(){
      if (!this.coinAccount.accId) return;

      //this.grid.rowData = [];
      this.selectList = [];

      const cocData =  await window.$http.get('apisise/getCocData', { params: {} } );
      //const comData =  await window.$http.get('apisise/getComData', { params: {} } );

      

      if (this.coinPosOrdStList.length == 0){
        this.coinPosOrdStList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList']
      }

      if ( this.coinPosOrdStList) {
        let rowData = [];
        this.coinPosOrdStList.forEach((data) => {
          for (let coc in cocData.data){
            if (coc == data.symCd){
              data.nowPrice = cocData.data[coc].curPr;
            }
            if (this.cocMap.get(coc) == undefined || this.cocMap.get(coc).curPr == undefined){
              this.cocMap.set(coc, cocData.data[coc]);
            }
          }
          //markPrc

          // for (let com in comData.data){
          //   if (coc == data.symCd){
          //     data.nowPrice = cocData.data[coc].curPr;
          //   }
          //   if (this.cocMap.get(coc) == undefined || this.cocMap.get(coc).curPr == undefined){
          //     this.cocMap.set(coc, cocData.data[coc]);
          //   }
          // }

          data.valPl = '';
          data.valRoi = 0;
          data.nowPrice = 0;
          if (data.balQty > 0 && data.ordType == "POS"){
            //data.checkBoxId = "AgPosition_"+ data.symCd;
            rowData.push(data);
          }
          // if (data.ordType == "POS"){
          //   data.actionName = "청산";
          // }else if (data.ordType == "ORD"){
          //   data.actionName = "취소";
          // }else if (data.ordType == "ST"){
          //   data.actionName = "취소";
          // }

          data.marginMode = this.usrPos.marginMode;
          
        });

        this.grid.rowData = rowData;
        

        if (this.gridApi != null)
          this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침

        this.setGridPrice();
        
        //잔고 시세 업데이트
        //this.$emit("updateSubscribeOnPrice", this.grid.rowData);
      }else{
        //잔고 목록이 없으면 평가손익 초기화
        this.setGridPrice();
        this.selectList = [];
      }
    },
    tikVal(coinSymbol){
      if (coinSymbol == null){
        return 0;
      }
      return (coinSymbol.minmov / coinSymbol.pricescale);
    },
    tikDotSz(coinSymbol){
      if (coinSymbol == null){
        return 0;
      }
      return Utils.tikDotSz(coinSymbol);
    },
    setGridPrice(){
      let totData = {totalValPl:0, totalQty:0, totalLossCut:0};
      if (this.grid.rowData != null && this.grid.rowData.length > 0){
        let totalValPl = 0;
        let totalQty = 0;
        let totalLossCut = 0;

        let usdExr = 0;
        let usdExHkd = 0;
        let hkdExR = 0;
        const coinDailyPlMn = window.$store.getters['SocketCoinOms/getCoinDailyPlMn'];
        if (coinDailyPlMn && coinDailyPlMn.usdExR) usdExr = coinDailyPlMn.usdExR;

        this.grid.rowData.forEach(data => {
          if (data.balQty > 0 && data.ordType == "POS"){
            totalQty += Number(data.balQty);
            totalLossCut += (Number(data.rmsSetAmt) * Number(data.balQty)); //RMS_SET_AMT
            //console.log("this.cocMap.get(data.symCd)", this.cocMap.get(data.symCd))
            // if (this.coc != undefined)
            // data.nowPrice = this.coc.curPr;

            

            //let res = this.coinSymbolCalPrc(data, this.cocMap.get(data.symCd), this.coinSymbolList, usdExr, usdExHkd, hkdExR, data.lv);
            // 계산 공통사용변경
            // data - 그리드 1개 row data
            // cocMap
            // symbolList
            // usdExr 환율
            // usdExHkd 홍콩달러환율
            // hkdExR 홍콩환율
            // lvTpCd 레버리지여부
            // currency 원/달러 구분 WON
            // return {data, }
            let res = Utils.coinSymbolCalPrc(data, this.cocMap.get(data.symCd), this.coinSymbolList, data.lv);
            //data = res.data; //데이터가 갱신 안되면 주석해제
            //totalValPl += Number(res.valProLoss);




          }
        });
        if (this.gridApi != null)
          this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침

        totData.totalQty = totalQty;
        totData.totalValPl = totalValPl;
        totData.totalLossCut = totalLossCut;

        //this.$emit("updateTotalValPl", totalValPl);
      }else{
        //this.$emit("updateTotalValPl", 0);
      }

      //this.$emit("updateTotalValPl", totData);
    },
    //스탑로스 팝업 호출
    tpslPop(){
      console.log("스탑로스 팝업 호출...");
    },
    //지정가 청산팝업 호출
    orderLimitPop(selData){
      console.log("지정가 청산 팝업 호출...");
    },
    //시장가 청산
    orderClear(selData) {
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined ||this.coinAccount.accId == 0){
        window.$alert.alert('코인 계좌 정보가 없습니다.', '알림')
        return;
      }

      console.log("selData", selData)

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      if (!selData){
        this.$alert.alert("선택한 주문이 없습니다.", '알림');
        return;
      }

      // if (this.orderFlag){
      //   return;
      // }
      let coinSymbolData = null;
      this.coinSymbolList.forEach(item => {
        if (item.name == selData.symCd){
          coinSymbolData = item;
          return;
        }
      });

      let message = "코인 체결리스트 청산 클릭 "+ selData.symCd;
      this.sendCoinOmsLogMessage(message);

      let selectList = [{symCd:selData.symCd}];

      const order = {
        accId : this.coinAccount.accId,
        trdDt : '',
        //accPass : this.getUsrAccPass,
        ordSdCd: selData.ordSdCd,
        coinSymbolList : selectList,
        cposTpCd : selData.cposTpCd,
      }

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        const orderData = {apiCode:"COMS0014", order: order, symbol:coinSymbolData, qty:selData.balQty, price: selData.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendCoinOmsMessage("COMS0014", order);
      }

      // this.$alert.confirm('선택한 보유잔고를 청산하시겠습니까?', '청산확인')
      //   .then((response) => {
      //     if (response) { 
      //       //this.orderFlag = true;

      //       const order = {
      //         accId : this.coinAccount.accId,
      //         trdDt : '',
      //         //accPass : this.getUsrAccPass,
      //         symbolList : selectList,
      //       }
      //       this.sendCoinOmsMessage('COMS0014', order)
      //     }
      //   })

      //1.5초후 버튼 누를 수 있게 설정
      // if (this.orderFlag){
      //   setTimeout(() => {
      //     this.orderFlag = false
      //   }, 1500)
      // }
    },
    cancelStock(data) {
      if ( data.symCd === undefined
        || data.execPrc === undefined ) {
        return;
      } 
  
      let orgOrders = new Array();
      let totCnt = 0;
      let totOrdQty = 0;

      totCnt++;
      totOrdQty += data.ordQty;

      const orgOrder = {
        accId : this.coinAccount.accId,
        symCd : data.symCd,
        symNo : data.symNo,
        trdDt : data.trdDt,
        ordId : 0,
        orgOrdNo: data.ordId,
        ordSdCd : data.ordSdCd,
        ordTpCd : data.ordTpCd,
        ordPrc : data.execPrc,
        ordQty : data.ordQty,
        repReqQty : data.repReqQty,
        cxlReqQty : data.ordQty,
        orgUsrKey : data.usrKey,
      }

      orgOrders.push(orgOrder);
        
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.coinAccount.accId,
          symCd : data.symCd,
          symNo : data.symNo,
          //accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : data.ordSdCd
      }

      let coinSymbolData;

      this.coinSymbolList.forEach(symbol=>{
        if (symbol.name == data.symCd){
          coinSymbolData = symbol;
        }
      })

      let message = "코인 체결리스트 지정가 취소 클릭 "+ data.symCd;
      //this.sendCoinOmsLogMessage(message);
  
      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"COMS0002", order: order, symbol:coinSymbolData ,qty:totOrdQty, price: data.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendCoinOmsMessage('COMS0002', order)

      }
    },
    cancelStStock(data) {
      if ( data.symCd === undefined
        || data.execPrc === undefined ) {
        return;
      } 

      let orgStOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      totCnt++;
      totOrdQty += data.ordQty

      const orgOrder = {
        accId : this.coinAccount.accId,
        symCd : data.symCd,
        symNo : data.symNo,
        trdDt : data.trdDt,
        ordId : 0,
        orgOrdNo: data.ordId,
        ordSdCd : data.ordSdCd,
        ordTpCd : data.ordTpCd,
        ordPrc : data.execPrc,
        ordQty : data.ordQty,
        repReqQty : data.repReqQty,
        cxlReqQty : data.ordQty,
        orgUsrKey : data.usrKey
      }

      orgStOrders.push(orgOrder);
      
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.coinAccount.accId,
          symCd : this.coinSymbol.name,
          symNo : this.coinSymbol.symbolno,
          ordSdCd : '1',
          //accPass : this.getUsrAccPass,
          orderList : orgStOrders
      }

      let message = "코인 체결리스트 지정가 취소 클릭 "+ data.symCd;
      this.sendCoinOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        let coinSymbolData;

        this.coinSymbolList.forEach(coinSymbol=>{
          if (coinSymbol.name == data.symCd){
            coinSymbolData = coinSymbol;
          }
        })

        const orderData = {apiCode:"COMS0005", order: order, symbol:coinSymbolData ,qty:totOrdQty, price: data.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendCoinOmsMessage('COMS0005', order);
      }
    },
    modifyStock(data){
      if ( data.symCd === undefined
        || data.execPrc === undefined ) {
        return;
      }

      console.log("정정?", data);

      if (data.ordId == undefined || data.ordId == 0){
        window.$alert.alert('원주문 정보가 없습니다.')
        return;
      }

      
  
      let orgOrders = new Array();
      let totCnt = 0;
      let totOrdQty = 0;
      let modifyPrc = data.execPrc; //정정가격 수정필요

      //테스트
      modifyPrc = modifyPrc - 10000;
      console.log("modifyPrc ", data, modifyPrc)

      totCnt++;
      totOrdQty += data.ordQty;

      const orgOrder = {
        accId : this.coinAccount.accId,
        symCd : this.coinSymbol.name,
        symNo : this.coinSymbol.symbolno,
        trdDt : data.trdDt,
        ordId : 0,
        orgOrdNo: data.ordId,
        ordSdCd : data.ordSdCd,
        ordTpCd : data.ordTpCd,
        ordPrc : modifyPrc,
        ordQty : data.ordQty,
        repReqQty : data.ordQty,
        cxlReqQty : 0,
        orgUsrKey : data.usrKey,
        lv : data.lv,
      }

      orgOrders.push(orgOrder);
        
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.coinAccount.accId,
          symCd : data.symCd,
          symNo : data.symNo,
          //accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : data.ordSdCd
      }

      let coinSymbolData;

      this.coinSymbolList.forEach(symbol=>{
        if (symbol.name == data.symCd){
          coinSymbolData = symbol;
        }
      })

      let message = "코인 체결리스트 미체결 정정 클릭 "+ data.symCd;
      //this.sendCoinOmsLogMessage(message);
  
      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"COMS0003", order: order, symbol:coinSymbolData ,qty:totOrdQty, price: data.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendCoinOmsMessage('COMS0003', order)

      }
    },
    sendCoinOmsMessage(apiCode, body) {
      console.log("sendCoinOmsMessage", apiCode, body)
      //팝업주문창여부 확인
      if (window.name == undefined || window.name == null || window.name == ""){
        if ( !window.$store.getters['SocketCoinOms/getIsConnectCoinOms'] ) {
          window.$alert.alert('코인 주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }
        //비밀번호 추가
        // body.accPass = this.getUsrAccPass; 
        window.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', {apiCode:apiCode, body:body})
      }else{
        if ( !window.opener.$store.getters['SocketCoinOms/getIsConnectCoinOms'] ) {
          window.$alert.alert('코인 주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }

        window.opener.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', {apiCode:apiCode, body:body})
      }
    },
    sendCoinOmsLogMessage(message){
      if (window.name == "orderPop1" || window.name == "orderPop2"){
        message = "코인 팝업("+window.name + ") " + message;
        window.opener.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
      }else{
        if (window.location.pathname == "/mobile"){
          message = "코인 모바일 " + message;
        }
        window.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
      }
    }
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>