<template>
  <div>
    <Header 
      :account="account"
      :coinAccount="coinAccount"
    />
    <div class="coin-grid">
      <div class="left-area">
        <div class="chart-area">
          <div class="instrument-row coin">
            <button v-if="futUseYn == 'Y'" class="btn-line" value="korea" :class="{ active: showInstruments === 'korea' }" @click="checkInstruments">국내선물</button>
            <button v-if="futUseYn == 'Y'" class="btn-line" value="global" :class="{ active: showInstruments === 'global' }" @click="checkInstruments">해외선물</button>
            <button v-if="coinUseYn == 'Y'" class="btn-line" value="coin" :class="{ active: showInstruments === 'coin' }" @click="checkInstruments">코인선물</button>
            <input type="text" v-model="coinSymbol.name" class="symbol" readonly />
            <input type="text" :value="coinSymbol.description != undefined ? coinSymbol.description:''" class="symbol-name" readonly />
            <!-- <button value="coin" class="btn-primary">팝업주문</button> -->
          </div>

          <div class="instrument-info-panel">
            <div class="secondary-info-container">

              <!-- <div class="base-dropdown">
                <div class="dropdown instrument" @click="showPrepList">
                  <div class="selected">{{ coinSymbol.name }}</div>
                  <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
                </div>
								<div class="dropdown-list instrument-list" :class="{show:showCoinSymbolPrep}">
                  <table class="instrument-table">
                    <thead>
                      <tr>
                        <th class="instrument">종목</th>
                        <th class="price">가격</th>
                        <th class="rate">변동률</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in coinSymbolPrepList" :key="item.symCd" @click="selectCoinSymbol(item.coinSymbol)">
                        <td class="instrument">{{item.base_name}}</td>
                        <td class="price">{{item.lstPrc}}</td>
                        <td class="rate">{{item.chgRate}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
              <SymbolDropDown id="coinSymbolSelect" :options="coinSymbolPrepList" @coinSymbolSelect="selectCoinSymbol" />

              <div class="instrument-price">
                <div class="price" :class="{ 'color-blue' : coinNowPrice.buyerMaker === true && coinNowPrice.curPr > 0, 'color-red' : coinNowPrice.buyerMaker === false && coinNowPrice.curPr > 0, '' : coinNowPrice.curPr == 0 }">{{ coinNowPrice.curPr.toFixed(this.tikDotSz) }}</div>
                <div class="diff" :class="{ 'color-red' : coinNowPrice.chPrRate > 0, 'color-blue' : coinNowPrice.chPrRate < 0, '' : coinNowPrice.chPrRate == 0 }">
                  <span>{{ coinNowPrice.chPrRate }}</span>
                  <span> {{ coinNowPrice.chGrate }}%</span>
                </div>
              </div>
              <div class="secondary-info">
                <div class="label">시장평균가</div>
                <div class="info">{{ coinNowPrice.markPr }}</div>
              </div>
              <div class="secondary-info">
                <div class="label">지수가격</div>
                <div class="info">{{ coinNowPrice.indexPr }}</div>
              </div>
              <div class="secondary-info">
                <div class="label">펀딩비/남은시간</div>
                <div class="info">
                  <span>{{ coinNowPrice.funding }}</span>
                  /{{ coinNowPrice.CountDown }}
                </div>
              </div>
              <div class="secondary-info">
                <div class="label">고가(24H)</div>
                <div class="info">{{ coinNowPrice.highPr }}</div>
              </div>
              <div class="secondary-info">
                <div class="label">저가(24H)</div>
                <div class="info">{{ coinNowPrice.lowPr }}</div>
              </div>
              <div class="secondary-info">
                <div class="label">24시간 총량(BTC)</div>
                <div class="info">{{ coinNowPrice.btcVol }}</div>
              </div>
              <div class="secondary-info">
                <div class="label">24시간 총량(USDT)</div>
                <div class="info">{{ coinNowPrice.usdtVol }}</div>
              </div>
              <div class="secondary-info">
                <div class="label">미결제 총량</div>
                <div class="info">{{ coinNowPrice.usdtOpenInterest }}</div>
              </div>
            </div>
          </div>

          <div class="chart-container">
            <CoinChartBox :symbol="coinSymbol" :symbolList="coinSymbolList" :height="height" />
          </div>
        </div>
        <div class="orderbook-area">
          <Orderbook @updateCoinPrice="updateCoinPrice" />
          <BuySellScaleBar />
          <FillPanel />
        </div>
        <div class="table-area">
          <div class="table-header-ui">
            <div class="order-type-tab-container no-border">
              <button class="order-type-tab" :class="{ active: coinPositionType === 'POS' }" @click="coinPositionType = 'POS'">포지션 ({{ rtPosCnt }})</button>
              <button class="order-type-tab" :class="{ active: coinPositionType === 'ORD' }" @click="coinPositionType = 'ORD'">미체결 ({{ rtPosOrdCnt }})</button>
            </div>
            <button class="btn-line" v-if="coinPositionType === 'POS'" @click="clearOrderAll('all')">전체청산</button>
            <button class="btn-line" v-if="coinPositionType === 'ORD'" @click="cancelAll('all')">전체취소</button>
          </div>
          <div class="ag-container">
            <AgTransactionTable :coinAccount="coinAccount" v-if="coinPositionType === 'POS'" />
            <AgTransactionTableUnfilled :coinAccount="coinAccount" v-if="coinPositionType === 'ORD'"/>
          </div>
        </div>
      </div>
      <div class="control-area">
        <ControlPanel :coinAccount="coinAccount" :coinOrdStdPrc="coinOrdStdPrc" />
      </div>
    </div>
    <ModalConfirmOrder @event:orderConfirm="orderConfirm" />
    <ModalLogout />

    <ModalMarginSelect @event:MarginMode="selectCoinUsrPos" />
    <ModalLeverageSelect @event:coinLeverage="selectCoinUsrPos" />
    <ModalTradeMode @event:cpos="selectCoinUsrPos" />
    <ModalTPSP />
    <ModalTPSPAll />
    <ModalEditOrder @modifyOrd="modifyOrd" />
    <ModalLimitClose />
    <ModalSettings />
    <ModalDepositWithdraw />
  </div>
</template>
<script>
import Header from '@/components/coin/Header.vue';
import Orderbook from '@/components/coin/Orderbook.vue';
import FillPanel from '@/components/coin/FillPanel.vue';
import ControlPanel from '@/components/coin/ControlPanel.vue';
import AgTransactionTable from '@/components/coin/AgTransactionTable.vue';
import BuySellScaleBar from '@/components/coin/BuySellScaleBar.vue';
import CoinChartBox from '@/components/CoinChartBox';
import EventBus from '@/modules/event-bus';
import ModalConfirmOrder from '@/components/ModalConfirmOrder.vue';
import Utils from '@/modules/utils';
import SymbolDropDown from '@/components/coin/SymbolDropDwon.vue';
import ModalLogout from '@/components/ModalLogout.vue';
import AgTransactionTableUnfilled from '@/components/coin/AgTransactionTableUnfilled.vue';
import ModalMarginSelect from '@/components/coin/ModalMarginSelect.vue';
import ModalLeverageSelect from '@/components/coin/ModalLeverageSelect.vue';
import ModalTradeMode from '@/components/coin/ModalTradeMode.vue';
import ModalTPSP from '@/components/coin/ModalTPSP.vue';
import ModalTPSPAll from '@/components/coin/ModalTPSPAll.vue';
import ModalEditOrder from '@/components/coin/ModalEditOrder.vue';
import ModalLimitClose from '@/components/coin/ModalLimitClose.vue';
import ModalSettings from '@/components/coin/ModalSettings.vue';
import ModalDepositWithdraw from '@/components/coin/ModalDepositWithdraw.vue';
import Const from '@/const';

export default {
  name: 'WhiteLabelDesktopPage',
  components: {
    Header,
    Orderbook,
    FillPanel,
    ControlPanel,
    AgTransactionTable,
    BuySellScaleBar,
    CoinChartBox,
    ModalConfirmOrder,
    SymbolDropDown,
    ModalLogout,
    AgTransactionTableUnfilled,
    ModalMarginSelect,
    ModalLeverageSelect,
    ModalTradeMode,
    ModalTPSP,
    ModalTPSPAll,
    ModalEditOrder,
    ModalLimitClose,
    ModalSettings,
    ModalDepositWithdraw,
  },
  computed: {
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin'];
    },
    //소켓 코인 시세
    changeCocData: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 호가
    changeCohData: function(){
      let cohData = window.$store.getters['SocketCoinPrice/getCohData']
      return cohData
    },
    //소켓 코인 센터가
    changeCotData: function(){
      let cotData = window.$store.getters['SocketCoinPrice/getCotData']
      return cotData
    },
    //소켓 코인 청산가
    changeComData: function(){
      let comData = window.$store.getters['SocketCoinPrice/getComData']
      return comData
    },
    //소켓 코인 시/고/저 지수등
    changeChlData: function(){
      let chlData = window.$store.getters['SocketCoinPrice/getChlData']
      return chlData
    },
    //폴링시세
    changeCocDataList: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    //폴링호가
    changeCohDataList: function(){
      let cohData = window.$store.getters['SocketCoinPrice/getCohDataList']
      return cohData
    },
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    tikDotSz(){
      return Utils.tikDotSz(this.coinSymbol);
    },
    resultCoinOms: function(){
      return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    },
    getAbleQty: function(){
      return window.$store.getters['SocketCoinOms/getAbleQty'];
    },
    resultMsg: function(){
      return window.$store.getters['SocketMsg/getMessage'];
    },
    getCoinDailyPlMn: function(){
      return window.$store.getters['SocketCoinOms/getCoinDailyPlMn'];
    },
    getPosOrdStList: function(){
      return window.$store.getters['SocketCoinOms/getPosOrdStList'];
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
    getCoinPosOrdStList: function(){
      return window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
    },
    getUsrPos: function(){
      return window.$store.getters['SocketCoinOms/getUsrPos'];
    },
  },
  watch: {
    //소켓시세
    changeCocData(newPrice){
      //console.log("changeOvcData >>>> ", newPrice)
      if (newPrice.symbol == this.coinSymbol.name){
        this.coc = newPrice;
        this.cot = newPrice

        this.coinNowPrice.curPr = newPrice.curPr;
        this.coinNowPrice.buyerMaker = newPrice.buyerMaker;
      }
      //
    },
    //소켓호가
    changeCohData(newPrice){
      // apiType
      // asks
      // bids
      // hotTime
      // symbol
      if (newPrice.symbol == this.coinSymbol.name){
        this.updateCoh(newPrice);
      }
    },
    //소켓 코인 센터가
    changeCotData(newPrice){
      if (newPrice.symbol == this.coinSymbol.name){

        // apiType: "COT"
        // buyerMaker: false
        // curPr: 61094.7
        // korDate: "20241011"
        // korTm: "191003"
        // qty: 0.017
        // symbol: "BTCUSDT"
        // trdTm: 1728641403276

        
        //this.cot = newPrice
      }
    },
    //소켓 코인 청산가
    changeComData(newPrice){
      // apiType
      // korDate
      // korTm
      // markPr
      // rate
      // symbol
      // trdTm
      if (newPrice.symbol == this.coinSymbol.name){
        this.com = newPrice;
        this.coinNowPrice.markPr = (Math.floor(newPrice.markPr * 100) / 100).toFixed(this.tikDotSz);
      }
    },
    //소켓 코인 시/고/저 지수등
    changeChlData(newPrice){
      if (newPrice.symbol == this.coinSymbol.name){
// chPr
// chPrate
// close
// closeQty
// high
// korDate
// korTm
// low
// open
// symbol
// totQ
// totV
// trdTm
// whAvgPr
        this.chl = newPrice;
        this.coinNowPrice.chPrRate = newPrice.chPr.toFixed(this.tikDotSz);
        this.coinNowPrice.chGrate = newPrice.chPrate;       
        this.coinNowPrice.funding = 0;
        this.coinNowPrice.CountDown = 0;
        this.coinNowPrice.highPr = newPrice.high.toFixed(this.tikDotSz);
        this.coinNowPrice.lowPr = newPrice.low.toFixed(this.tikDotSz);
        this.coinNowPrice.indexPr = 0;
        this.coinNowPrice.usdtVol = Utils.numberComma(newPrice.totQ);
        this.coinNowPrice.btcVol = Utils.numberComma(newPrice.totV);
        this.coinNowPrice.trdTm = newPrice.trdTm;
        this.coinNowPrice.usdtOpenInterest = 0;

        // this.coinNowPrice.chPr = newPrice.chPr.toFixed(this.tikDotSz);
        // this.coinNowPrice.chPrate = newPrice.chPrate;
        // this.coinNowPrice.close = newPrice.close;
        // this.coinNowPrice.closeQty = newPrice.closeQty;
        // this.coinNowPrice.high = newPrice.high;
        // this.coinNowPrice.low = newPrice.low;
        // this.coinNowPrice.open = newPrice.open;
        // this.coinNowPrice.totQ = newPrice.totQ;
        // this.coinNowPrice.totV = newPrice.totV;
        // this.coinNowPrice.trdTm = newPrice.trdTm;
        // this.coinNowPrice.whAvgPr = newPrice.whAvgPr;
        //this.coinNowPrice.markPr = Math.floor(newPrice.markPr * 100) / 100;
      }
    },
    //폴링시세
    changeCocDataList(newPrice){
      //
    },
    //폴링호가
    changeCohDataList(newPrice){
      //
    },
    getSelectCoinSymbol(newVal){
      if (newVal == undefined) return;
      // this.rStock = Object.assign({}, null);

      //종목이 변경될경우 소켓데이터 연결 갱신
      this.coinSymbol = Object.assign({}, newVal);

      this.coinNowPrice = {symCd: newVal.name, symNo:newVal.symbolno, chGrate:0, chPrRate:0, curPr:0, markPr:0, indexPr:0, funding:0, CountDown:0, highPr:0, lowPr:0, btcVol:0, usdtVol:0, usdtOpenInterest:0, buyerMaker:false};

      this.coinTikSize = newVal.minmov / newVal.pricescale;

      this.coinOrdStdPrc = 0; //일반주문 가격

      this.bidsList = [];
      this.asksList = [];
      this.coc.curPr = 0;
      this.coc.qty = 0;
      this.cot.curPr = 0;
      this.com.markPr = 0;

      this.setStockInfo(newVal);

      // //this.symbolDropdown(newVal);

      this.updateSubscribeOnCoinPrice();

      // this.selectSymbolTikPrice();

      // this.selectOrder(this.symbol.name);
      
      //accId가 있으면
      if (this.coinAccount.accId && this.coinAccount.accId > 0){
        this.searchCoinUsrData();
        this.selectCoinUsrPos();
      }else{
        //accId가 로딩중이면 기다렸다 실행
        window.$store.watch(() => this.coinAccount.accId, isAccount => {
          if (isAccount) {
            this.searchCoinUsrData();
            this.selectCoinUsrPos();
          }
        })
      }
    },
    resultCoinOms(res){ 
      //팝업 전송
      this.sendPopupData('COMS', res);

      if (res.result == "S"){
        this.completeCoinOrder(res);
      }else{
        if (res.apiCode == "COMS9990"){
          if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined){
            window.$http.post('/auth/login/refresh', {params : {}});
          }
          return;
        }else{
          this.procFailMessage(res);

          //실패시 리스트목록 주문 목록 재조회
          if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
            && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017"){
            this.searchCoinUsrData();
          }
        }
      }
    },
    resultMsg:{
      handler(res){
        console.log("resultMsg >>>> ", res)
        if (res.apiCode == Const.MSG_API_TYPE.BANK){
          //유저 데이터 재조회
          window.$store.dispatch('SocketCoinOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
        } else if ( res.apiCode == Const.MSG_API_TYPE.LOGOUT ) {

          window.$store.dispatch('Login/logout');

          this.disconnect();

          this.sendPopupData('CLOSE'); //팝업창 닫기

          this.$alert.alert('다른 브라우저에서 로그인하여 <span style="color:red;">로그아웃</span> 되었습니다.<br/><br/>로그인을 다시 시도하시거나 사용을 종료 해 주십시오.', '안내')
          .then(() => {
            this.$router.push('/login');
          });

          return;
        }else if (res.apiCode == Const.MSG_API_TYPE.LOGINKILL){
          //LOGINKILL("MSG0103"),		// 관리자 로그아웃처리
          window.$store.dispatch('Login/logout');

          this.disconnect();

          this.sendPopupData('CLOSE'); //팝업창 닫기

          this.$alert.alert('접속할 수 없습니다.<br/>관리자에게 문의하시기 바랍니다.', '안내')
          .then(() => {
            this.$router.push('/login');
          });
          return;
        }else if ( res.apiCode == Const.MSG_API_TYPE.NOTLOGIN){ //로그인 정보가 없으면 브라우저 로그인 되어있는지 확인 후 로그아웃처리
          //console.log("this.getLoginUserInfo ", this.getLoginUserInfo)
          if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined){
              window.$http.post('/auth/login/refresh', {params : {}});
              //console.log("login/refresh ");
          }
          return;
        }else if(res.apiCode == Const.MSG_API_TYPE.LVCHANGE){
          //코인은 레버리지 조정이 없음
          return;
        }

        // 알림 재조회
        //EventBus.$emit('Alarm:event:refresh', {});

        //공지 팝업 호출
        if (res.body.post != undefined && res.body.post.popupFlg != undefined && res.body.post.popupFlg == "Y"){
          this.$modal.show("modalBasic", res.body);
           return;
        }else{
          this.$notify({
            group : 'notification',
            //text : '<span style="color: var(--main-red)">알림</span> '+ res.body.msg
            text :  res.body.msg
          });
          return;
        }

      },
      deep:true
    },
    getAbleQty:{
      handler(res){
        //
      },
      deep:true
    },
    getCoinDailyPlMn:{
      handler(res){
        console.log("getCoinDailyPlMn ", res)
        // this.dailyPlMn.fxPlMn = res.fxPlMn; // 해외손익금액
        // this.dailyPlMn.fxCms = res.fxCms; // 해외수수료
        // this.dailyPlMn.nFxPlMn = res.nFxPlMn; // 해외순손익
        // this.dailyPlMn.futPlMn = res.futPlMn; // 국내손익금액
        // this.dailyPlMn.futCms = res.futCms; // 국내수수료
        // this.dailyPlMn.nFutPlMn = res.nFutPlMn; // 국내순손익
        // this.dailyPlMn.totPlMn = res.totPlMn; // 총손익금액
        // this.dailyPlMn.totNPlMn = res.totNPlMn; // 총순손익
        // this.dailyPlMn.totCms = res.totCms; // 총수수료
        // this.dailyPlMn.niPlMn = res.niPlMn;
        // this.dailyPlMn.balMn = res.balMn;
        // this.dailyPlMn.hkdExR = res.hkdExR;
        // this.dailyPlMn.usdExR = res.usdExR;
        // this.dailyPlMn.usdExHkd = res.usdExHkd;

        // this.sendPopupData('DAILY', res); //팝업 전송
      },        
      deep:true
    },
    getPosOrdStList(newList){
      // this.sendPopupData('POS_ORD', newList); //팝업 잔고리스트 전송
      // if (newList.length > 0){
      //   let balQty = 0;
      //   let execPrc = 0;
      //   let ordSdCd = "0";
      //   let plMn = 0;
      //   let valPl = 0;
      //   let plData = {};
      //   newList.forEach(data=>{
      //     if (data.ordType == "POS" && data.symCd == this.symbol.name){
      //       balQty = data.balQty;
      //       ordSdCd = data.ordSdCd;
      //       if (balQty > 0) execPrc = data.execPrc;
      //       plMn = data.plMn;
      //       valPl = data.valPl;
      //       plData = data;
      //     }
      //   })

      //   this.symbolPlMn.balQty = balQty;
      //   this.symbolPlMn.execPrc = execPrc;
      //   this.symbolPlMn.plMn = plMn;
      //   this.symbolPlMn.data = plData;

      //   this.avgPrice = Object.assign({}, {avgPrice:execPrc, ordSdCd:ordSdCd, balQty:balQty})
        

      //   this.setSymbolPrice();
        
      //   //symbolPlMn:{balQty:0, execPrc:0, fxPlMn:0, valPl:0},
      // }
    },
    getLoginUserInfo:{
      handler(userInfo){
        // this.loginUserInfo = userInfo;
      }
    },
    getCoinPosOrdStList(newList){
      console.log("getCoinPosOrdStList", newList)
      //보유포지션 목록 시세 갱신요청
      this.rtPosCoinSymbolList = [];
      let rtPosCnt = 0;
      let rtPosOrdCnt = 0;
      for (const data of newList){
        if (data.ordType == "POS"){
          this.rtPosCoinSymbolList.push(data);
          if (data.balQty > 0){
            rtPosCnt++;
          }
        }else if (data.ordType == "ORD"){
          rtPosOrdCnt++;
        }

      }

      this.rtPosCnt = rtPosCnt;
      this.rtPosOrdCnt = rtPosOrdCnt;
      
      this.updateSubscribeOnCoinPrice();
    },
    getUsrPos(usrPos){
      console.log("getUsrPos ", usrPos);
    }
  },
  data() {
    return {
      coc:{
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      cot:{
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      com:{
        markPr: 0,
      },
      chl:{
        chPr:0,
        chPrate:0,
      },
      bidsList:[],
      asksList:[],
      showPanel: true,
      showInstruments: 'coin',
      setIntervalId: null,
      selectedTheme: 'black',
      accId: null,
      account: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      coinAccount: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      accountList:[],
      chgSymbol: null,
      coinPriceReqType: "SCKTP02", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
      width: 1053,
      height: 478,
      currencyInfo:{currency:"USDT"},
      layout : {},
      noticePop: null,
      //팝업 창 관련
      dataChannel:null,
      popupId1:null,
      dataChannel1:null,
      ovcChannel1:null,
      popupId2:null,
      dataChannel2:null,
      ovcChannel2:null,
      //팝업 창 관련
      //코인주문 관련
      coinSymbolList: [],
      coinSymbolPrepList: [],
      selCoinSymbolPrep:{name:null, base_name:null},
      coinOrdStdType:"1",
      coinOrdStdQty:0,
      coinNowPrice: {symCd: null, symNo:0, chGrate:0, chPrRate:0, curPr:0, markPr:0, indexPr:0, funding:0, CountDown:0, highPr:0, lowPr:0, btcVol:0, usdtVol:0, usdtOpenInterest:0, buyerMaker:false},
      coinSymbol:{name:null, base_name:null, symbolno:0},
      coinOrdStdPrc:0,
      btnStdCoinFlag:true,
      btnClearAllFlag:true,
      btnCancelAllFlag:true,
      rtPosCoinSymbolList:[],
      coinLeverage: 1,
      coinSellAbleQty:0,
      coinBuyAbleQty:0,
      cpos:"1",
      marginMode:"1",
      coinTikSize:0,
      rtPosCnt: 0,
      rtPosOrdCnt: 0,
      coinPositionType: "POS",
      //코인주문 관련
      futUseYn: "N",
      coinUseYn: "N",
    }
  },
  created(){
    this.domainCheck();

    this.loadLayout();

    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.setLayout(layout)
    })

    this.selectCoinSymbolList();

    //message 소켓 서버 연결
    this.connectMsg();
    
    //팝업 전송용 채널
    this.dataChannel = new BroadcastChannel('dataChannel');
  },
  mounted() {
  },
  methods: {
    //창이 닫힐때 호출
    handleBeforeUnload(event) {
      this.sendPopupData('CLOSE');
    },
    async selectCoinSymbolList(){
      
      console.log("this.coinSymbolList ", this.coinSymbolList, window.$store.getters['Symbol/getCoinSymbolList'].length);
      await window.$store.dispatch('Symbol/coinLoad'); //코인 종목 조회
      
      this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];
      
      

      console.log("coinSymbolList", this.coinSymbolList[0].name)

      if (this.coinSymbolList != undefined && this.coinSymbolList.length > 0){
        console.log("this.coinSymbolList ", this.coinSymbolList);

        let prepList = [];

        //종목선택 div 목록 생성
        for (let item of this.coinSymbolList){
          const coinSymbol = {base_name:item.base_name[0], symCd:item.name, symbolno:item.symbolno, description: item.description, full_name:item.full_name, lstPrc:0, chgRate:0, fundRate:0, coinSymbol:item};
          prepList.push(coinSymbol);
        }

        //종목 선택 목록
        this.coinSymbolPrepList = prepList;

        //첫번째 종목 선택       
        //this.coinSymbol = this.coinSymbolList[0];
        if (!window.$store.getters['SocketCoinPrice/getIsConnectCoinPrice']) {
          window.$store.watch(() => window.$store.getters['SocketCoinPrice/getIsConnectCoinPrice'], socketPrice => {
            setTimeout(() => {
              window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', this.coinSymbolList[0]);
            }, 500);
          })
        }else{
          setTimeout(() => {
            window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', this.coinSymbolList[0]);
          }, 500);
        }
      }      
    },
    async domainCheck(){
      const host = window.location.host;
      const formData = {hostUrl: host};

      await this.$lhttp.post('/auth/domainChk', formData).then((response) => {
        //console.log("response... ", response);
        if (response.code == 200){
          if (response.data != undefined && response.data.cpnId > 0){
            //접속방식 설정
            window.$store.dispatch('SocketCoinPrice/setPollingType', {sckTpCd:response.data.sckTpCd, sckTpDsc:response.data.sckTpDsc});
            this.coinPriceReqType = window.$store.getters['SocketCoinPrice/getCoinPriceReqType'];

            //코인 시세 설정
            window.$store.dispatch('SocketCoinPrice/setPollingType', {sckTpCd:response.data.sckTpCd, sckTpDsc:response.data.sckTpDsc});

            //커스텀 화면 설정
            let cpnAuthList = window.$store.getters['Layout/getCpnAuthList']
            if (cpnAuthList == undefined || cpnAuthList == null || cpnAuthList.length){
              window.$store.dispatch('Layout/setCpnAuth', {data:response.data.cpnAuthList})
              cpnAuthList = response.data.cpnAuthList;
              this.setCpnAuth(cpnAuthList);
            }

            //선물, 코인 사용여부
            if (response.data.futUseYn != undefined){
              this.futUseYn = response.data.futUseYn;
            }
            if (response.data.coinUseYn != undefined){
              this.coinUseYn = response.data.coinUseYn;
            }

            if (this.coinUseYn == "Y"){
              this.showInstruments = "coin";
            }else{
              //코인 사용조건이 없을경우
              this.$alert.alert('코인선물 사용권한이 없습니다.', '알림').then(() => {
                if (this.futUseYn == "N"){ //해외 선물권한도 없을경우
                  this.$router.push('/login');
                }else{
                  this.$router.push('/deskTop');
                }                
              });
            }
          }else{
            this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
            return;
          }
        }else{
          this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
          return;
        }
      })
      
      this.selectAccountList();

      //소켓연결시
      //console.log("시세 연결 방식 >>> ", this.priceReqType);
      if (this.coinPriceReqType == "SCKTP01"){
        this.connectCoinPrice();
      }

      //코인 주문 소켓 서버 연결 (우선 로컬만 테스트하도록)
      if (this.coinUseYn == "Y" && window.location.hostname == "localhost"){
        this.connectCoinOms();
      }
    },
    // 코인 시세 socket 연결
    connectCoinPrice(symbolList){
      if (this.coinPriceReqType == "SCKTP01"){
        window.$store.dispatch('SocketCoinPrice/setConectCoinPrice', {sessionStorage:this.$sessionStorage.get('xDkToken')});
      }else if (this.coinPriceReqType == "SCKTP02" || this.coinPriceReqType == "SCKTP03"){
        window.$store.dispatch('SocketCoinPrice/setPollingCoinPrice', {symCd:this.symbolList[0].name, symbolList:symbolList});
      }
    },
    async selectAccountList(){
      await this.$http
      .get('/api/account/list')
      .then((result) => {
        if ( result && result.data !== null ) {
          //console.log("account result ", result)
          
          this.accountList = result.data;

          //첫번째 계좌는 선물계좌로 사용(순번 desc 순서)
          if (result.data.length > 0){
            for (let item of result.data){
              //console.log("account item ", item)
              if (item.accDivCd == 'ACCDIV04' && this.account.accId == 0){
                this.account = item;
                this.$sessionStorage.set('account', JSON.stringify(item));
              }

              if (item.accDivCd == 'ACCDIV05' && this.coinAccount.accId == 0){
                this.coinAccount = Object.assign({}, item);
                this.$sessionStorage.set('coinAccount', JSON.stringify(item));
              }
            }
          }
        }
      });
    },
    checkInstruments(e) {
      if(e.target.value === this.showInstruments) {
        //this.showInstruments = '';
      } else {
        if (e.target.value == "korea"){
          //국내선물
          window.$loading.show();
          window.$store.dispatch('SocketCoinPrice/resetCoinSymbol');
          this.sendCoinOmsLogMessage("국내선물 클릭");
          setTimeout(()=>{
            this.$router.push({name: 'Desktop Page', params: { instruments: 'korea' }});
          }, 1000);
          
        }else if (e.target.value == "global"){
          window.$loading.show();
          window.$store.dispatch('SocketCoinPrice/resetCoinSymbol');
          this.sendCoinOmsLogMessage("해외선물 클릭");
          setTimeout(()=>{
            this.$router.push({name: 'Desktop Page', params: { instruments: 'global' }});
          }, 1000);
          
        }else if (e.target.value == "coin"){
          this.sendCoinOmsLogMessage("코인선물 클릭");
          this.$router.push('/coinDesktop');
        }        
      }
    },
    loadLayout() {
      window.$store
        .dispatch('Layout/load')
        .then(() => {
          this.layout = window.$store.getters['Layout/getLayout']

          EventBus.$emit('Layout:event:loadLayout', this.layout)
        })         
    },
    setLayout(layout) {
      //this.orderSetting = layout.orderSetting;
      this.loadCustom();
    },
    loadCustom() {
      this.orderSetting = window.$store.getters['Layout/getOrderSetting']

      //환경설정 추가 필요
    },
    setCpnAuth(cpnAuthList){
      //커스텀 화면 설정
      //console.log("cpnAuthList", cpnAuthList)

      if (cpnAuthList != undefined && cpnAuthList.length > 0){ //커스텀 권한 설정
        let stdordYn = "N";
        cpnAuthList.forEach(item=>{
          // 코인 주문 관련 추가 필요
        });
      }

    },
    // coin oms socket 연결
    async connectCoinOms(){
      await window.$store.dispatch('SocketCoinOms/setConectCoinOms', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    },
    async connectMsg() {
      await window.$store.dispatch('SocketMsg/setConectMsg', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    },
    //종목 리스트 활성/비활성
    // showPrepList(){
    //   this.showCoinSymbolPrep = !this.showCoinSymbolPrep;
    //   console.log("showCoinSymbolPrep ", this.showCoinSymbolPrep)
    // },
    selectCoinSymbol(newVal){
      console.log("selectCoinSymbol", newVal)
      window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', newVal.coinSymbol);
      //this.showCoinSymbolPrep = !this.showCoinSymbolPrep;
    },
    async setStockInfo(newSymbol) {

      //const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {  } } )
      //console.log("rStock search >>> ", newSymbol)
      const rStock = await window.$http.get('apisise/getCoinLastPrice', { params: { symCd: newSymbol.name} } )

      if ( rStock != null ) {
        //console.log("rStock >>>>> ", rStock)
        if (rStock.data.coc != null){
          this.coc = rStock.data.coc
        }
        if (rStock.data.cot != null){
          this.cot = rStock.data.coc
        }
        if (rStock.data.com != null){
          this.com = rStock.data.com
        }

        if (rStock.data.coh != null){
          this.updateCoh(rStock.data.coh);
        }
      }
    },
    updateCoh(newPrice){
      let asksList = [];
      let askSum = 0;
      let revsAskList = [];

      //ask
      if (newPrice.asks != undefined){
        //sum값 계산, 배열 순번이 뒤로 갈수록 합산
        newPrice.asks.forEach(item=>{
          askSum += Number(item[1]);
          item[2] = askSum.toFixed(3);
        })

        //리스트 리버스
        for (let i=newPrice.asks.length-1; i>=0;i--){
          let item = newPrice.asks[i];
          asksList.push({price:item[0], size:item[1], sum:item[2]});
        }

        this.asksList = asksList;
      }

      //bid
      if (newPrice.bids != undefined){
        let bidsList = [];
        let bidSum = 0;
        newPrice.bids.forEach(item=>{
          bidSum += Number(item[1]);
          bidsList.push({price:item[0], size:item[1], sum:bidSum.toFixed(3)});
        });

        this.bidsList = bidsList;
      }
    },
    updateSubscribeOnCoinPrice(){
      //소켓 구독 종목 업데이트
      this.coinSymbolCdList = [];

      //잔고목록
      this.rtPosCoinSymbolList.forEach(data =>{
        if (data.balQty > 0){
          let chkYn = "N";
          for (let item of this.coinSymbolCdList){
            
            if (item == data.symCd){
              chkYn = "Y"
            }
          }

          if (chkYn == "N")
          this.coinSymbolCdList.push(data.symCd);
          
        }
      });

      //선택 종목
      const selCoinSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];

      console.log("selCoinSymbol", selCoinSymbol)

      //팝업 선택 종목
      let popupSelCoinSymbolList = [];
      //stock_trader_popup1SelSymbol

      if (JSON.parse(this.$sessionStorage.get('popup1SelCoinSymbol')) != null){
        const popupCoinSymbol = JSON.parse(this.$sessionStorage.get('popup1SelCoinSymbol'));
        popupSelCoinSymbolList.push(popupCoinSymbol.name);
      }

      if (this.$sessionStorage.get('popup2SelCoinSymbol') != null){
        const popupCoinSymbol = JSON.parse(this.$sessionStorage.get('popup2SelCoinSymbol'));
        popupSelCoinSymbolList.push(popupCoinSymbol.name);
      }

      //팝업 종목 추가
      popupSelCoinSymbolList.forEach(item =>{
        let chkYn = "N";
        this.rtPosCoinSymbolList.forEach(data =>{
          if (data == item){
            chkYn = "Y";
            return;
          }
        });

        if (chkYn == "N"){
          this.coinSymbolCdList.push(item);
        }
      });

      if (selCoinSymbol != undefined && selCoinSymbol.name){
        let chkYn = "N";
        this.coinSymbolCdList.forEach(item =>{
          if (selCoinSymbol.name == item){
            chkYn = "Y";
            return;
          }
        })
        if (chkYn == "N"){
          this.coinSymbolCdList.push(selCoinSymbol.name);
        }
      }
     
      console.log("소켓 구독 수정", this.coinSymbolCdList)
      
      this.subscribeOnCoinPriceModify(this.coinSymbolCdList, popupSelCoinSymbolList);
    },
    subscribeOnCoinPriceModify(coinSymbolList, popupSelCoinSymbolList) {
      if (this.coinPriceReqType == "SCKTP01"){
        window.$store.dispatch('SocketCoinPrice/setUpdateSubscribeOnPriceList', {coinSymbolList, popupSelCoinSymbolList});
      }else if (this.coinPriceReqType == "SCKTP02" || this.coinPriceReqType == "SCKTP03"){
        window.$store.dispatch('SocketCoinPrice/setPollingPrice', {symCd:this.coinSymbol.name, coinSymbolList:coinSymbolList, popupSelCoinSymbolList:popupSelCoinSymbolList});
      }
    },
    searchOrdAbleQty(){
      //window.$store.dispatch('SocketCoinOms/selectUsrData', {accId:this.coinAccount.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.coinLeverage, ordType:'coin'});
    },
    //코인 유저데이터 조회
    searchCoinUsrData(){
      console.log("searchCoinUsrData ", this.coinAccount.accId, this.coinSymbol.name)
      window.$store.dispatch('SocketCoinOms/selectUsrData', {accId:this.coinAccount.accId, symCd:this.coinSymbol.name, symNo:this.coinSymbol.symbolno, lv:this.coinLeverage, ordType:'coin'});
    },
    //코인 포지션/마진모드/레버리지 조회
    selectCoinUsrPos(){
      window.$store.dispatch('SocketCoinOms/selectUsrPos', {accNo:this.coinAccount.accNo, symCd:this.coinSymbol.name});
    },
    orderConfirm(orderData){
      if (orderData.apiCode && orderData.order){
        this.sendCoinOmsMessage(orderData.apiCode, orderData.order);
      }
    },
    sendCoinOmsMessage(apiCode, body) {
      console.log("sendCoinOmsMessage", apiCode, body)
      //팝업주문창여부 확인
      if (window.name == undefined || window.name == null || window.name == ""){
        if ( !window.$store.getters['SocketCoinOms/getIsConnectCoinOms'] ) {
          window.$alert.alert('코인 주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }
        //비밀번호 추가
        // body.accPass = this.getUsrAccPass; 
        window.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', {apiCode:apiCode, body:body})
      }else{
        if ( !window.opener.$store.getters['SocketCoinOms/getIsConnectCoinOms'] ) {
          window.$alert.alert('코인 주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }

        window.opener.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', {apiCode:apiCode, body:body})
      }
    },
    completeCoinOrder(res){
      if (res != undefined && res.notify != undefined){
        this.notifyAlert(res.notify)
      }
      const apiCode = res.apiCode;
      const body = res.body;

      //코인 일반주문 버튼 활성화
      this.btnStdCoinFlag = true;

      //포지션 조회
      if (this.coinAccount.accId && this.coinAccount.accId > 0){
        this.searchCoinUsrData();
      }
    },
    procFailMessage(res) {
      let body = res.body;

      //기존 알림창 닫기
      this.$notify({ group: 'notification', clean: true })

      this.$notify({
        group: 'notification',
        title: 'FAIL',
        text: body.message
      })
    },
    //팝업창 데이터 채널로 전달
    sendPopupData(type, data){
        this.dataChannel.postMessage({type:type, data:data});
    },
    notifyAlert(msgInfo){ //주문 알림 메시지
      //console.log("msgInfo", msgInfo)
      
      if (msgInfo != null){
        if (this.orderSetting != null && this.orderSetting.soundAlert){
           // 사운드 실행
           if (msgInfo.apiCode == 'OMS0001'){ //주문완료
            if (msgInfo.body.ordSdCd == '1'){
              this.soundOn('/SELL_COMP.WAV')
            }else{
              this.soundOn('/BUY_COMP.WAV')
            }
          }else if (msgInfo.apiCode == 'OMS0002'){  //취소주문
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0003'){  //정정주문  
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0005'){  //MIT취소완료
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0006'){  //MIT정정완료
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0010'){ //종목선택취소 
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0011'){ //MIT종목선택취소
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0012'){ //일괄취소
            this.soundOn('/CANCEL_COMP.WAV')
          }
        }

        if (this.orderSetting != null && this.orderSetting.messageAlert){
        
          
          this.$notify({ group: msgInfo.group, clean: true })

          this.$notify(msgInfo)
        }        
      }
    },
    soundOn(file){
      //if (this.orderSetting != null && this.orderSetting.soundAlert){
        new Audio("/wav"+file).play();
      //}
    },
    delayAlert(){ // 버튼 딜레이 시간까지 활성화 안되면 알림창
      window.$alert.alert(' 네트워크 상태가 좋지 않습니다.<br/>주문 누락 등의 위험이 있으니, 네트워크 상태를 점검해 보시기 바랍니다.', '알림');
    },
    //전체 청산
    clearOrderAll(type){
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined ||this.coinAccount.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnClearAllFlag){
        return;
      }
      
      if (type == 'all' || type == 'symbol'){
        let msg = "현종목";
        let symCd = this.coinSymbol.name;
        let symNo = this.coinSymbol.symbolno;
        if (type == 'all'){
          msg = "전체";
          symCd = "";
        }

        this.btnClearAllFlag = false;

        const order = {
          usrKey: Utils.getUuidv4(),
          accId : this.coinAccount.accId,
          symCd : symCd,
          symNo : symNo,
          trdDt : '',
          clearType : type,
          //accPass : this.getUsrAccPass,
        }

        let message = msg;
        message += " 청산 클릭";
        this.sendCoinOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
          const orderData = {apiCode:"COMS0013", order: order, symbol:this.coinSymbol, qty:0, price: 0, message:message};
          this.$modal.show("modalConfirmOrder", orderData);
        }else{
          this.sendCoinOmsMessage("COMS0013", order);
        }
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnClearAllFlag){
        setTimeout(() => {
          this.btnClearAllFlag = true
        }, 1500)
      }
    },
    cancelAll(type){ //전/현종목 취소
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined ||this.coinAccount.accId == 0){
        window.$alert.alert('계좌가 정보가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelAllFlag){
        return;
      }
      
      if (type == 'all' || type == 'symbol'){
        let msg = "현종목";
        let symCd = this.coinSymbol.name;
        let symNo = this.coinSymbol.symbolno;
        if (type == 'all'){
          msg = "전종목";
          symCd = "";
        }

        this.btnCancelAllFlag = false;

        const order = {
          usrKey: Utils.getUuidv4(),
          accId : this.coinAccount.accId,
          symCd : symCd,
          symNo : symNo,
          trdDt : '',
          clearType : type,
          //accPass : this.getUsrAccPass,
        }

        let message = msg;
        message += " 취소 클릭";
        this.sendCoinOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
          const orderData = {apiCode:"COMS0018", order: order, symbol:this.coinSymbol, qty:0, price: 0, message:message};
          this.$modal.show("modalConfirmOrder", orderData);
        }else{
          this.sendCoinOmsMessage("COMS0018", order);
        }
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelAllFlag){
        setTimeout(() => {
          this.btnCancelAllFlag = true
        }, 1500)
      }
    },
    sendCoinOmsLogMessage(message){ //코인 사용자 로그 저장
      window.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
    },
    disconnect(){
      console.log("coin disconnect....");
      //메시지
      // if (window.$store.getters['SocketMsg/getIsConnectMsg']) {
      //   //clear polling
      //   window.$store.dispatch('SocketMsg/setDisconnect');
      // }

      //코인 주문
      if (window.$store.getters['SocketCoinOms/getIsConnectCoinOms']) {
        //
        window.$store.dispatch('SocketCoinOms/setCoinDisconnect');
      }

      //코인 소켓 시세
      if (window.$store.getters['SocketCoinPrice/getIsConnectCoinPrice']) {
        //setDisconnect
        window.$store.dispatch('SocketCoinPrice/setCoinDisconnect');
      }

      //폴링 코인 시세
      if (window.$store.getters['SocketCoinPrice/getIsPolingCoinPrice']) {
        //clear polling
        window.$store.dispatch('SocketCoinPrice/setClearCoinPolling');
      }
    },
    //포지션 모드 체크 및 저장
    async selectCpos(newVal, oldVal){
      console.log("this.coinSymbol.symbolno", this.coinSymbol)
      this.cpos = oldVal;
      const data = {accNo:this.coinAccount.accNo, symCd:this.coinSymbol.name, ordType:"coin"};

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        console.log("response", response)
        if (response.code == 200){
          if (response.data == null){
            this.$alert.alert('코인선물 잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          }else if (response.data.coinOrdQty != undefined && response.data.coinOrdQty > 0){
            this.$alert.alert('코인선물 잔고/미체결 내역이 있으면 포지션 모드 변경 불가합니다.', '안내');
            this.cpos = response.data.cpos;
            return;
          }
          this.cpos = newVal;

          //포지션 모드 저장
          const saveData = {accNo:this.coinAccount.accNo, symNo:this.coinSymbol.symbolno, cpos:this.cpos};
          console.log("saveData >>> ", saveData)
          this.$lhttp.post('/api/order/usrPos/save', saveData).then((res) =>{
            
          });
          
        }
      });
    },
    async selectMarginMode(newVal, oldVal){
      this.marginMode = oldVal;
      const data = {accNo:this.coinAccount.accNo, symCd:this.coinSymbol.name, ordType:"coin"};

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        console.log("response", response)
        if (response.code == 200){
          if (response.data == null){
            this.$alert.alert('코인선물 잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          }else if (response.data.coinOrdQty != undefined && response.data.coinOrdQty > 0){
            this.$alert.alert('코인선물 잔고/미체결 내역이 있으면 마진 모드 변경 불가합니다.', '안내');
            this.marginMode = response.data.cpos;
            return;
          }
          this.marginMode = newVal;

          //포지션 모드 저장
          const saveData = {accNo:this.coinAccount.accNo, symNo:this.coinSymbol.symbolno, marginMode:this.marginMode};
          console.log("saveData >>> ", saveData)
          this.$lhttp.post('/api/order/usrPos/save', saveData).then((res) =>{
            
          });
          
        }
      });
    },
    async selectUsrPos(){
      const data = {accNo:this.coinAccount.accNo, symCd:this.coinSymbol.name, ordType:"coin"};
      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        console.log("response", response)
        if (response.code == 200){
          if (response.data == null){
            this.$alert.alert('코인선물 포지션/마진모드 조회중 에러가 발생했습니다.', '안내');
            return;
          }

          this.cpos = response.data.cpos;
          this.marginMode = response.data.marginMode;
          this.coinLeverage = response.data.leverage;
        }
      });
    },
    updateCoinPrice(newVal){
      console.log("updateCoinPrice", newVal)
      this.coinOrdStdPrc = Number(newVal.price);
    },
    modifyOrd(data){
      console.log("정정 ", data)
      if ( data.symCd === undefined
        || data.execPrc === undefined ) {
        return;
      }

      if (data.ordId == undefined || data.ordId == 0){
        window.$alert.alert('원주문 정보가 없습니다.')
        return;
      }
  
      let orgOrders = new Array();
      let totCnt = 0;
      let totOrdQty = 0;
      let modifyPrc = data.execPrc;

      totCnt++;
      totOrdQty += data.ordQty;

      const orgOrder = {
        accId : this.coinAccount.accId,
        symCd : this.coinSymbol.name,
        symNo : this.coinSymbol.symbolno,
        trdDt : data.trdDt,
        ordId : 0,
        orgOrdNo: data.ordId,
        ordSdCd : data.ordSdCd,
        ordTpCd : data.ordTpCd,
        ordPrc : modifyPrc,
        ordQty : data.ordQty,
        repReqQty : data.ordQty,
        cxlReqQty : 0,
        orgUsrKey : data.usrKey,
        lv : data.lv,
      }

      orgOrders.push(orgOrder);
        
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.coinAccount.accId,
          symCd : data.symCd,
          symNo : data.symNo,
          //accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : data.ordSdCd
      }

      let coinSymbolData;

      this.coinSymbolList.forEach(symbol=>{
        if (symbol.name == data.symCd){
          coinSymbolData = symbol;
        }
      })

      let message = "코인 주문 ";
      if (data.ordSdCd == "1"){
        message += " 매도";
      }else if (data.ordSdCd == "2"){
        message += " 매수";
      }

      let ordPrc = 0;

      message += " " + data.symCd;

      if (data.ordTpCd == "2"){ //시장가
        message += "/시장가";
      }else if (data.ordTpCd == "1"){ //지정가
        ordPrc = modifyPrc;
        message += "/지정가";
      }

      message += "/수량 "+totOrdQty + "/가격 "+modifyPrc;
      
      this.sendCoinOmsLogMessage(message);
  
      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"COMS0003", order: order, symbol:coinSymbolData ,qty:totOrdQty, price: data.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendCoinOmsMessage('COMS0003', order)

      }
    },
  },
  
  destroyed() {
    this.sendPopupData('CLOSE');
      this.disconnect();       
  },
  
}

</script>