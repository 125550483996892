<template>
  <div class="buysell-scale">
    <div class="buy-label">
      B
      <span>20.74%</span>
    </div>
    <div class="scale-bar">
      <div class="buy-scale" style="width:20.74%"></div>
      <div class="sell-scale" style="width:79.26%"></div>
    </div>
    <div class="sell-label">
      <span>79.26%</span>
      S
    </div>
  </div>
</template>

<script>
export default {
  name: 'buySellScaleBar',
  components: {
  },
  computed: {
  },
  watch: {

  },
  data() {
    return {

    }
  },
  created(){
  },
  mounted() {
  },
  methods:{

  },

}
</script>