<template>
  <div id="app">
    <router-view :reloadPanelComp="reloadPanelComp" :reloadNotice="reloadNotice"/>
    <notifications group="notification" :duration="3000" :width="notificationWidth" position="bottom right">
      <template slot="body" slot-scope="props">
        <div class="notification-container">
          <div class="notification-header">
            <h1>알림</h1>
            <button @click="props.close" class="close-btn"><img src="@/assets/icons/close-black.svg" alt=""></button>
          </div>
          <div class="notification-body" v-html="props.item.text"></div>
          <div class="notification-footer">
            <button class="btn-primary blue" @click="props.close">확인</button>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import EventBus from "@/modules/event-bus"
import Const from '@/const'

export default {
  name: 'App',
  components: {
    //
  },
  data() {
    return {
      socket: null, 
      isConnect: false,        
      loginCheckTimer: null, 
      reloadPanelComp: 1,
      reloadNotice: 1,
      notificationWidth: 300,
    }
  },
  created () {

    // 새로고침 처리
    this.refreshLogin();

    // 로그인 처리
    window.$store.watch(() => window.$store.getters['Login/isLogin'], isLogin => {
      if (isLogin) {
        if ( this.loginCheckTimer === null ) {
          this.loginCheckTimer = setInterval(this.refreshLogin, 1800000);
        }
      } else {
        if ( this.loginCheckTimer !== null ) {
          clearInterval(this.loginCheckTimer);
          this.loginCheckTimer = null;
        }
      }
    })

      //모바일 주소로 접속시 절전모드 설정
      if (window.location.pathname == "/mobile"){
        document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
      }

  },
  mounted () {
    this.isMobile();
    //모바일 구분 도메인으로 구분이 필요할듯.
    const domain = window.location.hostname; // 현재 도메인 가져오기
    let faviconUrl = ''; // 기본 favicon URL

    // 도메인에 따른 favicon 설정
    
    if (domain === 'mng.n-globalinv.com' || domain === 'n-globalinv.com'){
      faviconUrl = '/favicon1.ico'; // public 폴더의 favicon-aaa.ico
    }else { 
      faviconUrl = '/favicon.ico'; // 기본 favicon
    }

    // <link rel="icon"> 태그를 찾아서 href 속성을 변경
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = faviconUrl;
    // <head>에 추가
    document.getElementsByTagName('head')[0].appendChild(link);
  },
  methods: {
    handleVisibilityChange(){
      if (document.hidden) {
        this.pauseStartPage('P');
      } else  {
        this.pauseStartPage('S');
      }
    },
    pauseStartPage(type){
      if (type == "P"){ //절전모드
        console.log("app disConnect ....");
        clearInterval(this.loginCheckTimer);
      }else if(type == "S"){ //절전모드 해제
        console.log("app connect ....");
        this.loginCheckTimer = setInterval(this.refreshLogin, 1800000);
      }
    },
    isMobile() {
      console.log('calculating :' + window.innerWidth);
      if (window.innerWidth <= 1200) {
        return (this.notificationWidth = '100%');
      } else {
        return (this.notificationWidth = 300);
      }
    },
    refreshLogin() {
      const xDkToken = this.$sessionStorage.get('xDkToken');

      if ( xDkToken !== null && xDkToken !== '' ) {
        window.$store.dispatch('Login/refresh');
      }
    },
  },
  destroyed() {
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/styles.scss';
</style>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.notification-container {
  background-color: white;
  border: 1px solid #ddd;
}
.notification-header {
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
  .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
  }
  @media (max-width: $mobile) {
    padding: 5px 20px;
    h1 {
      /* font-size: 14px; */
      display: none;
    }
  }
}
.notification-body {
  padding: 0 20px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  @media (max-width: $mobile) {
    text-align: left;
  }
}
.notification-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  @media (max-width: $mobile) {
    padding: 5px 20px;
    button {
      display: none;
    }
  }
}
</style>
<style>
.color-red{
  color:var(--buy-color);
}
.color-blue{
  color:var(--sell-color);
}
</style>