<template>
  <div>
    <modal name="modalTradeMode" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">거래모드 선택</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
				<div class="modal-trade-mode">
          <div class="trade-mode-container">
            <button class="trade-mode-btn" :class="{ active: cpos === '1' }" @click="chgPosition('1')">
              <img src="@/assets/icons/oneway-mode.svg" alt="">
              <div class="desc-group">
                <div class="title">단방향 모드</div>
                <div class="desc-p">단방향 모드에서는 기호가 단방향으로만 위치를 유지할 수 있습니다.</div>
              </div>
            </button>
            <button class="trade-mode-btn" :class="{ active: cpos === '2' }" @click="chgPosition('2')">
              <img src="@/assets/icons/hedge-mode.svg" alt="">
              <div class="desc-group">
                <div class="title">헤지 모드</div>
                <div class="desc-p">헤지 모드에서는 한 종목이 롱 포지션과 숏 포지션을 동시에 유지하는 것을 지원하며, 실현되지 않은 손익은 두 종목의 포지션 사이에서 상쇄될 수 있습니다.</div>
              </div>
            </button>

            <p>• 포지션 모드는 오픈 포지션이나 오픈 오더로 조정할 수 없습니다. 포지션 모드 조정은 모든
  심볼에 유효합니다. 이설정은 USDⓢ-M 선물에만 적용됩니다.</p>
          </div>
          
        </div>
			</div>

      <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" :class="{'btn-disabled':cposFlg == false }" :disabled="cposFlg == false ? true : false" @click="selectPositionMode()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'CoinModalTradeMode',

  data() {
    return {
      cpos: "1",
      cposFlg:true,
      coinAccount:null,
      coinSymbol:null,
      width: '',
      height: '',
      tradeMode: 'oneway',
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 380;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    opened(){
      
    },
    beforeOpen(e){
      console.log("beforeOpen e.prams", e.params);
      //{marginMode: this.marginMode, coinAccount: this.coinAccount, coinSymbol:this.coinSymbol }

      // 초기화
      this.cpos = "1";
      this.cposFlg = true;
      this.coinSymbol = null;
      this.coinAccount = null;

      this.cpos = e.params.cpos;
      this.coinSymbol = e.params.coinSymbol;
      this.coinAccount = Object.assign({}, e.params.coinAccount);

      this.positionChk();

    },
    chgPosition(type){
      this.positionChk();
      if (this.cposFlg == true){
        this.cpos = type;
      }      
    },
    positionChk(){
      //포지션 체크
      const positionList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
      if (positionList != undefined && positionList.length > 0){
        let balQty = 0;
        let ordQty = 0;
        let mitQty = 0;
        positionList.forEach(data=>{
          if (data.symCd == this.coinSymbol.name && data.ordType == "POS"){
            balQty += data.balQty;
          }else if (data.symCd == this.coinSymbol.name && data.ordType == "ORD"){
            ordQty += data.balQty;
          }else if (data.symCd == this.coinSymbol.name && data.ordType == "ST"){
            mitQty += data.balQty;
          }
        });

        if (balQty > 0 || ordQty > 0 || mitQty> 0){
          this.cposFlg = false;
        }else{
          this.cposFlg = true;
        }
      }
    },
    //포지션 모드 체크 및 저장
    async selectPositionMode(){
      const data = {accNo:this.coinAccount.accNo, symCd:this.coinSymbol.name, ordType:"coin"};

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        console.log("response", response)
        if (response.code == 200){
          if (response.data == null){
            this.$alert.alert('코인선물 잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          }else if (response.data.coinOrdQty != undefined && response.data.coinOrdQty > 0){
            this.$alert.alert('코인선물 잔고/미체결 내역이 있으면 포지션 모드 변경 불가합니다.', '안내');
            this.cpos = response.data.cpos;
            return;
          }

          //포지션 모드 저장
          const saveData = {accNo:this.coinAccount.accNo, symNo:this.coinSymbol.symbolno, cpos:this.cpos};
          console.log("saveData >>> ", saveData)
          this.$lhttp.post('/api/order/usrPos/save', saveData).then((res) =>{
            this.$emit("event:cpos");
            this.closeModal();
          });
          
        }
      });
    },
    closeModal() {
      this.$modal.hide('modalTradeMode');
    },
  },
};
</script>
<style lang="scss" scoped></style>
