const state = {
  laySeq : 0,
  isSave : true,
  layoutName : '디폴트 화면',
  layout: {
    // path: '/desktop',
    // hideAccount: false,
    // hidePending: false,
    // hideOrders: false,
    // hideSide: false,
    // rtPosGridCustom: {          
    //   symCd: true, 
    //   ordSdNm: true,
    //   balQty: true,
    //   execPrc: true,
    //   nowPrice: true,
    //   valPl: true,
    //   crcNm: true,
    //   shtNm: true,
    //   expDt: true,
    // },
    // ordHstGridCustom: {          
    //   ordTm: true,
    //   symCd: true,
    //   symKorNm: true,
    //   ordSdNm: true,
    //   ordTpNm: true,
    //   ordQty: true,
    //   ordPrc: true,
    //   execQty: true,
    //   execPrc: false,
    //   ordId: false,
    //   orgHstOrdNo: false,
    // },
    // favoriteGridCustom : {
    //   itrstNm: '관심종목',
    //   shtNm: true,
    //   curPr: true,
    //   ydiffPr: true,
    //   chGrate: true,
    //   totQ: true,
    //   seq:0,
    //   socketSymbol: []
    // },
    orderSetting : {
      // oneClick: true,
      // dbClick: false,
      btnClick: 'oneClick',
      slTikVal1: {tikVal: 5},
      slTikVal2: {tikVal: 5},
      clickVal1: {val:1},
      clickVal2: {val:2},
      clickVal3: {val:3},
      clickVal4: {val:4},
      clickVal5: {val:5},
      centerKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      marketSellKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      marketBuyKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      cancelSell: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      cancelBuy: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      stopSellKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      stopBuyKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      allClear: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      symbolClear: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
      messageAlert: true,
      ordMessageAlert: true,
      clearMessageAlert: true,
      soundAlert: true,
      leverage: 1,
    },
    favoriteSymbol: null,
    //msgTpCds: ['MSGTP00', 'MSGTP01', 'MSGTP02', 'MSGTP03', 'MSGTP04', 'MSGTP05', 'MSGTP06', 'MSGTP07', 'MSGTP08', 'MSGTP09', 'MSGTP10', 'MSGTP11', 'MSGTP12', 'MSGTP99'],
  },
  cpnAuthList: null, //커스텀 화면 설정관련
}

const getters = {
  getLaySeq (state) {
    return state.laySeq
  },  
  isSave (state) {
    return state.isSave
  },  
  getLayoutName (state) {
    return state.layoutName
  },     
  getLayout (state) {
    return state.layout
  },
  getRtPosGridCustom (state) {
    return state.layout.rtPosGridCustom
  },
  getOrdHstGridCustom (state) {
    return state.layout.ordHstGridCustom
  },
  getFavoriteGridCustom (state) {
    return state.layout.favoriteGridCustom
  },
  getOrderSetting (state) {
    return state.layout.orderSetting
  },
  getMsgTpCds (state) {
    return state.layout.msgTpCds
  },
  getCpnAuthList(state){
    return state.cpnAuthList
  },
  getFavoriteSymbol (state) {
    return state.layout.favoriteSymbol
  },
  
}

const mutations = {
  setLaySeq (state, { laySeq }) {
    state.laySeq = laySeq
  },    
  setSave (state, { isSave }) {
    state.isSave = isSave
  }, 
  setLayoutName (state, { layoutName }) {
    state.layoutName = layoutName
  },     
  setLayout (state, { layout }) {
    state.layout = layout
  },   
  setPath (state, { path }) {
    state.layout.path = path
  },
  setHideAccount (state, { hideAccount }) {
    state.layout.hideAccount = hideAccount
  },
  setHidePending (state, { hidePending }) {
    state.layout.hidePending = hidePending
  },
  setHideOrders (state, { hideOrders }) {
    state.layout.hideOrders = hideOrders
  },
  setHideSide (state, { hideSide }) {
    state.layout.hideSide = hideSide
  },          
  setRtPosGridCustom (state, { custom }) {
    state.layout.rtPosGridCustom = custom
  },
  setOrdHstGridCustom (state, { custom }) {
    state.layout.ordHstGridCustom = custom
  },
  setFavoriteGridCustom (state, { custom }) {
    state.layout.favoriteGridCustom = custom
  },
  setOrderSetting (state, { custom }) {
    state.layout.orderSetting = custom
  },
  setMsgTpCds (state, { custom }) {
    state.layout.msgTpCds = custom
  },
  setCpnAuthList (state, { cpnAuthList }) {
    state.cpnAuthList = cpnAuthList
  },
  setFavoriteSymbol (state, { custom }){
    state.layout.favoriteSymbol = custom
  }
}

const actions = {
  load ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      window.$http
        .get('/common/layout')
        .then(response => {
          if ( response ) {
            if ( response.data !== null ) {
              commit('setLayout', { layout: JSON.parse(response.data.layCont) })

              let isSave = true
              if ( response.data.laySteYn !== 'Y' ) {
                isSave = false
              }

              commit('setLayoutName', { layoutName: response.data.layNm })
              commit('setSave', { isSave: isSave })
              commit('setLaySeq', { laySeq: response.data.laySeq })
            }
          }
          resolve()
        })
        .catch(e => reject(e))
    })     
  },
  use ({ commit, dispatch }, laySeq) {
    return new Promise((resolve, reject) => {
      window.$http
        .get('/common/layout/use', { params : {laySeq: laySeq} })
        .then(response => {
          if ( response ) {
            if ( response.data !== null ) {
              commit('setLayout', { layout: JSON.parse(response.data.layCont) })

              let isSave = true
              if ( response.data.laySteYn !== 'Y' ) {
                isSave = false
              }

              commit('setLayoutName', { layoutName: response.data.layNm })
              commit('setSave', { isSave: isSave })
              commit('setLaySeq', { laySeq: response.data.laySeq })
            }
          }
          resolve()
        })
        .catch(e => reject(e))
    })     
  },  
  save ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      if ( !state.isSave ) {
        return
      }

      const params = {
        laySeq : state.laySeq,
        layNm : state.layoutName,
        laySteYn : (state.isSave) ? 'Y' : 'N',
        layCont : JSON.stringify(state.layout)
      }

      window.$http
        .post('/common/layout/save', params)
        .then(response => {
          resolve()
        })
        .catch(e => reject(e))
    })     
  },
  saveCustom ({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      if ( params.type === 'PS' ) {
        commit('setRtPosGridCustom', { custom: params.custom })
      } else if ( params.type === 'HS' ) {
        commit('setOrdHstGridCustom', { custom: params.custom })
      } else if ( params.type === 'FV' ) {
        commit('setFavoriteGridCustom', { custom: params.custom })
      } else if ( params.type === 'OS') {
        commit('setOrderSetting', { custom: params.custom })
      } else if ( params.type === 'MSG') {
        commit('setMsgTpCds', { custom: params.custom })        
      } else if ( params.type === 'SYMBOL'){
        commit('setFavoriteSymbol', { custom: params.custom })        
      }

      dispatch('save')
    })
  },
  updateSave ({ commit, dispatch, state }, isSave) {
    return new Promise((resolve, reject) => {
      commit('setSave', { isSave: isSave })

      if ( state.laySeq <= 0 ) {
        return
      }

      window.$http
        .post('/common/layout/save/update', { laySeq: state.laySeq, laySteYn: (state.isSave) ? 'Y' : 'N' })
        .then(response => {
          resolve()
        })
        .catch(e => reject(e))
    })     
  },
  setPopupOrderSetting({ commit, dispatch, state }, data) {
    commit('setOrderSetting', { custom: data.data })
  },
  setCpnAuth({commit}, data){
    commit('setCpnAuthList', { cpnAuthList: data.data })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}