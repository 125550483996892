<template>
  <div class="orderbook-container">
    <div class="panel-header">
      <div class="header-title">거래호가</div>
    </div>

    <div class="view-option-header">
      <div class="view-option">
        <button :class="{active : orderBookMode == '1'}" @click="chnageOrderBook('1')"><img src="@/assets/icons/view-option1.svg" alt=""></button>
        <button :class="{active : orderBookMode == '2'}" @click="chnageOrderBook('2')"><img src="@/assets/icons/view-option2.svg" alt=""></button>
        <button :class="{active : orderBookMode == '3'}" @click="chnageOrderBook('3')"><img src="@/assets/icons/view-option3.svg" alt=""></button>
      </div>

      <div class="base-dropdown no-border">
        <div class="dropdown" @click="showTickList">
          <div class="selected">0.1</div>
          <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
        </div>
        <ul class="dropdown-list" :class="{show:showTick, hide:!showTick}">
          <!-- show 클래스로 확인 -->
          <li>0.01</li>
          <li>0.1</li>
          <li>1</li>
          <li>10</li>
          <li>50</li>
          <li>100</li>
        </ul>
      </div>
    </div>

    <div class="orderbook-table" v-show="orderBookMode == '1' || orderBookMode == '2'">
      <div class="orderbook-label">
        <div class="price">가격{{orderBookMode}}</div>
        <div class="volume">수량</div>
        <div class="total">합계</div>
      </div>
      <div class="orderbook-row sell" v-for="(item, index) in asksObject.slice().reverse()" :key="'ask'+index" @click="selectRowItem(item)">
        <div class="price"> {{ item.price }} </div>
        <div class="volume"> {{ item.size }} </div>
        <div class="total"> {{ item.sum }} </div>
        <div class="bg"></div>
      </div>
    </div>
    <div class="price-row buy">
      <!-- 클래스 buy, sell -->
      <div class="price-label">
        <div class="current-price" :class="{ 'color-blue' : cot.buyerMaker === true && cot.curPr > 0, 'color-red' : cot.buyerMaker === false && cot.curPr > 0, '' : cot.curPr == 0 }">{{ cot.curPr.toFixed(this.tikDotSz) }}</div>
        <div class="price-direction">
          <svg class="arrow-icon buy" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-if="cot.buyerMaker == false">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2.999l7.071 7.071-1.768 1.768-4.054-4.055V21h-2.5V7.784l-4.053 4.054-1.768-1.768 7.07-7.071H12z"
              fill="currentColor"
            ></path>
          </svg>
          <svg class="arrow-icon sell" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-if="cot.buyerMaker == true">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 20.999l7.071-7.071-1.768-1.768-4.054 4.055V2.998h-2.5v13.216L6.696 12.16l-1.768 1.768 7.07 7.071H12z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
        <div class="last-price">{{ com.markPr.toFixed(this.tikDotSz) }}</div>
      </div>
    </div>
    <div class="orderbook-table" v-show="orderBookMode == '1' || orderBookMode == '3'">
      <div class="orderbook-row buy" v-for="(item, index) in bidsObject" :key="'bid'+index" @click="selectRowItem(item)">
        <div class="price"> {{ item.price }} </div>
        <div class="volume"> {{ item.size }} </div>
        <div class="total"> {{ item.sum }} </div>
        <div class="bg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { stringCount } from '@/modules/helper';

export default {
  name: 'orderBook',
  components: {
  },
  computed: {
    //ask 호가 표시개수만큼 자르기
    asksObject(){
      //viewCnt +1 라인부터 , viewCnt +1 + viewCnt 목록개수보다 적으면 .asksListlength
      let first = 0;
      let last = 20;
      const maxlen = this.asksList.length;
      //16 < 20
      if (this.viewCnt < this.asksList.length){
        first = this.asksList.length - this.viewCnt+1;
        //17 + 16 ...
        //9 ~ 20
      }
      //return this.viewCnt ? this.asksList.slice(this.viewCnt+1, this.viewCnt+1+this.viewCnt < this.asksList.length ? this.viewCnt+1+this.viewCnt : this.asksList.length) : this.asksList
      return this.viewCnt ? this.asksList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.asksList
    },
    //bid 호가 표시개수만큼 자르기
    bidsObject(){
      return this.viewCnt ? this.bidsList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.bidsList
    },
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin'];
    },
    //소켓 코인 시세
    changeCocData: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 호가
    changeCohData: function(){
      let cohData = window.$store.getters['SocketCoinPrice/getCohData']
      return cohData
    },
    //소켓 코인 센터가
    changeCotData: function(){
      let cotData = window.$store.getters['SocketCoinPrice/getCotData']
      return cotData
    },
    //소켓 코인 청산가
    changeComData: function(){
      let comData = window.$store.getters['SocketCoinPrice/getComData']
      return comData
    },
    //폴링시세
    changeCocDataList: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    //폴링호가
    changeCohDataList: function(){
      let cohData = window.$store.getters['SocketCoinPrice/getCohDataList']
      return cohData
    },
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    tikDotSz(){
      if (this.coinSymbol == undefined){
        return 0;
      }
      return stringCount(this.coinSymbol.pricescale);
    },
    resultCoinOms: function(){
      return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    },
    getAbleQty: function(){
      return window.$store.getters['SocketCoinOms/getAbleQty'];
    },
    resultMsg: function(){
      return window.$store.getters['SocketMsg/getMessage'];
    },
    getDailyPlMn: function(){
      return window.$store.getters['SocketCoinOms/getDailyPlMn'];
    },
    getPosOrdStList: function(){
      return window.$store.getters['SocketCoinOms/getPosOrdStList'];
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
    getCoinPosOrdStList: function(){
      return window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
    },
  },
  watch: {
      //소켓시세
      changeCocData(newPrice){
      //console.log("changeOvcData >>>> ", newPrice)
      if (newPrice.symbol == this.coinSymbol.name){
        this.cot = newPrice
      }
      //
    },
    //소켓호가
    changeCohData(newPrice){
      // apiType
      // asks
      // bids
      // hotTime
      // symbol
      if (newPrice.symbol == this.coinSymbol.name){
        this.updateCoh(newPrice);
      }
    },
    //소켓 코인 센터가
    changeCotData(newPrice){
      if (newPrice.symbol == this.coinSymbol.name){

        // apiType: "COT"
        // buyerMaker: false
        // curPr: 61094.7
        // korDate: "20241011"
        // korTm: "191003"
        // qty: 0.017
        // symbol: "BTCUSDT"
        // trdTm: 1728641403276

        
        //this.cot = newPrice
      }
    },
    //소켓 코인 청산가
    changeComData(newPrice){
      // apiType
      // korDate
      // korTm
      // markPr
      // rate
      // symbol
      // trdTm
      if (newPrice.symbol == this.coinSymbol.name){
        this.com = newPrice
      }
    },
    //폴링시세
    changeOvcDataList(newPrice){
      //
    },
    //폴링호가
    changeOvhDataList(newPrice){
      //
    },
    getSelectCoinSymbol(newVal){
      if (newVal == undefined) return;

      this.coinSymbol = newVal;

      this.bidsList = [];
      this.asksList = [];
      this.cot.curPr = 0;
      this.com.markPr = 0;
    },
  },
  data() {
    return {
      showTick:false,
      cot:{
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      com:{
        markPr: 0,
      },
      bidsList:[],
      asksList:[],
      viewCnt:8,
      coinSymbol:null,
      tikSize: 0,
      orderBookMode:"1",
    }
  },
  created(){
    //ordersetting 호출 후 buy sell 색상 설정
  },
  mounted() {
  },
  methods:{
    showTickList(){
      this.showTick = !this.showTick;
      console.log("showTick", this.showTick)
    },
    selectRowItem(item){
      this.$emit("updateCoinPrice", item);
    },
    updateCoh(newPrice){
      let asksList = [];
      let askSum = 0;
      let revsAskList = [];

      //ask
      if (newPrice.asks != undefined){
        //sum값 계산, 배열 순번이 뒤로 갈수록 합산
        newPrice.asks.forEach(item=>{
          askSum += Number(item[1]);
          item[2] = askSum.toFixed(3);
        })

        //리스트 리버스
        // for (let i=newPrice.asks.length-1; i>=0;i--){
        //   let item = newPrice.asks[i];
        //   asksList.push({price:item[0], size:item[1], sum:item[2]});
        // }
        for (let i=0; i<newPrice.asks.length; i++){
          let item = newPrice.asks[i];
          asksList.push({price:item[0], size:item[1], sum:item[2]});
        }

        this.asksList = asksList;
      }

      //bid
      if (newPrice.bids != undefined){
        let bidsList = [];
        let bidSum = 0;
        newPrice.bids.forEach(item=>{
          bidSum += Number(item[1]);
          bidsList.push({price:item[0], size:item[1], sum:bidSum.toFixed(3)});
        });

        this.bidsList = bidsList;
      }
    },
    chnageOrderBook(type){
      if (this.orderBookMode == type) return;

      this.orderBookMode = type;
      if (type == "1"){ //매도 매수 분할 표시
        this.viewCnt = 8;
      }else if (type == "2"){ //매도만 표시
        this.viewCnt = 18;
      }else if (type == "3"){ //매수만 표시
        this.viewCnt = 18;
      }
    }
  },

}
</script>