import Utils from '@/modules/utils'

const state = {
  channelToCoinOms: new Map(),  
  socketCoinOms : null,
  isConnectCoinOms: false,
  sessionStorage: null,
  resultOrder: null,
  ordAble:{ordAbleBuyQty:0, ordAbleSellQty:0},
  coinDailyPlMn: {},
  coinPosOrdStList:[],
  coinOmsInterval: null,
  connectCoinOmsTimeout: null,
  usrPos: {},
}

const getters = {
  //oms접속
  getIsConnectCoinOms(state){
    return state.isConnectCoinOms
  },
  //oms 주문 리턴 조회
  getCompleteOrder(state){
    return state.resultOrder
  },
  //주문 가능 수량 조회
  getAbleQty(state){
    const ableQty = Object.assign({}, state.ordAble)
    return ableQty
  },
  //실현손익등 조회
  getCoinDailyPlMn(state){
    const coinDailyPlMn = Object.assign({}, state.coinDailyPlMn)
    return coinDailyPlMn
  },
  getCoinPosOrdStList(state){
    const coinPosOrdStList = Object.assign([], state.coinPosOrdStList)
    return coinPosOrdStList
  },
  getUsrPos(state){
    const usrPos = Object.assign({}, state.usrPos);
    return usrPos;
  },
}

const mutations = {
  setSessionStorage (state, {sessionStorage}){
    //console.log("oms setSessionStorage", sessionStorage)
    state.sessionStorage = sessionStorage
  },
  setConectCoinOms (state, {socketCoinOms}){
    state.socketCoinOms = socketCoinOms
  },
  setIsConnectCoinOms(state, {isConnect}){
    //console.log("setIsConnectCoinOms >>> ", isConnect)
    state.isConnectCoinOms = isConnect
  },
  sendCoinOmsMessage(state, {apiCode, body}){      
    // console.log("sendCoinOmsMessage >>>", apiCode)
    // console.log("sendCoinOmsMessage >>>", body)

    if (!state.isConnectCoinOms ) {
      console.log("COIN OMS Connect Fail.")
      //window.$alert.alert('COMS 서버 연결 종료.')
      //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: 'COMS 서버 연결 종료.' })
      return
    }

    const reqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage,
      },
      body : body
    } 

    try{
      state.socketCoinOms.send(JSON.stringify(reqData))
    }catch(e){
      // console.log("e >>> ", e, body)
    }
  },
  setChannelToCoinOms(state,  {subscribeItem}){
    // console.log("setChannelToCoinOms >>>> subscribeItem", subscribeItem)
    state.channelToCoinOms.set(subscribeItem.subscriptionUID, subscribeItem)
  },
  delChannelToCoinOms(state,  {subscribeItem}){
    // console.log("delChannelToCoinOms >>>> subscribeItem", subscribeItem)
    state.channelToCoinOms.delete(subscribeItem.subscriptionUID)
  },
  sendCoinOmsHeartBeat(state) {
    //console.log("sendCoinOmsHeartBeat ", state.sessionStorage)
    const reqData = {
      header: {
      apiCode : 'COMS0000',
      token : state.sessionStorage
      },
      body : {
      }
    }
    if (state.socketCoinOms.readyState == 1){
      state.socketCoinOms.send(JSON.stringify(reqData))
    }
  },
  setCoinDisconnect(state){
    if (state.isConnectCoinOms){
      state.socketCoinOms.onclose = function () {}
      state.socketCoinOms.close()
      state.isConnectCoinOms = false
    }
    clearInterval(state.coinOmsInterval)
  },
  setCompleteOrder(state,  {apiCode, body, notify, result}){
    let resultOrder = {apiCode:apiCode, body:body, notify:notify, result:result}
    state.resultOrder = resultOrder
  },
  setAbleQty(state, {data}){
    let ordAbleSellQty = 0;
    if (data != undefined && data.ordAbleSellQty != undefined) ordAbleSellQty = data.ordAbleSellQty;

    let ordAbleBuyQty = 0;
    if (data != undefined && data.ordAbleBuyQty != undefined) ordAbleBuyQty = data.ordAbleBuyQty;

    state.ordAble = Object.assign({}, {ordAbleSellQty:ordAbleSellQty, ordAbleBuyQty:ordAbleBuyQty})
  },
  setCoinDailyPlMn(state, {data}){
    state.coinDailyPlMn = Object.assign({}, data)
  },
  setCoinPosOrdStList(state, {data}){
    state.coinPosOrdStList = Object.assign([], data)
  },
  setPopupCompleteOrder(state,  {data}){
    //console.log("setPopupCompleteOrder >>>> ", data);
    state.resultOrder = data;
  },
  setUsrPos(state, {data}){
    const usrPosData = {cpos:data.cpos, leverage:data.leverage, marginMode:data.marginMode};
    state.usrPos = Object.assign({}, usrPosData);
  },
}

const actions = {
  //소켓 커넥션 생성
  setConectCoinOms({commit, dispatch, state}, sessionStorage){
    // console.log("setConectOms >>>> ", sessionStorage)
    commit('setSessionStorage', {sessionStorage:sessionStorage.sessionStorage})
    const websocketPath = window.$store.VUE_APP_OMS_COIN_SOCKET_BASE_URL

    if(state.socketCoinOms && state.socketCoinOms.readyState == WebSocket.OPEN){       
        state.socketCoinOms.close()
    }
    commit('setConectCoinOms', {socketCoinOms: new WebSocket(websocketPath)})
    state.socketCoinOms.onopen = () => {
      // console.log("socketOms onopen ??", sessionStorage)
      commit('setIsConnectCoinOms', {isConnect:true})
      clearInterval(state.coinOmsInterval)
      if (state.socketCoinOms.readyState != 1){
        return;
      }

      let subscriptionUID = sessionStorage.subscriptionUID

      if ( subscriptionUID == null ) {
        subscriptionUID = Utils.getUuidv4()
        //sessionStorage.setItem('subscriptionUID', subscriptionUID)
        sessionStorage.subscriptionUID = subscriptionUID
      }
  
      // 소켓 구독
      const subscribeItem = {
        subscriptionUID: subscriptionUID,
        data: new Array()
      }
  
      commit('setChannelToCoinOms', {subscribeItem:subscribeItem})
      commit('sendCoinOmsMessage', {apiCode:'COMS9999'})

      state.coinOmsInterval = setInterval(() => {
          commit('sendCoinOmsHeartBeat', {})
      }, 50000)      
    }

    state.socketCoinOms.onmessage = (e) => {
      //console.log("onMessage?", e.data)
      const returnData = JSON.parse(e.data)

      if ( returnData.header.result == 'S' ) {
        dispatch('completeOrder', {apiCode:returnData.header.apiCode, body:returnData.body, result:returnData.header.result}) 
      } else {
        dispatch('procFailMessage', {apiCode:returnData.header.apiCode, body:returnData.body, result:returnData.header.result}) 
      }
    }

    state.socketCoinOms.onerror = (e) => {
      commit('setIsConnectCoinOms', {isConnect:false})
      console.error("[Error] socket Error")
    }

    state.socketCoinOms.onclose = (e) => {
      commit('setIsConnectCoinOms', {isConnect:false});
      // console.error("[OnClose] socket close")

      if (e.wasClean) {
        console.log('[OMS] WS Closed')
        clearInterval(state.coinOmsInterval);
        state.connectCoinOmsTimeout = setTimeout(() => {
          dispatch('setConectCoinOms', {sessionStorage:state.sessionStorage});
        }, 5000);
      } else {
        console.log('[OMS] WS Connecting...')
        dispatch('setConectCoinOms', {sessionStorage:state.sessionStorage})
      }

      // clearTimeout(state.connectOmsTimeout)
      // state.connectOmsTimeout = setTimeout(() => {
      //   dispatch('setConectOms', {sessionStorage:state.sessionStorage})
      // }, 1000)
    }
  },
  setUnSubscribeOnOms({commit, state}){
      // 소켓 구독 종료
    const subscriptionUID = sessionStorage.getItem('subscriptionUID')
    const data = state.channelToCoinOms.get(subscriptionUID)
    if(data != null){
      if(state.isConnectCoinOms){
        commit('sendCoinOmsMessage', {apiCode:'COMS9998'})
      }
      commit('delChannelToCoinOms', {subscriptionUID:subscriptionUID})
    }
  },
  sendCoinOmsMessage({commit, state}, {apiCode, body}){
    commit('sendCoinOmsMessage', {apiCode:apiCode, body:body})
  },
  completeOrder({commit, state}, {apiCode, body, result}){
    // console.log("completeOrder apiCode >>> ", apiCode)
    // console.log("completeOrder body >>> ", body)

    let notify = null
    

    // 주문
    if ( apiCode == 'COMS0001' ) {
      if (body.execYn == 'Y'){
        notify = {group: 'notification', title: 'SUCCESS', text:'[주문완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // 취소  
    } else if ( apiCode == 'COMS0002' ) {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // 정정
    } else if ( apiCode == 'COMS0003' ) {

      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[정정완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // STOP 주문
    } else if (apiCode == 'COMS0004') {
      if ( body.execYn == 'Y' ) {
        //notify = {group: 'notification', title: 'SUCCESS', text:'[ST주문완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // STOP 취소
    }  else if ( apiCode == 'COMS0005') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[MIT취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // STOP 정정
    } else if ( apiCode == 'COMS0006') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[MIT정정완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // STOP LOSS 주문
    } else if ( apiCode == 'COMS0007') {
      //
    // STOP LOSS 취소 
    } else if ( apiCode == 'COMS0008') {
      //
    // 종목선택 취소
    } else if ( apiCode == 'COMS0010') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
      // STOP 종목선택 취소
    } else if ( apiCode == 'COMS0011') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[MIT취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // 상단 일괄 취소 버튼
    } else if ( apiCode == 'COMS0012') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // 전/현 종목 청산
    } else if ( apiCode == 'COMS0013') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[청산완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // PanelComp2 청산버튼 - 선택 종목의 보유포지션 청산
    } else if ( apiCode == 'COMS0014') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[청산완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
        // this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[청산완료] 주문이 처리되었습니다.' })
      }
    } else if ( apiCode == 'COMS0015'){ // 원주문 이미 처리..
    } else if ( apiCode == 'COMS0017'){ // RMS 청산
      notify = {group: 'notification', title: 'SUCCESS', text:'[시스템청산] RMS 청산이 처리 되었습니다.', body : body , apiCode:apiCode}
      //body.ordApiType 정정, 취소 구분
    } else if ( apiCode == 'COMS0018'){ // 전/현 종목 취소
      notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
    } else if ( apiCode == 'COMS9997'){ //서버 상태 조회
        console.log("COMS9997 >>>> ", body);
    } else if ( apiCode == 'COMS9995'){
      notify = {group: 'notification', title: 'SUCCESS', text:'[장마감] 장마감 청산이 완료되었습니다.', body : body , apiCode:apiCode}
    } else if ( apiCode == 'COMS9994'){
      notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 익절/손절 자동 취소 되었습니다.', body : body , apiCode:apiCode}
    }

    commit('setCompleteOrder', {apiCode:apiCode, body:body, notify:notify, result:result})
  },
  //실패 메시지 호출
  procFailMessage({commit, state}, {apiCode, body, result}){
    // console.log("procFailMessage >>>>>>>>>")
    commit('setCompleteOrder', {apiCode:apiCode, body:body, notify:null, result:result})
  },
  //가능수량 조회
  selectAbleQty({commit, state}, {accId, symCd, symNo, lv}){
    return new Promise((resolve, reject) => {
      window.$http
        .get('/api/order/ord-able-qty', { params: {accId: accId, symCd: symCd, symNo:symNo, lv:lv} })
        .then(response => {
          if ( response ) {
            console.log("response ", response.data)
            if ( response.data !== null ) {
              commit('setAbleQty', {data:response.data})
            }
          }
          resolve()
        })
        .catch(e => reject(e))
    })   
  },

  //평가손익/잔고/가능수량등 1번에 조회
  async selectUsrData({commit, state}, {accId, symCd, symNo, lv}){
    return await new Promise((resolve, reject) => {
      window.$http
        .get('/api/order/ord-usr-data', { params: {accId: accId, symCd: symCd, symNo:symNo, lv:lv, ordType:'coin'} })
        .then(response => {
          if ( response ) {
            if ( response.data !== null ) {
              commit('setAbleQty', {data:response.data.ordAble})
              commit('setCoinDailyPlMn', {data:response.data.coinDailyPlMn})
              commit('setCoinPosOrdStList', {data:response.data.coinPosOrdStList})
            }
          }
          resolve()
        })
        .catch(e => reject(e))
    })   
  },
  //코인 포지션/마진모드/레버리지 조회
  async selectUsrPos({commit, state}, {accNo, symCd}){
    return await new Promise((resolve, reject) => {
      window.$http
        .get('/api/order/rtPosOrdChk', { params: {accNo:accNo, symCd:symCd, ordType:"coin"} })
        .then(response => {
          if (response.code == 200){
            if (response.data == null){
              this.$alert.alert('코인선물 포지션/마진모드 조회중 에러가 발생했습니다.', '안내');
              return;
            }
            commit('setUsrPos', {data:response.data});
          }
          resolve()
        })
        .catch(e => reject(e))
    })
  },
  
  //커넥션 종료
  setCoinDisconnect({commit, state}){
    let subscriptionUID = sessionStorage.subscriptionUID

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      //sessionStorage.setItem('subscriptionUID', subscriptionUID)
      sessionStorage.subscriptionUID = subscriptionUID
    }

    // 소켓 구독
    const subscribeItem = {
      subscriptionUID: subscriptionUID,
      data: new Array()
    }

    commit('delChannelToCoinOms', {subscribeItem:subscribeItem})
    //commit('sendOmsMessage', {apiCode:'COMS9998'}) //구독해지

    commit("setCoinDisconnect")
  },

  //사용자 로그 전송
  setLogMsg({commit, state}, {message} ){ //사용자 로그 설정
    let logOrder = {
      usrKey: Utils.getUuidv4(),
      message : message,
      time : Utils.getNowTime(),
    }

    console.log("logOrder >>>> ",logOrder)
      //this.sendOmsMessage("COMS9991", logOrder);
    commit('sendCoinOmsMessage', {apiCode:'COMS9991', body:logOrder})
  },

  //---팝업주문창 관련---
  setChannelData({commit, state}, {type, data}){
    if (type == "COMS"){
      //state.resultOrder = data;
      commit('setPopupCompleteOrder', {data:data});
    }else if (type == "POS_ORD"){
      commit('setCoinPosOrdStList', {data:data})
    }else if (type == "DAILY"){
      commit('setCoinDailyPlMn', {data:data})
    }
  },
  //---팝업주문창 관련---

}
  
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}