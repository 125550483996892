<template>
  <div class="login1" :class="backgroundimg">
    <div class="login-form">
      <div class="login-form-header">
        <!-- 도메인을 읽어와서 로그를 바꾸도록 설정해야함-->
        <!-- url(http://localhost:8080/img/login-bg.ebb9bfe1.png) no-repeat -->
       <img v-if="imgLogSrc" ref="imgLogo" class="company-logo" :src="imgLogSrc"  alt="" /> 
				<h1 v-if="loginShow" class="login-title">로그인</h1>
        <!-- <button class="close-btn"><img src="@/assets/icons/close-black.svg" alt=""></button> -->
      </div>
      <div class="login-form-body">
      <validation-observer ref="validator">
        <validation-provider rules="required" name="ID" v-slot="{errors}">
        <input type="text" placeholder="ID" class="input" :class="[errors[0] ? 'error': '']" v-model="form.loginId" />
        <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
        </validation-provider>
        <validation-provider rules="required" name="비밀번호" v-slot="{errors}">
        <input :type="fieldType" placeholder="PW" class="input" :class="[errors[0] ? 'error': '']" v-model="form.passwd" @keyup.enter="login" />
				<div class="error">{{errors[0]}}</div>
        </validation-provider>
      </validation-observer>
        <div class="sub-options">
          <div class="checkbox-default">            
            <input type="checkbox" id="saveId" name="saveId" v-model="idSave" />
            <label for="saveId">아이디 저장</label>
          </div>
          <span class="division">|</span>
          <a href="/signup">회원가입</a>
        </div>
      </div>
      <div class="login-form-footer">
        <button class="btn-primary darkgray medium" @click="windowClose">종료</button>
        <button class="btn-primary blue medium" @click="login">로그인</button>
      </div>
    </div>
  </div>
</template>

<script>
import Const from '@/const'

export default {
  name: 'LoginPage',
  computed:{
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
    getDomain: function(){
      const domain = window.location.hostname; // 현재 도메인 가져오기
      return domain;
    },
    backgroundimg () {
      //업체별로 style.scss파일에 클래스 추가 후 도메인별 조건 추가
      const backImgClass = {login1_background_ngrobal:(this.getDomain === 'mng.n-globalinv.com' || this.getDomain === 'n-globalinv.com') ? true : false
                        // , login1_background_obbtrade:(this.getDomain === 'obbtrade.com' || this.getDomain == 'localhost') ? true : false
                    };
      return backImgClass;
    },
    backgroundtruefalse(){
      return true;
    },
  },
  watch:{
    getLoginUserInfo:{
      handler(userInfo){
        if (this.loginFlag == false && userInfo != undefined && userInfo.usrId != undefined){
          this.logOut();
          this.loginFlag = true;
        }
      }
    },
  },
  data() {
    return {
      imgLogSrc:null,
      fieldType: 'password',
      passwordEye: '',
      idSave: false, 
      form: {
        loginId: this.$cookies.get("wtsLoginId"),
        passwd: '',
        cpnId:1,
      },
      loginFlag:false,
      loginShow:false,
      futUseYn: "N",
      coinUseYn: "N",
    }
  },
  created(){
    //window.$store.dispatch('Login/logout')
    this.loginChk();
    this.domainCheck();
    //src="@/assets/images/toss-logo.png"
  },
  mounted() {
    if (this.$cookies.get("wtsLoginId") != undefined && this.$cookies.get("wtsLoginId") != null){
      this.idSave = true;
    }
  },
  methods: {
    async loginChk(){
      this.logOut();

      // let loginInfo = window.$store.getters['Login/getLoginUserInfo'];

      // if (loginInfo != undefined && loginInfo.usrId != undefined){
      //   this.logOut();
      // }
    },
    logOut(){
      window.$store.dispatch('Login/logout')
    },
    async domainCheck(){
      const host = window.location.host;

      const formData = {hostUrl: host};

      await this.$lhttp.post('/auth/domainChk', formData).then((response) => {
        //console.log("response... ", response);

        if (response.code == 200){
          if (response.data != undefined && response.data.cpnId > 0){
            //console.log(this.form.cpnId)
            this.form.cpnId = response.data.cpnId;
            const cpnAuthList = response.data.cpnAuthList;
            if (cpnAuthList != undefined && cpnAuthList.length > 0){ //커스텀 권한 설정
              window.$store.dispatch('Layout/setCpnAuth', {data:cpnAuthList})
              let loginShowChk = "N";
              cpnAuthList.forEach(item=>{
                if (item.authTpCd == "LOGIN"){ //로그인 글자 표시여부
                  loginShowChk = "Y";
                  if (item.useYn == "N"){
                    this.loginShow = false;
                  }else{
                    this.loginShow = true;
                  }
                }
              })

              //로그인 표시여부가 없으면 보이도록 설정
              if (loginShowChk == "N"){
                this.loginShow = true;
              }
            }else{
              this.loginShow = true;
            }

            //선물, 코인 사용여부
            if (response.data.futUseYn != undefined){
              this.futUseYn = response.data.futUseYn;
            }
            if (response.data.coinUseYn != undefined){
              this.coinUseYn = response.data.coinUseYn;
            }

          }else{
            this.loginShow = true;
            this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
            return;
          }
        }else{
          this.loginShow = true;
          this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
          return;
        }
      })

      //console.log("host >>>>> ", host);
      //DB에서 도메인 정보로 조회하여 이미지 정보를 조회 필요
      // if (host.indexOf("localhost") > -1){


    let faviconUrl = ''; // 기본 favicon URL

    // 도메인에 따른 favicon 설정
    
    if (host === 'mng.n-globalinv.com' || host === 'n-globalinv.com'){
      this.imgLogSrc = require('@/assets/images/logo_b.png');
    }        
      // }else if (host.indexOf("192.168.0.137") > -1){
      //   this.imgLogSrc = require('@/assets/images/toss-logo-white.png');
      // }
    },
    showPassword() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password'
      this.passwordEye = !this.passwordEye
    },
    login() {
      const { validator } = this.$refs;

      validator.validate().then((result) => {
        if (result) {
          //로그인 watch 체크 방지용
          this.loginFlag = true;
          
          window.$store
            .dispatch('Login/login', this.form)
            .then((response) => {

              // 로그인 성공
              if ( response.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {

                if ( this.idSave ) {
                  this.$cookies.set("wtsLoginId", this.form.loginId)
                }

                //console.log("response.data.loginUserInfo.authId", response.data.loginUserInfo.authId)
                
                if (response.data.loginUserInfo.authId != undefined 
                  && (response.data.loginUserInfo.authId == 'PART01'
                  || response.data.loginUserInfo.authId == 'PART02'
                  || response.data.loginUserInfo.authId == 'PART03'
                  || response.data.loginUserInfo.authId == 'PART04')
                ){
                  this.$router.push('/partner');
                }else{
                  var isMobile = /Mobi/i.test(window.navigator.userAgent);

                  if (isMobile){
                    if (this.futUseYn == "Y"){
                      this.$router.push('/mobile');
                    }else if (this.futUseYn == "N" && this.coinUseYn == "Y"){
                      this.$router.push('/coinMobile');
                    }                    
                  }else{
                    if (this.futUseYn == "Y"){
                      this.$router.push('/desktop');
                    }else if (this.futUseYn == "N" && this.coinUseYn == "Y"){
                      this.$router.push('/coinDesktop');
                    }
                  }
                }
              // 로그인 실패  
              } else { 

                this.$alert.alert(response.data.message)
                
              }

            })          
        }
      })
      
    },
    windowClose(){
      this.$alert.confirm(
        '화면을 종료 하시겠습니까?', '종료 확인')
      .then((response) => {
        if (response) {
          location.href="about:blank"
        }
      })
      
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-primary {
  width: unset;
  flex: 1;
  height: 50px;
}

.errPadding{
  padding-bottom:5px;
}

</style>
