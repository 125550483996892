<template>
  <div>
    <modal name="modalTPSP" classes="main-modal" :adaptive="true" :width="width" :height="height">
      <div class="modal-header">
        <h2 class="modal-header-title">이익실현(TP)/스탑로스(SL)</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
				<div class="modal-tpsp">
          <div class="label">주문A가 실행되면 주문B를 제출합니다.</div>
          <table class="modal-tpsp-table">
            <thead>
              <tr>
                <th>주문A</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="label">시장</div>
                  <div class="value">미체결</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">사이드</div>
                  <div class="value"><span class="red">매수</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">금액</div>
                  <div class="value">1,123 BTC</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="division-line-tpsl"><span></span></div>
          <table class="modal-tpsp-table">
            <thead>
              <tr>
                <th>주문B</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="label">이익 현실 시장가</div>
                  <div class="value">신규</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">사이드</div>
                  <div class="value"><span class="blue">매도</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">금액</div>
                  <div class="value">1,123 BTC</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">스탑</div>
                  <div class="value">1,123 BTC</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">발동</div>
                  <div class="value">1,123 BTC</div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="alert-notice">
            <img src="@/assets/icons/alert-blue.svg" alt="" />
            One-Triggers-a One Cancels the-Other(OTOCO) 주문을 사용하면 두 개의 주문, 즉 기본 주문과 두 개의 보조 주문을 동시에 제출할 수 있습니다.
          </div>
        </div>
			</div>

      <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" @click="closeModal()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'CoinModalTPSP',

  data() {
    return {
      width: '',
      height: '',
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 564;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('modalTPSP');
    },
  },
};
</script>
<style lang="scss" scoped></style>
