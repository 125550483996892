<template>
  <div class="control-panel-container">
    <div class="margin-row">
      <button class="btn-line" @click="chageMarginMode">{{ marginMode == "1" ? "Cross" : "Isolated" }}</button>
      <button class="btn-line" @click="changeLeverage">{{ coinLeverage }}x</button>
      <button class="btn-line" @click="changePositionMode">{{ cpos == "1" ? "Oneway" : "Hedge" }}</button>
    </div>

    <!-- 진입청산 -->
    <div class="entry-close" v-show="cpos == '2'">
      <div class="entry" :class="{active: openCloseMode == 'open'}" @click="changeOpenClose('open')">진입</div>
      <div class="close" :class="{active: openCloseMode == 'close'}" @click="changeOpenClose('close')">청산</div>
    </div>

    <div class="order-type-tab-container">
      <button class="order-type-tab" :class="{active: stdOrdTpCd == '1'}" @click="changeOrdTpCd('1')">지정가</button>
      <button class="order-type-tab" :class="{active: stdOrdTpCd == '2'}" @click="changeOrdTpCd('2')">시장가</button>
    </div>

    <div class="balance-row">
      <div class="simple-label">
        Avbl
        <b>0.00 USDT</b>
      </div>
      <button class="icon" @click="resetUsdt">
        금액초기화
        <img src="@/assets/icons/refresh.svg" alt="" />
      </button>
    </div>

    <div class="order-input-group">
      <div class="order-input-container" v-show="stdOrdTpCd == '1'">
        <label for="">가격</label>
        <input type="number" placeholder="가격" v-model="stdOrdPrc" />
        <!-- <button class="unit-btn">USDT</button> -->
        <div class="unit">USDT</div>
      </div>
      <div class="order-input-container">
        <label for="">수량</label>
        <input type="number" placeholder="수량" v-model="stdOrdQty" />
        <!-- <button class="unit-btn">USDT</button> -->
        <div class="base-dropdown no-border" @click="showStdOrdType">
          <div class="dropdown">
            {{ stdOrdType }}
            <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
            <ul class="dropdown-list" :class="{show:stdOrdTypeFlag, hide:!stdOrdTypeFlag}">
              <!-- show 클래스로 확인 -->
              <li @click="changeStdOrdType('USDT')">USDT</li>
              <li @click="changeStdOrdType('BTC')">BTC</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <Slider />

    <div class="sl-tp-container" v-show="stdOrdTpCd == '1'">
      <div class="checkbox-default">
        <input type="checkbox" id="takeProfit" name="stopProfit" v-model="showTpSlFlag" />
        <label for="takeProfit">익절/손절</label>
      </div>
      <div class="order-input-container" v-show="showTpSlFlag">
        <label for="">익절</label>
        <div class="base-dropdown no-border" @click="showTpType">
          <div class="dropdown">
            {{ stdOrdTpType }}
            <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
            <ul class="dropdown-list" :class="{show:showTpFlag, hide:!showTpFlag}">
              <!-- show 클래스로 확인 -->
              <li @click="changeTpType('Mark')">Mark</li>
              <li @click="changeTpType('Last')">Last</li>
            </ul>
          </div>
        </div>
        <input type="number" placeholder="가격" v-model="stdOrdTpPrc" />
        <!-- <button class="unit-btn">USDT</button> -->
        <!-- <div class="base-dropdown no-border">
          <div class="dropdown">
            USDT
            <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
            <ul class="dropdown-list">
              <li>BTC</li>
              <li>USDT</li>
            </ul>
          </div>
        </div> -->
      </div>
      <div class="order-input-container"  v-show="showTpSlFlag">
        <label for="">손절</label>
        <div class="base-dropdown no-border" @click="showSlType">
          <div class="dropdown">
            {{ stdOrdSlType }}
            <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
            <ul class="dropdown-list" :class="{show:showSlFlag, hide:!showSlFlag}">
              <!-- show 클래스로 확인 -->
              <li @click="changeSlType('Mark')">Mark</li>
              <li @click="changeSlType('Last')">Last</li>
            </ul>
          </div>
        </div>
        <input type="number" placeholder="가격" v-model="stdOrdSlPrc" />
        <!-- <button class="unit-btn">USDT</button> -->
        <!-- <div class="base-dropdown no-border">
          <div class="dropdown">
            USDT
            <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
            <ul class="dropdown-list">
              <li>BTC</li>
              <li>USDT</li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>

    <div class="buysell-btn-container">
      <div class="coin-btn-container">
        <button class="order-btn buy" @click="stdCoinSellBuy('buy')">매수/Long</button>
        <div class="simple-label">
          Cost:
          <b>0.00 USDT</b>
        </div>
      </div>
      <div class="coin-btn-container">
        <button class="order-btn sell" @click="stdCoinSellBuy('sell')">매도/Short</button>
        <div class="simple-label">
          Cost:
          <b>0.00 USDT</b>
        </div>
      </div>
    </div>
    <div class="info-panel">
      <div class="panel-header">
        <div class="header-title">예탁금</div>
        <button class="icon">
          자산이동
          <img src="@/assets/icons/transfer.svg" alt="" />
        </button>
      </div>
      <table class="info-list-table">
        <tbody>
          <tr>
            <th>보유자산</th>
            <td>0</td>
          </tr>
          <tr>
            <th>사용가능자산</th>
            <td>0</td>
          </tr>
          <tr>
            <th>사용중자산</th>
            <td>0</td>
          </tr>
          <tr>
            <th>동결자산</th>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="info-panel">
      <div class="panel-header">
        <div class="header-title">계약정보</div>
      </div>
      <table class="info-list-table">
        <tbody>
          <tr>
            <th>만료일자</th>
            <td>0</td>
          </tr>
          <tr>
            <th>자수가격</th>
            <td>0</td>
          </tr>
          <tr>
            <th>표시가격</th>
            <td>0</td>
          </tr>
          <tr>
            <th>24H 판매량</th>
            <td>0</td>
          </tr>
          <tr>
            <th>24H 거래량</th>
            <td>0</td>
          </tr>
          <tr>
            <th>계약가액</th>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Utils from '@/modules/utils';
import Slider from '@/components/coin/Slider.vue';

export default {
  name: 'conrolPanel',
  components: {
    Slider
  },
  props:{
    coinAccount: {
      type: Object
    },
    coinOrdStdPrc: {
      type: Number
    }
  },
  computed: {
    //소켓 코인 시세
    changeCocData: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //폴링시세
    changeCocDataList: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    tikDotSz(){
      return Utils.tikDotSz(this.coinSymbol);
    },
    resultCoinOms: function(){
      return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    },
    getDailyPlMn: function(){
      return window.$store.getters['SocketCoinOms/getDailyPlMn'];
    },
    getUsrPos: function(){
      return window.$store.getters['SocketCoinOms/getUsrPos'];
    },
  },
  watch: {
    //소켓시세
    changeCocData(newPrice){
      //console.log("changeOvcData >>>> ", newPrice)
      if (newPrice.symbol == this.coinSymbol.name){
        this.coinNowPrice.curPr = newPrice.curPr;
        this.coinNowPrice.buyerMaker = newPrice.buyerMaker;
      }
      //
    },
    //폴링시세
    changeCocDataList(newPrice){
      //
    },
    getUsrPos(usrPos){
      console.log("getUsrPos 111", usrPos);
      this.marginMode = usrPos.marginMode;
      this.coinLeverage = usrPos.leverage;
      this.cpos = usrPos.cpos;
    },
    getSelectCoinSymbol(newVal){
      if (newVal == undefined) return;

      this.coinSymbol = newVal;

      this.coinNowPrice = {symCd: newVal.name, symNo:newVal.symbolno, chGrate:0, chPrRate:0, curPr:0, markPr:0, indexPr:0, funding:0, CountDown:0, highPr:0, lowPr:0, btcVol:0, usdtVol:0, usdtOpenInterest:0, buyerMaker:false};

      this.stdOrdPrc = ''; //주문 가격
      this.stdOrdQty = ''; //주문 수량
      this.stdOrdSlPrc = '';
      this.stdOrdTpPrc = '';
    },
    resultCoinOms(res){ 
      if (res.result == "S"){
        this.resetUsdt();
      }
    },
    getDailyPlMn:{
      handler(res){
        console.log("DailyPlMn", res)
        this.coinDailyPlMn = res;
      },        
      deep:true
    },
    coinOrdStdPrc:{
      handler(newVal){
        if (this.stdOrdTpCd == "1"){
          this.stdOrdPrc = newVal;
        }
        
      },
      deep:true
    },
  },
  data() {
    return {
      marginMode: "1",
      coinLeverage: 1,
      cpos: "1",
      openCloseMode: "open",
      stdOrdTpCd: '1',
      stdOrdPrc: '',
      stdOrdQty: '',
      stdOrdType: 'USDT',
      stdOrdTypeFlag:false,
      showTpSlFlag:false,
      showTpFlag:false,
      showSlFlag:false,
      stdOrdTpType:'Mark',
      stdOrdSlType:'Mark',
      stdOrdTpPrc:'',
      stdOrdSlPrc:'',
      btnStdCoinFlag:true,
      coinNowPrice:{curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0},
      coinSymbol:{name:null, base_name:null, symbolno:0},
      coinDailyPlMn: {fxPlMn:"0", fxCms:"0", nFxPlMn:"0", futPlMn:"0", futCms:"0", nFutPlMn:"0", totPlMn:"0", totNPlMn:"0", totCms:"0", niPlMn:"0", balMn:"0", hkdExR:"0", usdExR:"0", usdExHkd:"0", totalValPl:"0", totalLossCut:"0", data:null},

    }
  },
  created(){
  },
  mounted() {

  },
  methods:{
    //마진모드 변경
    chageMarginMode(){
      const params = {marginMode: this.marginMode, coinAccount: this.coinAccount, coinSymbol:this.coinSymbol };
      this.showModal('modalMarginSelect', params)
    },
    //레버리지 변경
    changeLeverage(){
      const params = {coinLeverage: this.coinLeverage, coinAccount: this.coinAccount, coinSymbol:this.coinSymbol };
      this.showModal('modalLeverageSelect', params)
    },
    //포지션모드 변경
    changePositionMode(){
      const params = {cpos: this.cpos, coinAccount: this.coinAccount, coinSymbol:this.coinSymbol };
      this.showModal('modalTradeMode', params)
    },
    //햇지 oepn close 설정
    changeOpenClose(type){
      this.openCloseMode = type;
    },
    //지정가, 시장가 설정
    changeOrdTpCd(ordTpCd){
      this.stdOrdTpCd = ordTpCd;
    },
    //금액/수량 초기화
    resetUsdt(){
      this.stdOrdPrc = '';
      this.stdOrdQty = '';
      this.stdOrdTpPrc = '';
      this.stdOrdSlPrc = '';
    },
    //주문방법 콤보박스 활성/비활성
    showStdOrdType(){
      this.stdOrdTypeFlag = !this.stdOrdTypeFlag;
    },
    //가격/수량 변경
    changeStdOrdType(ordType){
      this.stdOrdPrc = '';
      this.stdOrdQty = '';
      this.stdOrdType = ordType;
    },
    //익절 콤보
    showTpType(){
      this.showTpFlag = !this.showTpFlag;
    },
    //손절 콤보
    showSlType(){
      this.showSlFlag = !this.showSlFlag;
    },
    //익절 콤보 설정
    changeTpType(type){
      this.stdOrdTpType = type;
    },
    //손절 콤보 설정
    changeSlType(type){
      this.stdOrdSlType = type;
    },
    stdCoinSellBuy(type){
      if (!this.stdOrdTpCd){
        window.$alert.alert('지정가/시장가를 선택하세요.', '알림');
        return;   
      }else if (this.stdOrdTpCd == "1" && (!this.stdOrdPrc || this.stdOrdPrc <= 0)){
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;
      }
      
      if (!this.stdOrdQty || this.stdOrdQty <= 0){
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;
      }

      // if ((type == "buy" && this.stdOrdQty == 0) || (type == "sell" && this.stdOrdQty == 0)){
      //   window.$alert.alert('주문가능 수량이 부족합니다.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnStdCoinFlag){
        return;
      }

      let ordSdCd = "0";
      let message = "코인선물 주문창 주문";
      if (type == "sell"){
        ordSdCd = "1";
        message += " 매도";
      }else if (type == "buy"){
        ordSdCd = "2";
        message += " 매수";
      }

      message += " " + this.coinSymbol.name;

      let ordTpCd = this.stdOrdTpCd;
      let apiCode = "";
      let stTpCd = "2";
      let ordPrc = 0;
      let ordQty = 0;
      let minOrdQty = 0.002; //db에서 읽어와야함

      //수량에 USDT로 입력할 경우 BTC 수량으로 계산
      if (this.stdOrdType == "USDT"){
        ordQty = Utils.coinUsdtToBtc(this.stdOrdQty, this.coinNowPrice.curPr, minOrdQty);
      }else{
        ordQty = this.stdOrdQty;
      }

      //BTC 최소 수량 가능한지 체크
      if (ordQty < minOrdQty){
        console.log("ordQty, minOrdQty ", ordQty, minOrdQty)
        window.$alert.alert('주문가능 최소 수량을 확인 해주세요.', '알림');
        return;
      }

      
      if (this.stdOrdTpCd == "2"){ //시장가
        apiCode = "COMS0001";
        message += "/시장가";
      }else if (this.stdOrdTpCd == "1"){ //지정가
        apiCode = "COMS0001";
        ordPrc = this.stdOrdPrc;
        message += "/지정가";
      }else if (this.stdOrdTpCd == "3"){ //MIT, 코인 stop은 아직 없음
        apiCode = "COMS0004";
        ordPrc = this.stdOrdPrc;
        message += "/MIT";
        
        //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
        if (Number(this.coinNowPrice.curPr) == Number(this.stdOrdPrc)){
          this.$notify({ group: 'notification', clean: true })
  
          this.$notify({
            group : 'notification',
            title : 'FAIL',
            text : '[주문거부] 현재가에 MIT주문이 불가능합니다.'
          });
          return;
        } else if (this.coinNowPrice.curPr < Number(this.stdOrdPrc)){
          stTpCd = "1";
        }
      }

      message += "/수량 "+this.stdOrdQty + "/가격 "+ordPrc;

      this.btnStdCoinFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.coinAccount.accId,
        symCd : this.coinSymbol.name,
        symNo : this.coinSymbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : ordSdCd,
        ordTpCd : ordTpCd,
        ordPrc : ordPrc,
        ordQty : ordQty,
        repReqQty : 0,
        cxlReqQty : 0,
        stTpCd : stTpCd,
        //accPass : this.getUsrAccPass,
        lv : this.coinLeverage,
      }

      this.sendCoinOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:apiCode, order: order, symbol:this.coinSymbol ,qty:this.ordStdQty, price: Number(ordPrc), message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendCoinOmsMessage(apiCode, order);
      }
      
      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnStdCoinFlag){
        setTimeout(() => {
          this.btnStdCoinFlag = true;
        }, 300);
      }
    },
    sendCoinOmsLogMessage(message){ //코인 사용자 로그 저장
      window.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
    },
    sendCoinOmsMessage(apiCode, body) { // 코인 주문 서버 전송
      if ( !window.$store.getters['SocketCoinOms/getIsConnectCoinOms'] ) {
        window.$alert.alert('주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
        return;
      }

      //비밀번호 추가
      //body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', {apiCode:apiCode, body:body});
    }, 
		showModal(name, params) {
      this.$modal.show(name, params);
    },
  },

}
</script>