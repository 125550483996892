<template>
	<button class="btn-link" @click="showModal('modalTPSPAll')">추가</button>
</template>

<script>
export default {
	name: 'AgTpSl',
	methods: {
		showModal(name) {
      this.$modal.show(name);
      console.log(name);
    },
	},
}
</script>