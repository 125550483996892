<template>
    <div class="base-dropdown" :class="{ full: full }" :style="{width: width+'px'}" ref="dropdown">
      <div class="dropdown" @click="handleDropdown()" :class="{ active: isDropdownVisible }" :style="{height:cdHeight + 'px'}">
        <div class="selected">{{ selectedName }}</div>
        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
      </div>
        <transition name="fade">
          <DropDownList v-if="isDropdownVisible" v-bind:height="height" :popup="popup">
            <div
              class="dropdown-list-item"
              v-for="code in codeList"
              :key="code.cd"
              @click="onSelect(code)">
              <div class="col">
                <h3>{{ code.cdNm }}</h3>
              </div>
            </div>
          </DropDownList>
        </transition>
      </div>
    </template>
    
    <script>
    import DropDownList from '@/components/DropDownList.vue'
    
    export default {
      name: 'CodeDropDown',
      components: {
        DropDownList,
      },
      props: {
        width: [String, Number],
        height: [String, Number],
        cdHeight: {
          type: String,
          default: '',
        },
        full: Boolean,
        popup: Boolean,
        grpCd: {
          type: String,
          default: '',
        },
        allYn: {
          type: String,
          default: 'N'
        },     
        value: {
          type: String,
          default: '',
        }
      },    
    
      data() {
        return {
          codeList: [],
          isDropdownVisible: false,
        }
      },
    
      computed: {
        selectedName() {
          let rtn = ''  
    
          if ( this.allYn != 'Y' && this.value === '' ) {
            if (this.codeList.length > 0 ) {
              rtn = this.codeList[0].cdNm
            }
          } else {
            for (let i in this.codeList) {
              if ( this.value == this.codeList[i].cd ) {
                rtn = this.codeList[i].cdNm
                break
              }  
            }
          }
    
          return rtn
        }
      },  
    
      mounted() {
        window.addEventListener('click', this.closeDropdown);

        let params = {
          grpCd : this.grpCd
        }

        console.log(" com >>>> ", params)
    
        this.$http
          .get('/common/com-code/list', {params : params})
          .then((result) => {
            console.log("com response? ", result)
            this.codeList = result.data
    
            if (this.allYn != 'Y' && this.value == '') {
              this.$emit('input', result.data[0].cd)
            }        
          })
      },
      beforeDestroy() {
        window.removeEventListener('click', this.closeDropdown);
      },
    
      methods: {
        handleDropdown() {
          this.isDropdownVisible = !this.isDropdownVisible
        },
        onSelect(code) {
          if (this.value != code.cd){
            this.$emit('input', code.cd, this.value)
          }
          this.isDropdownVisible = false
        },
        closeDropdown(element){
          if (!this.$refs.dropdown.contains(element.target)) {
            this.isDropdownVisible = false;
          }
        },
      },
    };
    </script>
    
    <style lang="scss" scoped>
    // .base-dropdown {
    // position: relative;
    // white-space: nowrap;
    // height: 30px;
    // cursor: pointer;
    // &.full {
    //     flex: 1;
    //     width: 100%;
    //     .dropdown-list {
    //     width: 100%;
    //     }
    // }
    // .dropdown-title {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     padding: 0 10px;
    //     height: 100%;
    //     /* height: 30px; */
    //     border: 1px solid var(--border-color);
    //     &:hover {
    //     border-color: var(--main-orange);
    //     }
    //     &.active {
    //     border-color: var(--main-orange);
    //     }
    // }
    // h3 {
    //     font-size: 12px;
    //     // font-weight: 400;
    //     padding-bottom: 2px;
    // }
    // .triangle {
    //     margin-left: 5px;
    //     transition: transform 0.2s;
    //     &.open {
    //     transform: rotate(180deg);
    //     }
    // }
    // @media (max-width: $mobile) {
    //     height: 40px;
    // }
    // }
    </style>
      