<template>
  <div>
    <modal name="modalDepositWithdraw" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">입출금신청</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="tab-nav-container">
          <div class="tab" :class="{ active: selectedTab === 'deposit' }" @click="selectTablClick('deposit')">입금신청</div>
          <div class="tab" :class="{ active: selectedTab === 'withdrawal' }" @click="selectTablClick('withdrawal')">출금신청</div>
          <div class="tab" :class="{ active: selectedTab === 'exchg' }" @click="selectTablClick('exchg')">전환신청</div>
        </div>

        <div class="tab-content" v-if="selectedTab === 'deposit'">
          <div class="tab-inner">
            <div class="col2-grid">
              <div class="left">
                <h3 class="subtitle">입금계좌</h3>
                <div class="input-row">
                  <label for="" class="input-label">은행명</label>
                  <h4>{{ bankCpn.bnkNm }}</h4>
                </div>
                <div class="input-row">
                  <label for="" class="input-label">계좌번호</label>
                  <h4>{{ bankCpn.bnkAccNo }}</h4>
                </div>
                <div class="input-row">
                  <label for="" class="input-label">예금주</label>
                  <h4>{{ bankCpn.bnkAccHd }}</h4>
                </div>
              </div>
              <div class="division-line mobile"></div>
              <validation-observer ref="validatorDeposit">
              <div class="right">
                <h3 class="subtitle">신청금액</h3>
                <validation-provider rules="numeric|required|amount|limit:13" name="입금신청금액" v-slot="{errors}">
                <div class="input-row wts-margin-row" :class="{'margin-row-bottom':errors[0] != null}">
                  <label for="dpsReqMn" class="input-label label-margin">입금신청금액</label>                 
                    <div class="input-unit">
                      <input type="text" id="dpsReqMn" v-model="depositForm.dpsReqMn">
                      <div class="unit">원</div>
                      <div class="error errPadding">{{errors[0] }}</div>
                    </div>                      
                </div>
                </validation-provider>
                <div class="input-row" style="height: unset;">
                  <label for="convertExUsdt" class="input-label">예상변환금액</label>
                  <div class="input-unit">
                    <input type="text" id="convertExUsdt" :value="convertToUsdt" readonly="readonly" />
                    <div class="unit">USDT</div>
                  </div>
                  <div class="currency-rate">(1USDT = {{ numberComma(dpsWdMnCms.usdExR) }} KRW)</div>
                </div>
                <div class="input-row">
                  <label for="dpsHd" class="input-label label-margin">입금자명</label>
                  <div class="input-unit">
                    <validation-provider rules="required|limit:15" name="입금자명" v-slot="{errors}">
                      <input type="text" id="dpsHd" v-model="depositForm.dpsHd" />
                      <div class="error">{{errors[0] }}</div>
                    </validation-provider>
                  </div>  
                </div>
                <div class="flex-row row-margin">
                  <button class="btn-line" @click="sumDpsReqMn(100_0000)">+100만</button>
                  <button class="btn-line" @click="sumDpsReqMn(50_0000)">+50만</button>
                  <button class="btn-line" @click="sumDpsReqMn(10_0000)">+10만</button>
                  <button class="btn-line" @click="sumDpsReqMn(1_0000)">+1만</button>
                  <button class="btn-line" @click="sumDpsReqMn(0)">초기화</button>
                </div>
              </div>
              </validation-observer>
            </div>
          </div>
        </div>

        <div class="tab-content" v-if="selectedTab === 'withdrawal'">
          <div class="tab-inner">
            <div class="col2-grid">
              <div class="left">
                <h3 class="subtitle">출금계좌</h3>
                <div class="input-row">
                  <label for="" class="input-label">은행명</label>
                  <h4>{{ bankWd.wdBnkNm }}</h4>
                </div>
                <div class="input-row">
                  <label for="" class="input-label">계좌번호</label>
                  <h4>{{ bankWd.wdBnkAccNo }}</h4>
                </div>
                <div class="input-row">
                  <label for="" class="input-label">예금주</label>
                  <h4>{{ bankWd.wdBnkHd }}</h4>
                </div>
              </div>
              <div class="division-line mobile"></div>
              <div class="right">
                <h3 class="subtitle">신청금액</h3>
                <div class="input-row">
                  <label for="" class="input-label">출금가능금액</label>
                  <div class="input-unit">
                    <input type="text" :value="dpsWdMnCms.usdt" readonly="readonly" />
                    <div class="unit">USDT</div>
                  </div>
                </div>
                <!-- <div class="input-row">
                  <label for="" class="input-label"></label>
                  <div class="input-unit">
                    <input type="text" :value="dpsWdMnCms.balMn" readonly="readonly" />
                    <div class="unit">원</div>
                  </div>
                </div> -->
                <validation-observer ref="validatorDeposit">
                <validation-provider rules="required|amount|limit:13" name="출금신청금액" v-slot="{errors}">
                <div class="input-row wts-margin-row" :class="{'margin-row-bottom':errors[0] != null}">
                  <label for="wdReqMn" class="input-label label-margin">출금신청금액</label>
                  <div class="input-unit">
                    <input type="number" id="wdReqMn" v-model="withdrawForm.wdReqMn" />
                    <div class="unit">USDT</div>
                    <div class="error errPadding">{{errors[0] }}</div>
                  </div>
                </div>
                </validation-provider>
                </validation-observer>
                <div class="input-row" style="height: unset;">
                  <label for="" class="input-label">예상변환금액</label>
                  <div class="input-unit">
                    <input type="text" :value="convertToKrw" readonly="readonly" />
                    <div class="unit">원</div>
                  </div>
                  <div class="currency-rate">(1USDT = {{ numberComma(dpsWdMnCms.usdExR) }} KRW)</div>
                </div>
                <!-- <div class="flex-row row-margin">
                  <button class="btn-line" @click="sumWdReqMn(100_0000)">+100만</button>
                  <button class="btn-line" @click="sumWdReqMn(50_0000)">+50만</button>
                  <button class="btn-line" @click="sumWdReqMn(10_0000)">+10만</button>
                  <button class="btn-line" @click="sumWdReqMn(1_0000)">+1만</button>
                  <button class="btn-line" @click="sumWdReqMn(0)">초기화</button>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="tab-content" v-if="selectedTab === 'exchg'">
          <div class="tab-inner">
            <div class="input-row">
              <label for="" class="input-label">보유 원화</label>
              <div class="input-unit">
                <input type="text" v-model="exchgForm.balMn" readonly="readonly" />
                <div class="unit">원</div>
              </div>
            </div>
            <div class="input-row">
              <label for="wdReqUsdt" class="input-label">보유 테더</label>
              <div class="input-unit">
                <input type="text" v-model="exchgForm.usdt" readonly="readonly" />
                <div class="unit" >USDT</div>
              </div>
            </div>
            <div class="division-line style-sets"></div>
            <div class="style-settings-container">
              <div class="style-settings-radio conversion">
                <input type="radio" id="futuresToCoin" name="conversionSettings" v-bind:checked="exchgForm.chgTpCd == '1'" @click="searchBalMnUsdt('1')" />
                <label for="futuresToCoin">
                  <!-- <div class="radio-mark"></div> -->
                  <div class="converstion-container">
                    <span>선물 계좌</span>
                    <img src="@/assets/icons/arrow-right-black.svg" alt="" />
                    <span>코인 계좌</span>
                  </div>
                </label>
              </div>
              <div class="style-settings-radio conversion">
                <input type="radio" id="coinToFutures" name="conversionSettings" v-bind:checked="exchgForm.chgTpCd == '2'" @click="searchBalMnUsdt('2')" />
                <label for="coinToFutures">
                  <div class="converstion-container">
                    <span>코인 계좌</span>
                    <img src="@/assets/icons/arrow-right-black.svg" alt="" />
                    <span>선물 계좌</span>
                  </div>
                </label>
              </div>

              <validation-observer ref="validatorExchg">
              <validation-provider rules="required|amount|limit:13" name="전환요청금액" v-slot="{errors}">
              <div class="conversion-input">
                <div class="input-row single">
                  <div class="input-unit">
                    <input type="number" v-model="exchgForm.chgReqMn" @input="changeExChgReqMn" />
                    <div class="unit" v-if="exchgForm.chgTpCd == '2'">USDT</div>
                    <div class="unit" v-if="exchgForm.chgTpCd == '1'">원</div>
                  </div>
                </div>
                <div class="input-row single">
                  <div class="input-unit">
                    <input type="text" readonly="readonly" :value="convertTrans" />
                    <div class="unit" v-if="exchgForm.chgTpCd == '1'">USDT</div>
                    <div class="unit" v-if="exchgForm.chgTpCd == '2'">원</div>
                  </div>
                    
                    <div class="currency-rate tab3">(1USDT = {{ numberComma(dpsWdMnCms.usdExR) }} KRW)</div>
                </div>
                <div class="input-row single">
                  <div class="errorExchg">{{errors[0] }}</div>
                </div>
              </div>
              </validation-provider>
              </validation-observer>
            </div>
          </div>
        </div>

        <div class="division-line"></div>

        <div class="notice-container">
          <h3 class="subtitle">유의사항</h3>
          <ul v-if="selectedTab === 'deposit'">
            <li>수수료는 {{ dpsWdMnCms.inTransTpCd == '1' ? dpsWdMnCms.inTransCms + '%' : dpsWdMnCms.inTransCms + 'KRW' }} 입니다.</li>
            <li>당사의 입금계좌가 보이지 않을 경우 반드시 고객센터로 연락하시기 바랍니다.</li>
            <li>입금신청금액과 실제 입금금액이 다를 경우 입금처리가 거부될 수 있음을 유의하시기 바랍니다.</li>
            <li>등록해주신 계좌이외에 타인의 명의로 입금시 입금처리가 거부되오니 반드시 입금전에 확인해주시기 바랍니다.</li>
            <li>원화 입력시, 자동으로 USDT로 환산합니다.</li>
          </ul>
          <ul v-if="selectedTab === 'withdrawal'">
            <li>수수료는 {{ dpsWdMnCms.outTransTpCd == '1' ? dpsWdMnCms.outTransCms + '%' : dpsWdMnCms.outTransCms + 'KRW' }} 입니다.</li>
            <li>보유잔고가 존재하면 출금 신청이 불가능합니다.</li>
          </ul>
          <ul v-if="selectedTab === 'exchg'">
            <li v-if="exchgForm.chgTpCd == '1'">수수료는 {{ dpsWdMnCms.inTransTpCd == '1' ? dpsWdMnCms.inTransCms + '%' : dpsWdMnCms.inTransCms + 'KRW' }} 입니다.</li>
            <li v-if="exchgForm.chgTpCd == '2'">수수료는 {{ dpsWdMnCms.outTransTpCd == '1' ? dpsWdMnCms.outTransCms + '%' : dpsWdMnCms.outTransCms + 'KRW' }} 입니다.</li>
            <li>보유잔고가 존재하면 전환 신청이 불가능합니다.</li>
          </ul>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary darkgray" @click="closeModal()">취소</button>
        <button class="btn-primary blue" v-if="selectedTab === 'deposit'" @click="requestDeposit">입금신청</button>
        <button class="btn-primary blue" v-if="selectedTab === 'withdrawal'" @click="requestWithdraw">출금신청</button>
        <button class="btn-primary blue" v-if="selectedTab === 'exchg'" @click="requestExchg">전환신청</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Utils from '@/modules/utils';

export default {
  name: 'WhiteLabelModalDepositWithdraw',
  computed:{
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
    //usdt로 변환
    convertToUsdt(){
      let toUsdt = 0;
      toUsdt = Utils.krwToUsdt(this.depositForm.dpsReqMn, this.dpsWdMnCms.usdExR, this.dpsWdMnCms.inTransTpCd, this.dpsWdMnCms.inTransCms);
      
      return toUsdt;
    },
    //원화로 변환
    convertToKrw(){
      let toKrw = 0;

      toKrw = Utils.usdtToKrw(this.withdrawForm.wdReqMn, this.dpsWdMnCms.usdExR, this.dpsWdMnCms.outTransTpCd, this.dpsWdMnCms.outTransCms);
      
      return toKrw;
    },
    convertTrans(){
      let toMoney = 0;
      if (this.exchgForm.chgReqMn == undefined || this.exchgForm.chgReqMn == 0){
        return 0;
      }

      if (this.exchgForm.chgTpCd == '1'){ //원화 > USDT
        toMoney = Utils.krwToUsdt(this.exchgForm.chgReqMn, this.dpsWdMnCms.usdExR, this.dpsWdMnCms.inTransTpCd, this.dpsWdMnCms.inTransCms);
      }else{ //USDT > 원화
        toMoney = Utils.usdtToKrw(this.exchgForm.chgReqMn, this.dpsWdMnCms.usdExR, this.dpsWdMnCms.outTransTpCd, this.dpsWdMnCms.outTransCms); 
         
      }

      return toMoney;
    },
  },
  watch:{

  },
  data() {
    return {
      width: '',
      height: '',
      selectedTab: 'deposit',
      showSearchBalMn: false,
      account: {
        accNo: '',
        accId: 0,
        accDivCd: '',
      },
      coinAccount: {
        accNo: '',
        accId: 0,
        accDivCd: '',
      },
      bankCpn: {},
      bankWd: {},
      balMn: 0,
      usdt: 0,
      depositForm: {
        accId: 0,
        accPass: '',
        dpsReqMn: 0,
        dpsHd: '',
        accDivCd:'',
      },
      withdrawForm: {
        accId: 0,
        accPass: '',
        wdReqMn: 0, 
        wdBnkNm: '',
        wdBnkAccNo: '',
        wdBnkHd: '',
        accDivCd: '',
        etcMony:0,
      },
      exchgForm: {
        accNo: '',
        accPass: '',
        chgTpCd: 0, 
        chgReqMn: 0,
        balMn:0,
        toAccNo: '',
      },
      dpsWdMnCms:{balMn:0, usdt:0, inTransTpCd:'1', inTransCms:0, outTransTpCd:'1', outTransCms:0, usdExR: 0}, //잔고, 수수료, type 수수료 구분(%, 고정), 입/출 수수료
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {
	},

  methods: {
    closeModal() {
      this.$modal.hide('modalDepositWithdraw');
    },
    opened(){
      this.searchBalMnCms();
    },
    beforeOpen(e){
      this.account = Object.assign({}, e.params.account);
      this.coinAccount = Object.assign({}, e.params.coinAccount);

      console.log("e.params ", e.params)


      this.searchBankCpn();
      this.searchBankWd();

      this.selectedTab = 'deposit';
      this.balMn = 0;
      this.usdt = 0;
      this.depositForm.accId = 0;
      this.depositForm.accPass = '';
      this.depositForm.dpsReqMn = 0;
      this.depositForm.dpsHd = '';
      this.withdrawForm.accId = 0;
      this.withdrawForm.accPass = '';
      this.withdrawForm.wdReqMn = '';
      this.withdrawForm.wdBnkNm = '';
      this.withdrawForm.wdBnkAccNo = '';
      this.withdrawForm.wdBnkHd = '';
      this.withdrawForm.etcMony = 0;      

      this.exchgForm.accNo = '';
      this.exchgForm.accPass = '';
      this.exchgForm.chgTpCd = '1';
      this.exchgForm.krwToUsdtMn = 0;
      this.exchgForm.krwToUsdtReqMn = 0;
      this.exchgForm.toAccNo = '';
      this.exchgForm.usdtToKrwMn = 0;
      this.exchgForm.usdtToKrwReqMn = 0;
      this.exchgForm.balMn = 0;

      this.dpsWdMnCms.dpsCmsType = "1";
      this.dpsWdMnCms.wdCmsType = "1";
      this.dpsWdMnCms.dpsCms = "0";
      this.dpsWdMnCms.wdCms = "0";
      this.dpsWdMnCms.usdExR = "0";      
      this.dpsWdMnCms.accId = 0;
      this.dpsWdMnCms.accNo = '';
      this.dpsWdMnCms.accDivCd = '';
      this.dpsWdMnCms.balMn = '';
      this.dpsWdMnCms.balMn = '';
    },
    selectTablClick(type){
      this.selectedTab = type;
      if (type == "exchg"){
        this.searchBalMnUsdt(this.exchgForm.chgTpCd);
      }
    },
    sumDpsReqMn(sumValue) {
      if ( sumValue == 0 ) {
        this.depositForm.dpsReqMn = 0;
      } else {
        if ( this.depositForm.dpsReqMn === '' ) {
          this.depositForm.dpsReqMn = sumValue;
        } else {
          this.depositForm.dpsReqMn = parseInt(this.depositForm.dpsReqMn) + sumValue;
        }
      }
    },
    sumWdReqMn(sumValue) {
      if ( sumValue == 0 ) {
        this.withdrawForm.wdReqMn = 0;
      } else {
        if ( this.withdrawForm.wdReqMn === '' ) {
          this.withdrawForm.wdReqMn = sumValue;
        } else {
          this.withdrawForm.wdReqMn = parseInt(this.withdrawForm.wdReqMn) + sumValue;
        }        
      }
    },
    async searchBankCpn() {
      const response = await this.$lhttp.get('/api/account/bank/cpn');

      if ( response && response.data != null ) {
        this.bankCpn = response.data;
      }        
    },
    async searchBankWd() {
      const response = await this.$lhttp.get('/api/account/bank/wd');

      if ( response && response.data != null ) {
        this.bankWd = response.data;
      }        
    },
    //코인 잔고(원, USDT), 입/출금 수수료 조회
    async searchBalMnCms() {
      const response = await this.$lhttp.get('/api/account/dpsWdMnCms', {params:{accId: this.coinAccount.accId}});

      console.log("dpsWdMnCms ", response)
      if ( response && response.data != null ) {
        this.dpsWdMnCms = response.data;
        //this.exchgForm.balMn = response.data.balMn;
        this.exchgForm.usdt = response.data.usdt;
      }        
    },
    //해외선물 계좌 잔고
    async searchBalMn() {
      const response = await this.$lhttp.get('/api/account/balmn', {params:{accId: this.account.accId}});

      if ( response && response.data != null ) {
        this.balMn = response.data;
        console.log("this.exchgForm.balMn", response.data)        
        this.exchgForm.balMn = response.data;
      }        
    },
    //선물/코인 계좌 전환 조회
    searchBalMnUsdt(selType){
      this.exchgForm.chgTpCd = selType;
      this.exchgForm.usdt = 0;
      this.exchgForm.balMn = 0;
      this.exchgForm.chgReqMn = 0;
      
      if (selType == "1"){
        this.searchBalMn();
      }else{
        this.searchBalMnCms();
      }
    },
    requestDeposit() {
      const { validatorDeposit } = this.$refs;

      validatorDeposit.validate().then((result) => {
        if (result) {

          // if ( this.account.accDivCd === Const.ACC_DIV_TYPE.EVENT ) {
          //   this.$alert.alert('입금 신청 할 수 없는 계좌입니다.', '확인')
          //   return
          // }

          this.$alert.confirm('입금 신청 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {
                console.log("this.account.accId", this.coinAccount.accId)
                this.depositForm.accId   = this.coinAccount.accId;
                this.depositForm.accDivCd = this.coinAccount.accDivCd;
                //this.depositForm.accPass = this.account.accPass;

                this.$lhttp
                  .post('/api/account/request/deposit',
                      this.depositForm)
                  .then(() => {
                    this.$alert.alert('입금 신청이 완료되었습니다.', '안내').then(() => {
                      this.$emit("depositWithdraw-complete");
                      this.closeModal()
                    })
                  })

              }
            })

        } else {
          this.$alert.alert('먼저 입력 항목을 확인 하십시오.', '알림')            
        }
      })
    },
    requestWithdraw() {
      const { validatorDeposit } = this.$refs;

      validatorDeposit.validate().then((result) => {
        if (result) {

          if (this.coinAccount.accId == undefined || this.coinAccount.accId == 0){
            this.$alert.alert('계좌정보가 없습니다.', '안내')
            return;
          }

          if ( Number(this.withdrawForm.wdReqMn) <= 0) {
            this.$alert.alert('먼저 출금신청금액을 확인 하십시오.', '알림')
            return
          }

          if ( Number(this.withdrawForm.wdReqMn) < 1) {
            this.$alert.alert('출근신청 최소단위는 1USDT입니다.', '알림')
            return
          }

          if (Number(this.dpsWdMnCms.usdt) < Number(this.withdrawForm.wdReqMn)){
            this.$alert.alert('출금신청 금액이 출금가능금액을 초과할 수 없습니다.', '알림')
            return
          }

          if ( this.bankWd.wdBnkAccNo === undefined || this.bankWd.wdBnkAccNo === null || this.bankWd.wdBnkAccNo === '' 
           || this.bankWd.wdBnkNm === undefined || this.bankWd.wdBnkNm === null || this.bankWd.wdBnkNm === '' 
           || this.bankWd.wdBnkHd === undefined || this.bankWd.wdBnkHd === null || this.bankWd.wdBnkHd === '' 
          ) {
            this.$alert.alert('먼저 출금계좌 정보를 등록 하십시오.', '알림')
            return
          }      

          // if ( this.account.accDivCd === Const.ACC_DIV_TYPE.EVENT ) {
          //   this.$alert.alert('출금 신청 할 수 없는 계좌입니다.', '확인')
          //   return
          // }

          //포지션 체크 (코인 포지션 체크 필요....)
          const positionList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
          if (positionList != undefined && positionList.length > 0){
            let balQty = 0;
            let ordQty = 0;
            let mitQty = 0;
            positionList.forEach(data=>{
              if (data.ordType == "POS"){
                balQty += data.balQty;
              }else if (data.ordType == "ORD"){
                ordQty += data.balQty;
              }else if (data.ordType == "ST"){
                mitQty += data.balQty;
              }
            });

            if (balQty > 0 || ordQty > 0 || mitQty> 0){
              this.$alert.alert('보유 포지션 또는 미체결 주문이 있어 출금 신청 하실 수 없습니다.', '알림');
              return;
            }
          }

          this.$alert.confirm('출금 신청 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {      

                this.withdrawForm.accId      = this.coinAccount.accId
                this.withdrawForm.accPass    = this.coinAccount.accPass
                this.withdrawForm.wdBnkNm    = this.bankWd.wdBnkNm
                this.withdrawForm.wdBnkAccNo = this.bankWd.wdBnkAccNo
                this.withdrawForm.wdBnkHd    = this.bankWd.wdBnkHd
                this.withdrawForm.accDivCd = this.coinAccount.accDivCd;

                this.$lhttp
                  .post('/api/account/request/withdraw',
                      this.withdrawForm)
                  .then(() => {
                    this.$alert.alert('출금 신청이 완료되었습니다.', '안내').then(() => {
                      this.closeModal()
                    })
                  })

              }
            })  
        }
      })
    },
    requestExchg() {
      const { validatorExchg } = this.$refs;

      validatorExchg.validate().then((result) => {
        if (result) {

          //코인계좌
          if (this.coinAccount.accId == undefined || this.coinAccount.accId == 0){
            this.$alert.alert('코인 계좌정보가 없습니다.', '안내')
            return;
          }

          if (this.account.accId == undefined || this.account.accId == 0){
            this.$alert.alert('선물 계좌정보가 없습니다.', '안내')
            return;
          }

          if ( this.exchgForm.chgReqMn <= 0 ) {
            this.$alert.alert('먼저 출금신청금액을 확인 하십시오.', '알림')
            return;
          }

          // if ( this.bankWd.wdBnkAccNo === undefined || this.bankWd.wdBnkAccNo === null || this.bankWd.wdBnkAccNo === '' 
          //  || this.bankWd.wdBnkNm === undefined || this.bankWd.wdBnkNm === null || this.bankWd.wdBnkNm === '' 
          //  || this.bankWd.wdBnkHd === undefined || this.bankWd.wdBnkHd === null || this.bankWd.wdBnkHd === '' 
          // ) {
          //   this.$alert.alert('먼저 출금계좌 정보를 등록 하십시오.', '알림')
          //   return;
          // }      

          // if ( this.account.accDivCd === Const.ACC_DIV_TYPE.EVENT ) {
          //   this.$alert.alert('출금 신청 할 수 없는 계좌입니다.', '확인')
          //   return
          // }

          //선물 > 코인
          if (this.exchgForm.chgTpCd == '1'){
            if (this.exchgForm.balMn == 0){
              this.$alert.alert('선물계좌의 보유 금액이 없습니다.', '알림');
              return;
            }else if (this.exchgForm.chgReqMn > this.exchgForm.balMn){
              this.$alert.alert('전환신청 금액이 보유금액을 초과할 수 없습니다.', '알림');
              return;
            }
          }else{
            if (this.exchgForm.usdt == 0){
              this.$alert.alert('코인계좌의 보유 금액이 없습니다.', '알림');
              return;
            }else if (this.exchgForm.usdt > this.exchgForm.balMn){
              this.$alert.alert('전환신청 금액이 보유금액을 초과할 수 없습니다.', '알림');
              return;
            }

            const positionList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
            if (positionList != undefined && positionList.length > 0){
              let balQty = 0;
              let ordQty = 0;
              let mitQty = 0;
              positionList.forEach(data=>{
                if (data.ordType == "POS"){
                  balQty += data.balQty;
                }else if (data.ordType == "ORD"){
                  ordQty += data.balQty;
                }else if (data.ordType == "ST"){
                  mitQty += data.balQty;
                }
              });

              if (balQty > 0 || ordQty > 0 || mitQty> 0){
                this.$alert.alert('보유 포지션 또는 미체결 주문이 있어 출금 신청 하실 수 없습니다.', '알림');
                return;
              }
            }
          }

          this.$alert.confirm('전환 신청 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {      

                //선물 > 코인
                if (this.exchgForm.chgTpCd == '1'){
                  this.exchgForm.accId = this.account.accId
                  this.exchgForm.accPass = this.account.accPass
                  this.exchgForm.accDivCd = this.account.accDivCd;
                  this.exchgForm.toAccNo = this.coinAccount.accNo;
                  this.exchgForm.toAccId = this.coinAccount.accId;
                  
                }else{  //코인 > 선물
                  this.exchgForm.accId = this.coinAccount.accId
                  this.exchgForm.accPass = this.coinAccount.accPass
                  this.exchgForm.accDivCd = this.coinAccount.accDivCd;
                  this.exchgForm.toAccNo = this.account.accNo;
                  this.exchgForm.toAccId = this.account.accId;
                }

                this.$lhttp
                  .post('/api/account/request/exchg',
                      this.exchgForm)
                  .then(() => {
                    this.$alert.alert('전환 신청이 완료되었습니다.', '안내').then(() => {
                      this.closeModal()
                    })
                  })

              }
            })  
        }
      })
    },
    popWdBank() {
      this.$modal.show('wdBankPop')
    },
    numberComma(newVal){
      let toVal = 0;
      
      if (newVal != undefined){
        toVal = Utils.numberComma(Number(newVal))
      }

      return toVal;
    },
    changeExChgReqMn(e){
      console.log("e >>> ", e.target.value.toString())
      if (this.exchgForm.chgTpCd == "1" && e.target.value.indexOf(".") > -1){ //원화만 입력가능
        let balMn = Math.floor(e.target.value);
        if (balMn.toString().indexOf(".") > -1){
          console.log("소수점..")
          balMn = balMn.replace(/./g, "");
        }

        this.exchgForm.chgReqMn = balMn
      }else{
        //소수점 2자리 이상 삭제
        let balMn = Math.floor(e.target.value * 100) / 100;
        this.exchgForm.chgReqMn = balMn
      }
    },
  },
};
</script>

<style scoped>
.input-row-error {
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
  gap: 0 30px;
  height: 30px;
}

.error {
  color: var(--table-red);
  font-size: 12px;
  width:225px;
  margin-top: 5px;
}

.errorExchg {
  color: var(--table-red);
  font-size: 12px;
  width:100%;
  margin-top: 5px;
}

.errPadding{
  /* padding-bottom:5px;
  width: 124%; */
  position: absolute;
  transform: translateY(125%);
}

.label-margin{
  align-self: baseline;
  margin-top: 10px;
}

.row-margin{
  margin-top: 20px;
}

.wts-margin-row{
  margin-bottom: 10px;
}

.margin-row-bottom{
  margin-bottom: 32px;
}
</style>