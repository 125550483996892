<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
	style="width: 100%; height: 100%"
	class="ag-theme-wts"
	:columnDefs="grid.columnDefs"
	:rowData="grid.rowData"
	:headerHeight="grid.rowHeight"
	:rowHeight="grid.rowHeight"
  rowSelection="single"
  @row-clicked="selectSymbol"
  @grid-ready="onGridReady" />
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic

export default {
  name: "agFuturesTable",
  components: {
    AgGridVue,
  },
  props:{
    //
  },
  computed:{
    getSymbolList: function(){
      return window.$store.getters['Symbol/getSymbolList']
    },    
    getSelectSymbol: function(){
      return window.$store.getters['SocketPrice/getSelectSymbol'];
    },
  },
  watch:{
    getSymbolList(newVal){
      this.selSymbol = newVal[0];
      //종목 리스트 created에서 읽지 못하는경우가 있어서 감지로 처리
      this.selectSymbolList();
    },
    getSelectSymbol(newVal){
      if (newVal != null){
        this.selSymbol = newVal;

        let selYn = "N";
        //선택종목 셀렉트표시
        this.gridApi.forEachNode((node) => {
          if (node.data.name == newVal.name){
            node.setSelected(true, false, true);
            selYn = "Y";
          }
        });

        //선택된 종목이 없으면 전체 비선택
        if (selYn == "N"){
          this.gridApi.forEachNode((node) => {
            node.setSelected(false, false, true);
          });
        }
      }
    }
  },
  data() {
    return {
      grid:{
        columnDefs: [
          {
            headerName: "종목명", 
            field: "description",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "코드",
            field: "name",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "월물",
            field: "secMt",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            // valueFormatter: (d) =>{
            //   if (d.value){
            //     return d.value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
            //   }else{
            //     return '';
            //   }
            // },
          },
        ],
        rowData: null,
        autoSizeStrategy: null,
        paginationPageSize: null,
        currentPage: '',
        totalPages: '',
        rowHeight: '',
      },
      gridApi: null,
      gridColumnApi:null,
      isGridReady:null,
      selSymbol:null,
    }
  },
  created() {
    this.isMobile();
    //this.selectSymbolList();
  },
  mounted(){
    this.selSymbol = window.$store.getters['SocketPrice/getSelectSymbol'];
  },
  methods: {
    isMobile() {
      if (window.name == undefined || window.name == null || window.name == ""){
        if (window.innerWidth <= 1200) {
          return this.grid.rowHeight = 40;
        } else {
          return this.grid.rowHeight = 30;
        }
      }else{
        return this.grid.rowHeight = 30;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.isGridReady = true;

      this.selectSymbolList();
    },
    selectSymbol(val){
      if (val.data.name == this.selSymbol.name){
        this.$emit("selectSymbol"); //모바일 닫기
        return;
      }
      this.selSymbol = val.data;
      window.$store.dispatch('SocketPrice/setSelectSymbol', val.data);
      this.sendOmsLogMessage("종목 선택 "+val.data.name);
      this.$emit("selectSymbol"); //모바일 닫기
    },
    selectSymbolList(){
      let symbolList = window.$store.getters['Symbol/getSymbolList'];
      this.grid.rowData = [];
      //console.log("symbolList", symbolList)
      
      for (let i=0; i<symbolList.length; i++){
        if (symbolList[i].symbolno > 0 && symbolList[i].symbolno < 10){
          this.grid.rowData.push(symbolList[i]);    
        }
      }
    },
    sendOmsLogMessage(message){
      if (window.name == "orderPop1" || window.name == "orderPop2"){
        message = "팝업("+window.name + ") " + message;
        window.opener.$store.dispatch('SocketOms/setLogMsg', {message:message});
      }else{
        window.$store.dispatch('SocketOms/setLogMsg', {message:message});
      }
    }
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>