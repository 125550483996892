<template>
    <div class="base-dropdown" :id="id" ref="dropdown">
      <div class="dropdown instrument" @click="handleDropdown()">
        <div class="selected">{{ selectedName }}</div>
        <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
      </div>
      <div class="dropdown-list instrument-list" :class="{show:isDropdownVisible}">
        <table class="instrument-table">
          <thead>
            <tr>
              <th class="instrument">종목</th>
              <th class="price">가격</th>
              <th class="rate">변동률</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in options" :key="item.symCd" 
            @click="
            selectedOption = item;
            isDropdownVisible = false;
            $emit(id, item);
          ">
              <td class="instrument">{{item.base_name}}</td>
              <td class="price">{{item.lstPrc}}</td>
              <td class="rate">{{item.chgRate}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </template>
  
  <script>
  export default {
    name: 'SymbolDropDown',
    props: {
      options: {
        type: Array,
        required: true,
      },
      id: String,
      default: String,
      width: [String, Number],
      height: [String, Number],
      full: Boolean,
      popup: Boolean,
      cdHeight: {
        type: String,
        default: '',
      },
      value: {
        type: Object
      }
    },
    computed: {
      selectedName() {
        let rtn = ''  
        if ( this.value != undefined && this.value) {
          for (let i in this.options) {
            if ( this.value == this.options[i].base_name ) {
              rtn = this.options[i].base_name
              break
            }  
          }
        }else{
          if (this.selectedOption != undefined){
            rtn = this.selectedOption.base_name
          }else if (this.options != undefined && this.options.length > 0){
            rtn = this.options[0].base_name
          }
        }
        return rtn
      }
    },
    watch: {
      default: {
        handler(newOption) {
            this.selectedOption = newOption;
        },
        deep:true
      }
    },
    data() {
      return {
        selectedOption: '',
        isDropdownVisible: false,
      };
    },
  
  
    mounted() {
      this.selectedOption = this.default ? this.default : this.options[0];
      window.addEventListener('click', this.closeDropdown);
    },
    beforeDestroy() {
      window.removeEventListener('click', this.closeDropdown);
    },
  
    methods: {
      handleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible;
      },
          closeDropdown(element) {
        if (!this.$refs.dropdown.contains(element.target)) {
          this.isDropdownVisible = false;
        }
      },
    },
  };
  </script>
  