<template>
    <button class="btn-link" @click="cancelOrd(params)">취소</button>
  </template>
  
  <script>
  export default {
    name: 'AgOrdCancel',
    components: {
    },
    computed: {
    },
    watch: {
  
    },
    data() {
      return {
  
      }
    },
    created(){
    },
    mounted() {
    },
    methods:{
      cancelOrd(params){
        console.log("params", params)
        params.clicked(params.data);
      }
    },
  
  }
  </script>