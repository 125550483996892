<template>
  <div>
    <modal name="modalConfirmOrder" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">{{ orderNm }}</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="order-confirm-container">
          <div class="text-container">
            <h1>{{ orderMsg }} 하시겠습니까?</h1>
          </div>
          <div class="white-label-table-container order-confirm">
            <table class="white-label-table">
              <tbody>
                <tr>
                  <th>종목명</th>
                  <td>{{ symNm }}</td>
                  <th>수량</th>
                  <td>{{ qty }}</td>
                </tr>
                <tr>
                  <th>구분</th>
                  <td>{{ typeNm }}</td>
                  <th><span v-if="apiCode == 'OMS0003' || apiCode == 'COMS0003'">원주문가격</span><span v-else>주문가격</span></th>
                  <td>{{ price }}</td>
                </tr>
                <tr v-if="apiCode == 'OMS0003' || apiCode == 'COMS0003' ">
                  <th>정정가격</th>
                  <td>{{ targetPrice }}</td>
                  <th></th>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary darkgray" @click="cancel()">아니오</button>
        <button class="btn-primary blue" @click="orderConfirm">예</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { stringCount } from '@/modules/helper';
export default {
  name: 'WhiteLabelModalConfirmOrder',

  data() {
    return {
      orderNm: "",
      orderMsg: "",
      symbol: "",
      qty: 0,
      typeNm: "",
      price: 0,
      targetPrice: 0,
      data: null,
      apiCode: "",
      symNm: "",
    }
  },

  created() {
    if (window.name == undefined || window.name == null || window.name == ""){
      if (window.innerWidth > 1200) {
        this.width = 500;
        this.height = 300;
      } else {
        this.width = '100%';
        this.height = '100%';
      }
    }else{
      this.width = 500;
      this.height = 300;
    }


  },

  mounted() {},

  methods: {
    beforeOpen(e){
      console.log("beforeOpen ", e)
      //초기화
      this.orderNm = "";
      this.orderMsg = "";
      this.symbol = "";
      this.qty = "";
      this.typeNm = "";
      this.price = 0;
      this.targetPrice = 0;
      this.data = null;

      this.data = e.params;
      this.symbol = e.params.symbol;
      this.apiCode = this.data.apiCode;
      this.qty = this.data.qty;
      this.symNm = this.symbol.description;

      //console.log("beforeOpen", e.params);

      if (this.data.order.ordSdCd == "1"){
        this.orderNm = "매도";
      }else if (this.data.order.ordSdCd == "2"){
        this.orderNm = "매수";
      }

      if (this.data.apiCode == "OMS0001" || this.data.apiCode == "COMS0001"){
        if (this.data.order.ordTpCd == "1"){
          this.typeNm = this.orderNm + "(지정가)";
        }else if (this.data.order.ordTpCd == "2"){
          this.typeNm = this.orderNm + "(시장가)";
        }
        this.orderMsg = this.orderNm + " 주문을";
      }else if(this.data.apiCode == "OMS0002" || this.data.apiCode == "COMS0002"){
        this.typeNm = this.orderNm + "취소";
        this.orderMsg = this.orderNm + "주문을 취소";
      }else if (this.data.apiCode == "OMS0003" || this.data.apiCode == "COMS0003"){
        this.typeNm = this.orderNm + "정정";
        this.orderMsg = this.orderNm + "정정 주문을";
      }else if (this.data.apiCode == "OMS0004" || this.data.apiCode == "COMS0004"){
        this.typeNm = "MIT"+this.orderNm;
        this.orderMsg = "MIT"+this.orderNm; + " 주문을";
      }else if (this.data.apiCode == "OMS0005" || this.data.apiCode == "COMS0005"){
        this.typeNm = "MIT"+this.orderNm + "취소";
        this.orderMsg = "MIT"+this.orderNm + " 주문을 취소";
      }else if (this.data.apiCode == "OMS0006" || this.data.apiCode == "COMS0006"){
        this.typeNm = "MIT"+this.orderNm + "정정";
        this.orderMsg = "MIT"+this.orderNm; + "정정 주문을";
      }else if (this.data.apiCode == "OMS0010" || this.data.apiCode == "COMS0010"){
        this.typeNm = this.orderNm + "취소";
        this.orderMsg = this.orderNm + "주문을 취소";
        this.qty = "전체";
        this.price = "전체"
      }else if (this.data.apiCode == "OMS0011" || this.data.apiCode == "COMS0011"){
        this.typeNm = "MIT"+this.orderNm + "취소";
        this.orderMsg = "MIT"+this.orderNm + " 주문을 취소";
        this.qty = "전체";
        this.price = "전체"
      }else if (this.data.apiCode == "OMS0013" || this.data.apiCode == "COMS0013"){
        this.orderNm = "청산";
        this.typeNm = "현종목";
        if (this.data.order.symCd == ""){
          this.typeNm = "전종목";
          this.symNm = "전종목";          
        }

        this.orderMsg = this.typeNm + "을 청산";
        this.qty = "전체";
        this.price = "전체"
      }else if (this.data.apiCode == "OMS0014" || this.data.apiCode == "COMS0014"){
        this.typeNm = this.orderNm + "청산";
        this.orderNm = "청산";

        this.orderMsg = this.symbol.description + "을 청산";
      }else if (this.data.apiCode == "OMS0018" || this.data.apiCode == "COMS0018"){
        this.orderNm = "취소";
        this.typeNm = "현종목";
        console.log("this.data", this.data)
        if (this.data.order.symCd == ""){
          this.typeNm = "전종목";
          this.symNm = "전종목";          
        }

        this.orderMsg = this.typeNm + "을 취소";
        this.qty = "전체";
        this.price = "전체"
      }


      if (this.data.price > 0){
        this.price = this.data.price.toFixed(this.tikDotSz(this.symbol));
      }
      if (this.data.targetPrice > 0){
        this.targetPrice = this.data.targetPrice.toFixed(this.tikDotSz(this.symbol));
      }
    },
    opened(){
      console.log("opened ")
      //console.log("opened");
    },
    cancel(){
      this.closeModal();
    },
    closeModal() {
      if (this.data.message){
        this.data.message += " '아니오' 버튼 클릭";
        this.sendOmsLogMessage(this.data.message);
      }
      this.$modal.hide('modalConfirmOrder');
    },
    orderConfirm(){
      if (this.data.message){
        this.data.message += " '예' 버튼 클릭";
        this.sendOmsLogMessage(this.data.message);
      }

      const orderData = {apiCode:this.data.apiCode, order:this.data.order};
      this.$emit("event:orderConfirm", orderData);
      this.$modal.hide('modalConfirmOrder');
    },
    tikDotSz(symbol){
      return stringCount(symbol.pricescale);
    },
    sendOmsLogMessage(message){
      if (window.name == "orderPop1" || window.name == "orderPop2"){
        message = "팝업("+window.name + ") " + message;
        window.opener.$store.dispatch('SocketOms/setLogMsg', {message:message});
      }else{
        if (window.location.pathname == "/mobile"){
          message = "모바일 " + message;
        }
        window.$store.dispatch('SocketOms/setLogMsg', {message:message});
      }      
    }
  },
};
</script>
