<template>
  <div>
    <div ref="tv_chart_container_coin" id="tv_chart_container_coin" :style="{ width: width + 'px', height: height + 'px', }" />
  </div>
</template>
    
<script>
import { widget } from '../../public/plugin/charting_library'
import datafeed from '@/modules/datafeedCoin'

function getStudiesStorageData(){
  // eslint-disable-next-line no-prototype-builtins
  if(window.localStorage.hasOwnProperty('studies')){
    let storage_studies = window.localStorage.getItem('studies')
    return JSON.parse(storage_studies)
  }else{
    return [];
  }
}


function setStudiesStorage(studies){
  window.localStorage.setItem('studies', JSON.stringify(studies))
}

export default {
  name: 'ChartBox',
  props: {
    symbol: {
      type: Object,
    default: () => ({})
    },
    paramSymbol: {
      default : '',
      type: String
    },
    autosize: {
      default: true,
      type: Boolean
    },
    studiesOverrides: {
      type: Object,
      default: () => ({})
    },
    fullscreen: {
      default: false,
      type: Boolean
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
  },
  data() {
    return {
      widgetOptions: {
        symbol: '',
        datafeed: datafeed,
        interval: '1',
        container: 'tv_chart_container_coin',
        library_path: '/plugin/charting_library/',
        custom_css_url: '/plugin/css/charting_library.css',
        locale: 'ko', 
        autosize: this.autosize,
        fullscreen: this.fullscreen,
        //studiesOverrides: {},
        // studies_overrides: this.studiesOverrides,
        // ADD 
        loading_screen: { backgroundColor: "#ffffff" },
        timezone: 'Asia/Seoul',
        debug: false,
        theme: 'white',
        disabled_features: ['go_to_date', 'study_templates', 'use_localstorage_for_settings','volume_force_overlay','timeframes_toolbar','header_screenshot',
                                            'header_fullscreen_button', 'header_undo_redo','header_settings'],
        enabled_features: [''],   
        time_scale: {
            min_bar_spacing: 5,
        },   
        custom_formatters: {},   
        studies_overrides: {"volume.volume.color.0": "#ff4352","volume.volume.color.1": "#459dff",},  
        overrides: {
        "paneProperties.background": "#ffffff",
        "paneProperties.backgroundType": "solid",
        "mainSeriesProperties.candleStyle.upColor": "#ff4352",
        "mainSeriesProperties.candleStyle.downColor": "#459dff",        
        "mainSeriesProperties.candleStyle.borderDownColor": "#459dff",
        "mainSeriesProperties.candleStyle.borderUpColor": "#ff4352",
        "mainSeriesProperties.candleStyle.wickUpColor" : "#ff4352",
        "mainSeriesProperties.candleStyle.wickDownColor" : "#459dff", 
        "mainSeriesProperties.showCountdown" : false,
        },
        load_last_chart: true,
      },
      tvWidget: null,
      tv_chart: 0,
    }
  },
  watch: {
    symbol: {
      handler(newSymbol, oldSymbol) {
        if (newSymbol == undefined || newSymbol == null || newSymbol.name == undefined) return;

        if (this.tvWidget != undefined && newSymbol != undefined) {
          if (this.tvWidget.activeChart() != undefined){
            this.tvWidget.activeChart().setSymbol(newSymbol.name)
          }
        } else {
          this.widgetOptions.symbol = newSymbol.name

          this.tvWidget = new widget(this.widgetOptions)
          let studies = [];

          this.tvWidget.onChartReady(() => {
            const stuiesList = this.tvWidget.getStudiesList();
            let notValueStudies = [];
            // eslint-disable-next-line no-prototype-builtins
            if(window.localStorage.hasOwnProperty('studies')){
              let storage_studies = window.localStorage.getItem('studies')
              const studies = JSON.parse(storage_studies)

              studies.forEach(study => {
                const studyIndex = stuiesList.findIndex(item => item === study);
                if(studyIndex === -1){
                  notValueStudies.push(study)
                }else{
                  const input = this.tvWidget.getStudyInputs(study)
                  this.tvWidget.activeChart().createStudy(study, false, false, input).then((id) => {
                    //console.log(id)
                  })
                }
              })

              notValueStudies.forEach(notValue => {
                const notStudyIndex = studies.findIndex(item => item === notValue);

                if(notStudyIndex !== -1 ){
                  studies.splice(notStudyIndex,1)
                }
              })

              window.localStorage.setItem('studies', JSON.stringify(studies))
            }
          })

          this.tvWidget.subscribe('study', (arg)=> {
            //console.log("==============================study===================", arg)

            let storage_studies = window.localStorage.getItem('studies')
            let studies = JSON.parse(storage_studies)
            if(studies){
              const studiesIndex = studies.findIndex(study => study === arg.value)
              if(studiesIndex === -1){
                studies.push(arg.value)
              }
            }else{
              studies = [arg.value]
            }
            window.localStorage.setItem('studies', JSON.stringify(studies))
            // study subscribe end
          })

          // undo_redo_state_changed
          this.tvWidget.subscribe('undo_redo_state_changed', (args)=> {
            //console.log("==============================undo_redo_state_changed===================", args)

            let data = getStudiesStorageData();
            const allStudies = this.tvWidget.activeChart().getAllStudies()
            let allStudieName = [];
            allStudies.forEach(_s => {
              if(_s.name !== 'Volume'){
                allStudieName.push(_s.name)
              }
            });
            
            setStudiesStorage(allStudieName)
            //undo_redo_state_changed end
          })
        }
      },
      deep: true,
      },
  },
  created() {

  },
  mounted() {
    this.tv_chart = this.$refs.tv_chart_container_coin.clientHeight;
    //console.log('tv_chart_container_coin: ' + this.gridContainer);

  },
  destroyed () {
    if (this.tvWidget !== null) {    
      this.tvWidget.remove()
      this.tvWidget = null
    }
    datafeed.disconnect()
  }

}
</script>
      