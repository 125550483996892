<template>
  <div>
    <modal name="modalLimitClose" classes="main-modal" :adaptive="true" :width="width" :height="height">
      <div class="modal-header">
        <h2 class="modal-header-title">지정가 종료</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
				<div class="modal-limit-close">
          <div class="label">지정가를 입력해주세요.</div>
          <input type="number" placeholder="지정가" />
        </div>
			</div>

      <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" @click="closeModal()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'CoinModalLimitClose',

  data() {
    return {
      width: '',
      height: '',
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 228;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('modalLimitClose');
    },
  },
};
</script>
<style lang="scss" scoped></style>
