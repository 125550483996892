<script setup>
import { ref } from 'vue';
const activeTab = ref('order');
function setActiveTab(tab) {
  activeTab.value = tab;
}
</script>

<template>
  <div class="mobile-container">
    <div class="mobile-header">
      <div class="left">
        <button><img src="@/assets/icons/menu-black.svg" alt="" /></button>

        <div class="instrument" @click="showModal('modalInstruments')">
          <img src="@/assets/icons/search-black.svg" alt="" />
          <h3>BTCUSDT 무기한</h3>
        </div>
      </div>
      <div class="right">right</div>
    </div>
    <div class="mobile-inner-container">
      <div class="mobile-tab-container coin">
        <button class="mobile-tab" 
					:class="{ active: activeTab === 'order' }" 
					@click="setActiveTab('order')">주문</button>
        <button class="mobile-tab"
					:class="{ active: activeTab === 'chart' }" 
					@click="setActiveTab('chart')">차트</button>
        <button class="mobile-tab" 
					:class="{ active: activeTab === 'assets' }" 
					@click="setActiveTab('assets')">자산</button>
      </div>
      <div class="mobile-order-page" v-if="activeTab === 'order'">
        <div class="margin-row">
          <button class="btn-line">Cross</button>
          <button class="btn-line">125x</button>
          <button class="btn-line">Hedge</button>
        </div>

        <div class="price-row buy">
          <!-- 클래스 buy, sell -->
          <div class="current-price">167,255.18</div>
          <div class="last-price">167,255.18</div>
          <div class="simple-label">
            펀딩/남은시간
            <span class="orange">0.0100%</span>
            / 04:39:31
          </div>
        </div>

        <div class="mobile-orderbook">
          <div class="orderbook-table">
            <div class="orderbook-label">
              <div class="price">가격(USDT)</div>
              <div class="volume">수량(BTC)</div>
            </div>
            <div class="orderbook-row sell">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row sell">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row sell">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row sell">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row sell">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row sell">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
          </div>

          <div class="orderbook-table">
            <div class="orderbook-label">
              <div class="price">가격(USDT)</div>
              <div class="volume">수량(BTC)</div>
            </div>
            <div class="orderbook-row buy">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row buy">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row buy">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row buy">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row buy">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
            <div class="orderbook-row buy">
              <div class="price">166976.80</div>
              <div class="volume">643.5K</div>
            </div>
          </div>
        </div>

        <div class="entry-close">
          <div class="entry">진입</div>
          <div class="close active">청산</div>
        </div>

        <div class="mobile-row coin">
          <div class="base-dropdown">
            <div class="dropdown">
              지정가
              <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
              <ul class="dropdown-list">
                <!-- show 클래스로 확인 -->
                <li>지정가</li>
                <li>시장가</li>
              </ul>
            </div>
          </div>
          <div class="mobile-number-input">
            <button><img src="@/assets/icons/plus-black.svg" alt="" /></button>
            <input type="number" value="67212.10" />
            <button><img src="@/assets/icons/minus-black.svg" alt="" /></button>
          </div>
        </div>

        <div class="mobile-row coin">
          <div class="mobile-number-input">
            <button><img src="@/assets/icons/plus-black.svg" alt="" /></button>
            <input type="number" value="67212.10" />
            <button><img src="@/assets/icons/minus-black.svg" alt="" /></button>
          </div>
          <div class="base-dropdown">
            <div class="dropdown">
              USDT
              <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
              <ul class="dropdown-list">
                <!-- show 클래스로 확인 -->
                <li>USDT</li>
                <li>BTC</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="mobile-control-ui">
          <div class="slider-container">
            <input class="amount-slider" type="range" value="0" min="0" max="100" step="1" list="my-detents" />

            <datalist id="my-detents">
              <option value="25"></option>
              <option value="50"></option>
              <option value="75"></option>
            </datalist>
          </div>

          <div class="mobile-sl">
            <div class="checkbox-default">
              <input type="checkbox" id="takeProfit" name="stopProfit" value="" />
              <label for="takeProfit">익절/손절</label>
            </div>
            <div class="mobile-row coin">
              <div class="base-dropdown">
                <div class="dropdown">
                  Mark
                  <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
                  <ul class="dropdown-list">
                    <!-- show 클래스로 확인 -->
                    <li>Mark</li>
                    <li>Last</li>
                  </ul>
                </div>
              </div>
              <div class="mobile-number-input">
                <button><img src="@/assets/icons/plus-black.svg" alt="" /></button>
                <input type="number" value="67212.10" />
                <button><img src="@/assets/icons/minus-black.svg" alt="" /></button>
              </div>
            </div>
            <div class="mobile-row coin">
              <div class="base-dropdown">
                <div class="dropdown">
                  Mark
                  <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
                  <ul class="dropdown-list">
                    <!-- show 클래스로 확인 -->
                    <li>Mark</li>
                    <li>Last</li>
                  </ul>
                </div>
              </div>
              <div class="mobile-number-input">
                <button><img src="@/assets/icons/plus-black.svg" alt="" /></button>
                <input type="number" value="67212.10" />
                <button><img src="@/assets/icons/minus-black.svg" alt="" /></button>
              </div>
            </div>
          </div>

          <div class="row-info">
            <div class="label">Avbl</div>
            <div class="value">0.00000000 BTC</div>
          </div>
          <div class="row-info">
            <div class="label">Order Value</div>
            <div class="value">0.00000000 BTC</div>
          </div>

          <div class="buysell-btn-container">
            <div class="coin-btn-container">
              <button class="order-btn buy">매수/Long</button>
              <div class="simple-label">
                Cost:
                <b>0.00 USDT</b>
              </div>
            </div>
            <div class="coin-btn-container">
              <button class="order-btn sell">매도/Short</button>
              <div class="simple-label">
                Cost:
                <b>0.00 USDT</b>
              </div>
            </div>
          </div>
        </div>

        <div class="mobile-transaction-table">
          <div class="order-type-tab-container">
            <button class="order-type-tab active">포지션 (2)</button>
            <button class="order-type-tab">미체결 (0)</button>
          </div>
          <div class="mobile-transaction-ui">
            <button class="btn-line">전체청산</button>
          </div>
          <ul class="mobile-transaction-list">
            <li>
              <div class="transaction-summary">
                <div>
                  <div class="label">BTC/USDT</div>
                  <div class="value margin">Isolated / 100x / Long</div>
                </div>
                <div class="pnl">
                  <div class="label">PNL(ROE)</div>
                  <div class="value minus">-0.07(-11.04%)</div>
                </div>
              </div>
              <div class="transaction-detail">
                <div>
                  <div class="label">수량</div>
                  <div class="value">9.12345678</div>
                </div>
                <div>
                  <div class="label">마진</div>
                  <div class="value">123.12</div>
                </div>
                <div>
                  <div class="label">비율</div>
                  <div class="value minus">-7.9%</div>
                </div>
                <div>
                  <div class="label">진입가격</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">시장평균가</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">청산가격</div>
                  <div class="value margin-call">100000.00</div>
                </div>
              </div>
              <div class="transaction-actions">
                <button class="btn-line">레버리지</button>
                <button class="btn-line">TP/SL</button>
                <button class="btn-line">청산</button>
              </div>
            </li>

            <li>
              <div class="transaction-summary">
                <div>
                  <div class="label">BTC/USDT</div>
                  <div class="value margin">Isolated / 100x / Long</div>
                </div>
                <div class="pnl">
                  <div class="label">PNL(ROE)</div>
                  <div class="value minus">-0.07(-11.04%)</div>
                </div>
              </div>
              <div class="transaction-detail">
                <div>
                  <div class="label">수량</div>
                  <div class="value">9.12345678</div>
                </div>
                <div>
                  <div class="label">마진</div>
                  <div class="value">123.12</div>
                </div>
                <div>
                  <div class="label">비율</div>
                  <div class="value minus">-7.9%</div>
                </div>
                <div>
                  <div class="label">진입가격</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">시장평균가</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">청산가격</div>
                  <div class="value margin-call">100000.00</div>
                </div>
              </div>
              <div class="transaction-actions">
                <button class="btn-line">레버리지</button>
                <button class="btn-line">TP/SL</button>
                <button class="btn-line">청산</button>
              </div>
            </li>

            <li>
              <div class="transaction-summary">
                <div>
                  <div class="label">BTC/USDT</div>
                  <div class="value margin">Isolated / 100x / Long</div>
                </div>
                <div class="pnl">
                  <div class="label">PNL(ROE)</div>
                  <div class="value minus">-0.07(-11.04%)</div>
                </div>
              </div>
              <div class="transaction-detail">
                <div>
                  <div class="label">수량</div>
                  <div class="value">9.12345678</div>
                </div>
                <div>
                  <div class="label">마진</div>
                  <div class="value">123.12</div>
                </div>
                <div>
                  <div class="label">비율</div>
                  <div class="value minus">-7.9%</div>
                </div>
                <div>
                  <div class="label">진입가격</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">시장평균가</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">청산가격</div>
                  <div class="value margin-call">100000.00</div>
                </div>
              </div>
              <div class="transaction-actions">
                <button class="btn-line">레버리지</button>
                <button class="btn-line">TP/SL</button>
                <button class="btn-line">청산</button>
              </div>
            </li>

            <li>
              <div class="transaction-summary">
                <div>
                  <div class="label">BTC/USDT</div>
                  <div class="value margin">Isolated / 100x / Long</div>
                </div>
                <div class="pnl">
                  <div class="label">PNL(ROE)</div>
                  <div class="value minus">-0.07(-11.04%)</div>
                </div>
              </div>
              <div class="transaction-detail">
                <div>
                  <div class="label">수량</div>
                  <div class="value">9.12345678</div>
                </div>
                <div>
                  <div class="label">마진</div>
                  <div class="value">123.12</div>
                </div>
                <div>
                  <div class="label">비율</div>
                  <div class="value minus">-7.9%</div>
                </div>
                <div>
                  <div class="label">진입가격</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">시장평균가</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">청산가격</div>
                  <div class="value margin-call">100000.00</div>
                </div>
              </div>
              <div class="transaction-actions">
                <button class="btn-line">레버리지</button>
                <button class="btn-line">TP/SL</button>
                <button class="btn-line">청산</button>
              </div>
            </li>

            <li>
              <div class="transaction-summary">
                <div>
                  <div class="label">BTC/USDT</div>
                  <div class="value margin">Isolated / 100x / Long</div>
                </div>
                <div class="pnl">
                  <div class="label">PNL(ROE)</div>
                  <div class="value minus">-0.07(-11.04%)</div>
                </div>
              </div>
              <div class="transaction-detail">
                <div>
                  <div class="label">수량</div>
                  <div class="value">9.12345678</div>
                </div>
                <div>
                  <div class="label">마진</div>
                  <div class="value">123.12</div>
                </div>
                <div>
                  <div class="label">비율</div>
                  <div class="value minus">-7.9%</div>
                </div>
                <div>
                  <div class="label">진입가격</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">시장평균가</div>
                  <div class="value">100000.00</div>
                </div>
                <div>
                  <div class="label">청산가격</div>
                  <div class="value margin-call">100000.00</div>
                </div>
              </div>
              <div class="transaction-actions">
                <button class="btn-line">레버리지</button>
                <button class="btn-line">TP/SL</button>
                <button class="btn-line">청산</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
			<div class="mobile-chart-page" v-if="activeTab === 'chart'">
				chart
			</div>
			<div class="mobile-assets-page" v-if="activeTab === 'assets'">
				<div class="info-panel">
      <div class="panel-header">
        <div class="header-title">예탁금</div>
        <button class="icon">
          자산이동
          <img src="@/assets/icons/transfer.svg" alt="" />
        </button>
      </div>
      <table class="info-list-table">
        <tbody>
          <tr>
            <th>보유자산</th>
            <td>0</td>
          </tr>
          <tr>
            <th>사용가능자산</th>
            <td>0</td>
          </tr>
          <tr>
            <th>사용중자산</th>
            <td>0</td>
          </tr>
          <tr>
            <th>동결자산</th>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>
		<div class="info-panel">
      <div class="panel-header">
        <div class="header-title">계약정보</div>
      </div>
      <table class="info-list-table">
        <tbody>
          <tr>
            <th>만료일자</th>
            <td>0</td>
          </tr>
          <tr>
            <th>자수가격</th>
            <td>0</td>
          </tr>
          <tr>
            <th>표시가격</th>
            <td>0</td>
          </tr>
          <tr>
            <th>24H 판매량</th>
            <td>0</td>
          </tr>
          <tr>
            <th>24H 거래량</th>
            <td>0</td>
          </tr>
          <tr>
            <th>계약가액</th>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>
			</div>
    </div>
  </div>
</template>
