<template>
	<button class="btn-link" @click="showModal('modalTPSP')">보기</button>
</template>

<script>
export default {
  name: 'AgViewTpSl',
  components: {
  },
  computed: {
  },
  watch: {

  },
  data() {
    return {

    }
  },
  created(){
  },
  mounted() {
  },
  methods:{
		showModal(name) {
      this.$modal.show(name);
      console.log(name);
    },
  },

}
</script>