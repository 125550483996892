<template>
	<div class="position-type">
    <button class="btn-link" @click="limitOrd(params)">지정가</button>
		<button class="btn-link" @click="maketOrd(params)">시장가</button>
	</div>
</template>

<script>
export default {
  name: 'agPositiontype',
  components: {
  },
  computed: {
  },
  watch: {

  },
  data() {
    return {

    }
  },
  created(){
  },
  mounted() {
  },
  methods:{
    maketOrd(params){
      params.clicked("2", params.data);  
    },
    limitOrd(params){
      params.clicked("1", params.data);  
    },
  },

}
</script>

<style lang="scss" scoped>
.position-type {
	display: flex;
	gap: 10px;
}
</style>