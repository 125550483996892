<template>
  <div>
    <modal name="coinModalSettings" classes="main-modal" :adaptive="true" :width="width" :height="height">
      <div class="modal-header">
        <h2 class="modal-header-title">환경설정</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="tab-nav-container">
          <div class="tab" :class="{ active: selectedTab === 'tab1' }" @click="selectedTab = 'tab1'">기본설정</div>
          <div class="tab" :class="{ active: selectedTab === 'tab2' }" @click="selectedTab = 'tab2'">암호설정</div>
          <!-- <div class="tab" :class="{ active: selectedTab === 'tab3' }" @click="selectedTab = 'tab3'">오버나잇</div> -->
        </div>

        <div class="tab-content" v-if="selectedTab === 'tab1'">
          <div class="col2-grid settings">
            <div class="tab-inner-row">
              <h3 class="subtitle">주문알림/확인 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="orderAlarm" value="" />
                    <label for="orderAlarm">주문알림</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="orderConfirm" value="" />
                    <label for="orderConfirm">주문확인</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="orderClose" value="" />
                    <label for="orderClose">청산/취소 확인</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="orderVoice" value="" />
                    <label for="orderVoice">음성</label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-inner-row">
              <h3 class="subtitle">차트위치 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="radio-default">
                    <input type="radio" id="chartLeft" name="chartPosition" value="" />
                    <label for="chartLeft">죄측</label>
                  </div>
                </li>
                <li>
                  <div class="radio-default">
                    <input type="radio" id="chartRight" name="chartPosition" value="" />
                    <label for="chartRight">우측</label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-inner-row">
              <h3 class="subtitle">스타일 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="style-settings-radio">
                    <input type="radio" id="greenRed" name="styleSettings" value="" checked />
                    <label for="greenRed">
                      <!-- <div class="radio-mark"></div> -->
                      <div class="style-container">
                        <span class="buy1">매수</span>
                        <span class="red">매도</span>
                      </div>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="style-settings-radio">
                    <input type="radio" id="redBlue" name="styleSettings" value="" />
                    <label for="redBlue">
                      <div class="style-container">
                        <span class="buy">매수</span>
                        <span class="red">매도</span>
                      </div>
                    </label>
                  </div>
                </li>
              </ul>
            </div>

            <div class="tab-inner-row full shortcut">
              <h3 class="subtitle">키보드 단축키 설정</h3>
              <div class="shortcut-inner-grid">
                <ul class="shortcut-list">
                  <li>
                    <div class="label">중앙정렬</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                  <li>
                    <div class="label">시장가매도</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                  <li>
                    <div class="label">시장가매수</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                  <li>
                    <div class="label">매도취소</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                  <li>
                    <div class="label">매수취소</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                </ul>
                <ul class="shortcut-list">
                  <li>
                    <div class="label">MIT매도취소</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                  <li>
                    <div class="label">MIT매수취소</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                  <li>
                    <div class="label">전종목청산</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                  <li>
                    <div class="label">현종목청산</div>
                    <div class="base-dropdown">
                      <div class="dropdown">
                        <div class="selected">ALT</div>
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                      </div>
                      <!-- <ul class="dropdown-list">
                            <li>1. 일반</li>
                            <li>2. 모의투자</li>
                          </ul> -->
                    </div>
                    +
                    <CounterComp />
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="tab-inner modal-settings"></div> -->
            <!-- <div class="division-line"></div> -->
          </div>
        </div>

        <div class="tab-content" v-if="selectedTab === 'tab2'">
          
            <h3 class="subtitle">비밀번호 입력 (4~8자리 알파벳+숫자)</h3>
            <div class="input-row">
              <label for="" class="input-label">현재 비밀번호</label>
              <input type="password" />
            </div>
            <div class="input-row">
              <label for="" class="input-label">변경 비밀번호</label>
              <input type="password" />
            </div>
            <div class="input-row">
              <label for="" class="input-label">변경 확인</label>
              <input type="password" />
            </div>
          
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary darkgray" @click="closeModal()">취소</button>
        <button class="btn-primary blue">저장</button>
      </div>
    </modal>
  </div>
</template>

<script>
import AgOvernightTable from '@/components/agTables/AgOvernightTable.vue';
import CounterComp from '@/components/CounterComp.vue';
import EventBus from '@/modules/event-bus';

export default {
  components: { AgOvernightTable, CounterComp },
  name: 'CoinModalSettings',

  data() {
    return {
      width: '',
      height: '',
      selectedTab: 'tab1',
      selectedTheme: 'black',
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 664;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('coinModalSettings');
    },
    thousand(value) {
      return value.toLocaleString('en-US', { minimumFractionDigits: 2 });
    },
    // changeTheme() {
    //   bus.$emit('selectedTheme', this.selectedTheme);
    // },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.tab-content {
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: $mobile) {
    padding-left: 0;
    padding-right: 0;
  }
  .tab-inner-row {
    &.full {
      grid-column: -1/1;
    }
    &.shortcut {
      @media (max-width: $mobile) {
        display: none;
      }
    }
    .row-list {
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
